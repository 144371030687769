import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import {
  BehaviorSubject,
  catchError,
  retry,
  shareReplay,
  throwError,
} from 'rxjs';
import { PingService } from '../../../api/gen/api/ping.service';

@Injectable({
  providedIn: 'root',
})
export class ConnectivtyBannerService {
  private _isError = false;
  private readonly $networkErrorState = new BehaviorSubject<boolean>(
    this._isError,
  );

  public $networkErrorStateChanged = this.$networkErrorState.asObservable();

  private _currentSnackbar: MatSnackBarRef<any> | undefined;

  constructor(
    private readonly snackbar: MatSnackBar,
    private readonly pingService: PingService,
  ) {}

  public requestSucceeded() {
    if (!this._isError) return;

    this._isError = false;
    this._currentSnackbar?.dismiss();
    this.$networkErrorState.next(this._isError);
  }

  public requestFailed() {
    if (this._isError) return;

    this._isError = true;

    // https://medium.com/angular-in-depth/retry-failed-http-requests-in-angular-f5959d486294
    this.pingService
      .pingGet()
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (!this._currentSnackbar) {
            this._currentSnackbar = this.snackbar.open(
              $localize`Whoops. Unser System hat scheinbar Probleme.`,
            );
            this.$networkErrorState.next(this._isError);
          }
          return throwError(() => new Error('Failed'));
        }),
        retry({ delay: 3000 }),
        shareReplay(),
      )
      .subscribe((_) => {
        this.requestSucceeded();
      });
  }
}
