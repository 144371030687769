import { Component, Inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Report } from '../../../../model/report/report';
import { BehaviorSubject } from 'rxjs';
import { ReportService } from '../../../../services/api/report.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-rename-report-dialog',
  templateUrl: './rename-report-dialog.component.html',
  styleUrls: ['./rename-report-dialog.component.scss'],
  providers: [ReportService],
})
export class RenameReportDialogComponent {
  private readonly report: Report;
  isLoading = new BehaviorSubject(false);
  reportName: FormControl;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { report: Report },
    private readonly dialogRef: MatDialogRef<RenameReportDialogComponent>,
    private readonly snackBar: MatSnackBar,
    private readonly reportService: ReportService,
  ) {
    this.report = data.report;
    this.reportName = new FormControl(data.report.fileName, [
      Validators.required,
      Validators.min(3),
      Validators.max(100),
    ]);
  }

  getErrorMessage(): string {
    if (this.reportName.hasError('min')) {
      return $localize`Der Name muss mindestens drei Zeichen lang sein.`;
    }
    if (this.reportName.hasError('max')) {
      return $localize`Der Name darf maximal 100 Zeichen lang sein.`;
    }
    if (this.reportName.hasError('required')) {
      return $localize`Der Name für den Report darf nicht leer sein.`;
    }

    return '';
  }

  saveReportName() {
    const reportName = this.getPdfPrependedReportName(this.reportName.value);
    this.isLoading.next(true);
    this.reportService.updateReportName(this.report.id, reportName).subscribe({
      next: (report) => {
        this.dialogRef.close(report);
      },
      error: (_) => {
        this.snackBar.open(
          $localize`Name konnte nicht geändert werden.`,
          $localize`Ok`,
        );
      },
    });
  }

  private getPdfPrependedReportName(value: string) {
    if (value.endsWith('.pdf')) {
      return value;
    }

    return `${value}.pdf`;
  }
}
