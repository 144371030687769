import { Injectable } from '@angular/core';
import { BlobClient, BlobServiceClient } from '@azure/storage-blob';
import * as JSZip from 'jszip';
import { firstValueFrom, Observable, timeout } from 'rxjs';
import { environment } from '../../../environments/environment';
import { AttachmentWithState } from '../../scheduling/inquiry-details/attachments/data/attachment-with-state';
import { saveAs } from 'file-saver-es';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AttachmentService } from '../api/attachment.service';
import { Attachment } from '../../model/attachment/attachment';
import { AssociatedCallPeriod } from '../../model/attachment/associated-call-period';
import { AttachmentState } from '../../scheduling/inquiry-details/attachments/data/attachment-state';
import { CreateAttachmentRequest } from '../../model/attachment/request/create-attachment-request';
import { Author } from '../../model/attachment/author';
import { AttachmentUpload } from '../../model/attachment/attachment-upload';
import { Origin } from '../../model/attachment/origin';

@Injectable({ providedIn: 'root' })
export class AttachmentFileService {
  private static readonly MAX_UPLOAD_SIZE = 314_572_800; // 300MiB max size
  private readonly blobServiceClient: BlobServiceClient;

  constructor(
    private readonly attachmentService: AttachmentService,
    private readonly snackBar: MatSnackBar,
  ) {
    this.blobServiceClient = new BlobServiceClient(environment.storagePath);
  }

  private static getFileNameWithoutExtension(filename: string) {
    return filename.substring(0, filename.lastIndexOf('.'));
  }

  private static getFileExtension(filename: string) {
    return filename.substring(filename.lastIndexOf('.') + 1, filename.length);
  }

  downloadAttachment(
    attachment: Attachment,
    attachmentDownloaded: (attachment: Attachment) => void,
  ) {
    const fileName = `${attachment.fileName}.${attachment.extension}`;
    saveAs(attachment.annotationBlobUrl ?? attachment.blobUrl, fileName);
    attachmentDownloaded(attachment);
  }

  uploadAttachments(
    associatedCallPeriod: AssociatedCallPeriod,
    inquiryId: string,
    files: File[],
  ): Observable<AttachmentWithState> {
    return new Observable<AttachmentWithState>((observer) => {
      const uploadedAttachments = [];

      for (const element of files) {
        const file = element;
        if (file) {
          if (!this.validateFileSize(file)) {
            return;
          }
          const fileName = file.name;
          const createThumbnail = !(file.type.match('image/*') == null);
          this.createUploadFileRequest(inquiryId, fileName).then(
            async (attachmentUpload) => {
              observer.next(this.uploading(fileName));
              const sasUri = attachmentUpload.sasUri;
              const res = await this.uploadAttachment(sasUri, file);
              if (!res) {
                observer.error(this.uploadError(fileName));
                return;
              }

              this.createAttachment(
                attachmentUpload.storageIdentifier,
                attachmentUpload.blobPath,
                associatedCallPeriod,
                inquiryId,
                fileName,
                createThumbnail,
              )
                .then((attachment) => {
                  uploadedAttachments.push(attachment);
                  observer.next(this.success(fileName, attachment));
                  if (uploadedAttachments.length >= files.length) {
                    observer.complete();
                  }
                })
                .catch(() => {
                  observer.next(this.fail(fileName));
                  observer.error(this.uploadError(fileName));
                });
            },
          );
        }
      }
    });
  }

  private validateFileSize(file: File): boolean {
    if (file.size > AttachmentFileService.MAX_UPLOAD_SIZE) {
      const maxUploadSizeMb = Math.floor(
        AttachmentFileService.MAX_UPLOAD_SIZE / 1_048_576,
      );
      this.snackBar.open(
        $localize`Die Datei ${file.name} ist zu groß. Es sind Dateien mit maximal ${maxUploadSizeMb}MiB erlaubt.`,
        'Ok',
        {
          duration: 5_000,
        },
      );
      return false;
    }

    return true;
  }

  async downloadAttachmentsAsZip(
    resultFileName: string,
    attachments: Attachment[],
    attachmentZipped: (attachment: Attachment) => void,
  ) {
    const zip = new JSZip();
    for (const attachment of attachments) {
      const containerClient = this.blobServiceClient.getContainerClient(
        attachment.containerName,
      );
      const blobClient = containerClient.getBlobClient(
        `${attachment.inquiryIdentifier}/${attachment.fileName}`,
      );

      const downloadBlockBlobResponse = await blobClient.download();
      const blob = await downloadBlockBlobResponse.blobBody;
      const fileName = `${attachment.fileName}.${attachment.extension}`;
      zip.file(fileName, blob);

      attachmentZipped(attachment);
    }

    zip.generateAsync({ type: 'blob' }).then(function (content) {
      saveAs(content, resultFileName);
    });
  }

  fileListToArray(fileList: FileList): File[] {
    const files: File[] = [];
    for (let i = 0; i < fileList.length; i++) {
      const file = fileList[i];
      files.push(file);
    }
    return files;
  }

  private async createUploadFileRequest(
    inquiryIdentifier: string,
    fileNameWithExtension: string,
  ): Promise<AttachmentUpload> {
    return await firstValueFrom(
      this.attachmentService.createAttachmentUploadRequest(
        inquiryIdentifier,
        fileNameWithExtension,
      ),
    );
  }

  private async uploadAttachment(sasUri: string, file: File): Promise<boolean> {
    const blobBlobClient = new BlobClient(sasUri);
    const response = await blobBlobClient
      .getBlockBlobClient()
      .uploadData(file, { blobHTTPHeaders: { blobContentType: file.type } });

    return !response.errorCode;
  }

  private async createAttachment(
    storageIdentifier: string,
    blobPath: string,
    associatedCallPeriod: AssociatedCallPeriod,
    inquiryId: string,
    fileName: string,
    createThumbnail: boolean,
  ): Promise<Attachment> {
    const request: CreateAttachmentRequest = {
      storageIdentifier: storageIdentifier,
      blobPath: blobPath,
      period: associatedCallPeriod,
      inquiryIdentifier: inquiryId,
      fileName: AttachmentFileService.getFileNameWithoutExtension(fileName),
      extension: AttachmentFileService.getFileExtension(fileName),
      origin: Origin.Uploaded,
      author: Author.Customer,
      createThumbnail: createThumbnail,
    };

    return await firstValueFrom(
      this.attachmentService.createAttachment(request).pipe(timeout(10_000)),
    );
  }

  private uploading(fileName: string): AttachmentWithState {
    return new AttachmentWithState(fileName, null, AttachmentState.UPLOADING);
  }

  private success(
    fileName: string,
    attachment: Attachment,
  ): AttachmentWithState {
    return new AttachmentWithState(
      fileName,
      attachment,
      AttachmentState.SUCCESSFUL,
    );
  }

  private fail(fileName: string): AttachmentWithState {
    return new AttachmentWithState(fileName, null, AttachmentState.FAILURE);
  }

  private uploadError(fileName: string): FileAttachmentUploadError {
    return new FileAttachmentUploadError(
      fileName,
      `Error uploading file with name ${fileName}`,
    );
  }
}

export class FileAttachmentUploadError extends Error {
  affectedFileName: string;

  constructor(affectedFileName: string, message: string) {
    super(message);

    this.affectedFileName = affectedFileName;
  }
}
