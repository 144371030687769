import { AttachmentService as Api } from '../../../api/gen';
import { map, Observable } from 'rxjs';
import { Attachment } from '../../model/attachment/attachment';
import { AttachmentFiltersRequest } from '../../model/attachment/request/attachment-filters-request';
import { Injectable } from '@angular/core';
import { UpdateAttachmentNote } from '../../model/attachment/request/update-attachment-note';
import { AttachmentUpload } from '../../model/attachment/attachment-upload';
import { CreateAttachmentUpload } from '../../model/attachment/request/create-attachment-upload';
import { CreateAttachmentRequest } from '../../model/attachment/request/create-attachment-request';
import { AddAnnotationRequest } from '../../model/attachment/request/add-annotation-request';
import { MarkerJSAnnotationState } from '../../model/attachment/marker-js-annotation-state';

@Injectable({ providedIn: 'root' })
export class AttachmentService {
  constructor(private readonly attachmentService: Api) {}

  getAttachment(attachmentId: string): Observable<Attachment> {
    return this.attachmentService
      .attachmentAttachmentIdGet(attachmentId)
      .pipe(map((dto) => Attachment.fromDto(dto)));
  }

  getAttachments(
    inquiryId: string,
    attachmentFilters?: AttachmentFiltersRequest,
  ): Observable<Attachment[]> {
    return this.attachmentService
      .attachmentInquiryInquiryIdPost(inquiryId, attachmentFilters ?? {})
      .pipe(map((x) => x.map((dto) => Attachment.fromDto(dto))));
  }

  deleteAttachment(attachmentId: string): Observable<Attachment> {
    return this.attachmentService
      .attachmentAttachmentIdDelete(attachmentId)
      .pipe(map((dto) => Attachment.fromDto(dto)));
  }

  createAttachmentUploadRequest(
    inquiryIdentifier: string,
    fileNameWithExtension: string,
  ): Observable<AttachmentUpload> {
    const request = {
      inquiryIdentifier: inquiryIdentifier,
      fileNameWithExtension: fileNameWithExtension,
    } as CreateAttachmentUpload;
    return this.attachmentService
      .attachmentUploadPost(request)
      .pipe(map((dto) => AttachmentUpload.fromDto(dto)));
  }

  createAttachment(request: CreateAttachmentRequest): Observable<Attachment> {
    return this.attachmentService
      .attachmentPost(request)
      .pipe(map((dto) => Attachment.fromDto(dto)));
  }

  updateAttachmentNote(
    attachmentId: string,
    note?: string,
  ): Observable<Attachment> {
    const body = { note: note } as UpdateAttachmentNote;
    return this.attachmentService
      .attachmentAttachmentIdNotePut(
        attachmentId,
        UpdateAttachmentNote.toRequest(body),
      )
      .pipe(map((dto) => Attachment.fromDto(dto)));
  }

  createAnnotatedAttachmentUploadRequest(
    attachmentId: string,
  ): Observable<AttachmentUpload> {
    return this.attachmentService
      .attachmentAttachmentIdAnnotationUploadPost(attachmentId)
      .pipe(map((dto) => AttachmentUpload.fromDto(dto)));
  }

  addAnnotationToAttachment(
    attachmentId: string,
    request: AddAnnotationRequest,
  ): Observable<Attachment> {
    return this.attachmentService
      .attachmentAttachmentIdAnnotationPost(attachmentId, request)
      .pipe(map((dto) => Attachment.fromDto(dto)));
  }

  getAnnotationStateMarkerJs(
    attachmentId: string,
  ): Observable<MarkerJSAnnotationState> {
    return this.attachmentService
      .attachmentAttachmentIdAnnotationStateGet(attachmentId)
      .pipe(map((dto) => MarkerJSAnnotationState.toDto(dto)));
  }
}
