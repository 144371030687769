import { UpdateCompanyLogo } from '../../../../api/gen';

export class UpdateCompanyLogoRequest {
  logoFileName?: string | null;
  base64LogoData?: string | null;

  static fromDto(dto: UpdateCompanyLogo): UpdateCompanyLogoRequest {
    return {
      logoFileName: dto.logoFileName,
      base64LogoData: dto.base64LogoData,
    } as UpdateCompanyLogoRequest;
  }

  static toDto(request: UpdateCompanyLogoRequest): UpdateCompanyLogo {
    return {
      logoFileName: request.logoFileName,
      base64LogoData: request.base64LogoData,
    } as UpdateCompanyLogo;
  }
}
