import { NotificationDto } from '../../../api/gen';
import { NotificationType } from './notification-type';
import { ReferenceType } from './reference-type';

export class Notification {
  id?: string;
  intendedFor?: string;
  type?: NotificationType;
  readAt?: string | null;
  referenceId?: string;
  referenceType?: ReferenceType;

  static fromDto(dto: NotificationDto): Notification {
    return {
      id: dto.id,
      intendedFor: dto.intendedFor,
      type: dto.type,
      readAt: dto.readAt,
      referenceId: dto.referenceId,
      referenceType: dto.referenceType,
    } as Notification;
  }
}
