<mat-card class="contact-container">
  <app-card-header-with-action
    i18n-title
    title="Kontakt"
    (actionClicked)="showEditContactDialog()"
  ></app-card-header-with-action>
  <div *ngIf="employee">
    <mat-list>
      <mat-list-item>
        <mat-icon matListItemIcon>people</mat-icon>
        <div matListItemTitle class="ns">
          {{ employee.firstName }}
          {{ employee.lastName }}
        </div>
      </mat-list-item>

      <mat-list-item>
        <mat-icon matListItemIcon>mail</mat-icon>
        <div matListItemTitle>
          <div class="about-card-email-container">
            <span class="ns">{{ employee.email }}</span>
            <mat-icon
              *ngIf="employee.emailVerified"
              class="about-card-email-icon"
              >verified
            </mat-icon>
            @if (!employee.emailVerified) {
              <mat-icon color="warn">error</mat-icon>
              @if (validatingEmail$()) {
                <mat-spinner diameter="20"></mat-spinner>
              } @else if (!emailValidationSent$()) {
                <button
                  mat-button
                  color="primary"
                  i18n
                  (click)="onSendValidationEmail(employee)"
                >
                  E-Mail validieren
                </button>
              }
            }
          </div>
        </div>
      </mat-list-item>

      <mat-list-item>
        <mat-icon matListItemIcon>people</mat-icon>
        <div matListItemTitle class="ns">{{ getRoleText() }}</div>
      </mat-list-item>
    </mat-list>
  </div>
</mat-card>
