import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';

import { AnalyticsService } from './api/analytics.service';
import { ApplicationSettingsService } from './api/applicationSettings.service';
import { AppointmentService } from './api/appointment.service';
import { AppointmentDashboardService } from './api/appointmentDashboard.service';
import { AppointmentRecordingService } from './api/appointmentRecording.service';
import { AssignInquiryService } from './api/assignInquiry.service';
import { AttachmentService } from './api/attachment.service';
import { AttachmentCvService } from './api/attachmentCv.service';
import { AuthenticationTokenService } from './api/authenticationToken.service';
import { BrandingColorService } from './api/brandingColor.service';
import { BrandingInvitationService } from './api/brandingInvitation.service';
import { CompanyService } from './api/company.service';
import { CompanyAddressService } from './api/companyAddress.service';
import { CompanyLoginService } from './api/companyLogin.service';
import { DistanceService } from './api/distance.service';
import { EmailAvailableService } from './api/emailAvailable.service';
import { EmployeeService } from './api/employee.service';
import { EmployeeInviteService } from './api/employeeInvite.service';
import { FeatureTrackingService } from './api/featureTracking.service';
import { InitializationStatusService } from './api/initializationStatus.service';
import { InquiryService } from './api/inquiry.service';
import { InquiryAccessService } from './api/inquiryAccess.service';
import { InquiryDashboardService } from './api/inquiryDashboard.service';
import { InquiryEditService } from './api/inquiryEdit.service';
import { InquirySettingsService } from './api/inquirySettings.service';
import { InvitationStatusService } from './api/invitationStatus.service';
import { LegalEntityService } from './api/legalEntity.service';
import { LiveChatService } from './api/liveChat.service';
import { LiveChatTranslationService } from './api/liveChatTranslation.service';
import { NotesDocumentationService } from './api/notesDocumentation.service';
import { NotificationCenterService } from './api/notificationCenter.service';
import { NotificationValidationService } from './api/notificationValidation.service';
import { PingService } from './api/ping.service';
import { PluginService } from './api/plugin.service';
import { ProfileService } from './api/profile.service';
import { QuotaService } from './api/quota.service';
import { RegistrationValidationService } from './api/registrationValidation.service';
import { ReportService } from './api/report.service';
import { StaticTemplateService } from './api/staticTemplate.service';
import { SubscriptionService } from './api/subscription.service';
import { SyncAppointmentService } from './api/syncAppointment.service';
import { ValidateEmailService } from './api/validateEmail.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: []
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
