import { Component } from '@angular/core';
import { DeviceService } from '../../services/device.service';

@Component({
  selector: 'app-setting-dialog',
  templateUrl: './setting-dialog.component.html',
  styleUrls: ['./setting-dialog.component.scss'],
})
export class SettingDialogComponent {
  constructor(private readonly deviceService: DeviceService) {}

  onSettingsChanged(deviceInfo?: MediaDeviceInfo) {
    this.deviceService.settingsChanged$.next(deviceInfo);
  }
}
