import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { first, map, takeUntil } from 'rxjs/operators';
import { CanvasSyncService } from '../annotations/services/canvas-sync.service';
import { EndCallDialogComponent } from './dialogs/end-call-dialog/end-call-dialog.component';
import { DeactivationGuarded } from './services/deactivate-guard.service';
import { VideoChatService } from './services/videochat.service';
import { AnalyticsService } from '../services/api/analytics.service';
import { SyncService } from './services/sync.service';
import { BreakpointObserver } from '@angular/cdk/layout';
import { OverscrollService } from '../services/overscroll.service';

@Component({
  selector: 'app-video-appointment',
  templateUrl: './video-appointment.component.html',
  styleUrls: ['./video-appointment.component.scss'],
  providers: [CanvasSyncService],
})
export class VideoAppointmentComponent
  implements OnInit, OnDestroy, DeactivationGuarded
{
  protected inquiryId: string;
  protected usePortrait = false;

  private readonly unsubscribe$ = new Subject<void>();

  constructor(
    private readonly videoChatService: VideoChatService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly dialog: MatDialog,
    private readonly analyticsService: AnalyticsService,
    private readonly syncService: SyncService,
    private readonly cdr: ChangeDetectorRef,
    breakpointObserver: BreakpointObserver,
    private readonly overScrollService: OverscrollService,
  ) {
    breakpointObserver
      .observe(['(orientation: portrait)', '(orientation: landscape)'])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((x) => {
        this.usePortrait = x.breakpoints['(orientation: portrait)'];
        this.cdr.markForCheck();
      });
    this.overScrollService.turnOffOverScroll();
  }

  canDeactivate(): boolean | Observable<boolean> {
    const room = this.videoChatService.activeRoom$.value;
    if (room && !room.data?.roomFinishedAt) {
      const dialogRef = this.dialog.open(EndCallDialogComponent);
      return dialogRef.afterClosed().pipe(
        map((result) => {
          if (result) {
            this.videoChatService.endCall();
            return true;
          }
          return false;
        }),
      );
    } else {
      return true;
    }
  }

  async ngOnInit() {
    this.activatedRoute.queryParams.pipe(first()).subscribe((params) => {
      this.inquiryId = params['inquiryId'];
      this.connectToRoom();
    });
  }

  ngOnDestroy() {
    this.overScrollService.resetToDefault();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private async connectToRoom() {
    if (this.inquiryId) {
      await this.videoChatService.joinRoom(this.inquiryId);
    }
  }
}
