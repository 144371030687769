import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-card-header-with-action',
  templateUrl: './card-header-with-action.component.html',
  styleUrls: ['./card-header-with-action.component.scss'],
})
export class CardHeaderWithActionComponent {
  @Input() title: string;
  @Input() actionIcon = 'edit';
  @Input() actionText = $localize`Bearbeiten`;
  @Input() actionEnabled = true;
  @Input() actionColor: ActionColor = ActionColor.Primary;

  @Output() actionClicked = new EventEmitter<void>();

  onActionClicked() {
    this.actionClicked.next();
  }
}

export enum ActionColor {
  Primary = 'primary',
  Accent = 'accent',
  Warn = 'warn',
}
