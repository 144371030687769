/**
 * Agent Client
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type InvitationLanguage = 'German' | 'English' | 'Unknown';

export const InvitationLanguage = {
    German: 'German' as InvitationLanguage,
    English: 'English' as InvitationLanguage,
    Unknown: 'Unknown' as InvitationLanguage
};

