import { Injectable } from '@angular/core';
import { AppointmentAttachmentsService } from './appointment-attachments.service';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class VideochatContentService {
  private readonly currentContent$ = new BehaviorSubject<ContentType>(
    ContentType.DEFAULT,
  );

  constructor(
    private readonly appointmentService: AppointmentAttachmentsService,
  ) {
    this.appointmentService.onCurrentAttachment().subscribe((attachment) => {
      this.currentContent$.next(
        attachment ? ContentType.ATTACHMENT : ContentType.DEFAULT,
      );
    });
  }

  public onCurrentContent(): Observable<ContentType> {
    return this.currentContent$.asObservable();
  }
}

export enum ContentType {
  DEFAULT,
  ATTACHMENT,
}
