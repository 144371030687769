<div class="validate-email-container">
  @if (isLoading$()) {
    <mat-spinner></mat-spinner>
  } @else {
    <img
      width="200px"
      src="assets/images/blankmile-tm-path.svg"
      alt="blankmile TM logo"
    />
    @if (companyLoginInfo$().logoUrl) {
      <img
        class="company-logo"
        width="200px"
        src="{{ companyLoginInfo$().logoUrl }}"
        alt="company logo"
      />
    }
    @if (isValid$()) {
      <h1 class="ns" i18n>Hallo {{ email$() }}!</h1>
      <span class="validate-text ns" i18n
        >Sie wurden von {{ companyLoginInfo$().displayName }} eingeladen
        blankmile beizutreten. <br />
        Validieren Sie hier Ihre E-Mail Adresse und starten Sie gleich mit Ihrem
        ersten Einsatz.</span
      >
      <button mat-flat-button color="primary" i18n (click)="onValidateClick()">
        Validieren
      </button>
    } @else {
      <h1 class="link-not-valid ns" i18n>
        Der eingegebene Link ist nicht gültig.
      </h1>
    }
  }
</div>
