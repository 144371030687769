<h1 mat-dialog-title i18n>{{ data.attachment.fileName }} löschen?</h1>
<div mat-dialog-content>
  <div
    *ngIf="isLoading$ | async; else previewImage"
    class="loading-spinner-container"
  >
    <mat-spinner></mat-spinner>
  </div>

  <ng-template #previewImage>
    <div class="preview-image-container">
      <img [src]="attachmentSource" class="preview-image" />
    </div>
  </ng-template>
</div>
<div mat-dialog-actions class="dialog-actions">
  <button
    (click)="onDeleteAttachment()"
    cdkFocusInitial
    color="primary"
    mat-button
    i18n
    [disabled]="isLoading$ | async"
  >
    Löschen
  </button>
  <button
    color="warn"
    mat-button
    i18n
    mat-dialog-close
    [disabled]="isLoading$ | async"
  >
    Abbrechen
  </button>
</div>
