import { ProfileService as ProfileApi } from '../../../api/gen';
import { map, Observable } from 'rxjs';
import { Employee } from '../../model/employee/employee';
import { Injectable } from '@angular/core';
import { UpdateEmployeeRequest } from '../../model/employee/request/update-employee-request';

@Injectable()
export class ProfileService {
  constructor(private readonly profileService: ProfileApi) {}

  getProfile(): Observable<Employee> {
    return this.profileService
      .profileGet()
      .pipe(map((dto) => Employee.fromDto(dto)));
  }

  updateProfile(request: UpdateEmployeeRequest): Observable<Employee> {
    return this.profileService
      .profilePut(UpdateEmployeeRequest.toDto(request))
      .pipe(map((dto) => Employee.fromDto(dto)));
  }
}
