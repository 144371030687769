import { Injectable } from '@angular/core';
import { firstValueFrom, map, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  createLocalAudioTrack,
  createLocalVideoTrack,
  LocalAudioTrack,
  LocalDataTrack,
  LocalVideoTrack,
} from 'twilio-video';
import { CallCtrlService } from './call-ctrl.service';
import {
  CamState,
  DeviceService,
  DeviceType,
  MicState,
} from './device.service';
import { PermissionService } from './permission.service';
import * as Sentry from '@sentry/angular-ivy';

@Injectable({
  providedIn: 'root',
})
export class LocalTrackCtrlService {
  public videoTrackUpdated$ = new Subject<LocalVideoTrack>();
  public audioTrackUpdated$ = new Subject<LocalAudioTrack>();
  public dataTrackUpdated$ = new Subject<LocalDataTrack>();

  private _videoTrack: LocalVideoTrack;
  private _audioTrack: LocalAudioTrack;
  private _dataTrack: LocalDataTrack;

  private readonly unsubscribe$ = new Subject();
  private ongoingVideoInit: Promise<LocalVideoTrack> | null;
  private ongoingAudioInit: Promise<LocalAudioTrack> | null;

  constructor(
    private readonly deviceService: DeviceService,
    private readonly callCtrlService: CallCtrlService,
    private readonly permissionService: PermissionService,
  ) {
    this.callCtrlService
      .subscribeToCamState()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((x) => {
        if (x === CamState.Off) {
          this._videoTrack?.disable();
        } else {
          this._videoTrack?.enable();
        }
      });

    this.callCtrlService
      .subscribeToMicState()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((x) => {
        if (x === MicState.Muted) {
          this._audioTrack?.disable();
        } else {
          this._audioTrack?.enable();
        }
      });

    this.callCtrlService.toggleFlash$.subscribe((_) => {
      this.sendDataObject(
        DataTrackObject.withType(DataTrackObjectType.TOGGLE_FLASH).withData(
          null,
        ),
      );
    });

    this.callCtrlService.changeZoom$.subscribe((newState) => {
      this.sendDataObject(
        DataTrackObject.withType(DataTrackObjectType.CHANGE_ZOOM).withData(
          newState,
        ),
      );
    });

    this.deviceService.settingsChanged$
      .pipe(map((x) => x.kind as DeviceType))
      .subscribe(async (x) => {
        if (x === DeviceType.AUDIO_INPUT) {
          this.detachAudioTrack();
          await this.getAudioTrack();
        } else if (x === DeviceType.VIDEO_INPUT) {
          this.detachVideoTrack();
          await this.getVideoTrack();
        }
      });
  }

  private createDataTrack(): LocalDataTrack {
    const newTrack = new LocalDataTrack();
    this.dataTrackUpdated$.next(newTrack);
    return newTrack;
  }

  public clearTracks() {
    this.detachVideoTrack();
    this.detachAudioTrack();

    this._dataTrack = null;
  }

  public async getVideoTrack(): Promise<LocalVideoTrack> {
    if (this._videoTrack) return this._videoTrack;
    if (!this.ongoingVideoInit) {
      this.ongoingVideoInit = this.createVideoTrack();
    }
    this._videoTrack = await this.ongoingVideoInit;
    this.ongoingVideoInit = null;
    return this._videoTrack;
  }

  public async getAudioTrack(): Promise<LocalAudioTrack> {
    if (this._audioTrack) return this._audioTrack;
    if (!this.ongoingAudioInit) {
      this.ongoingAudioInit = this.createAudioTrack();
    }
    this._audioTrack = await this.ongoingAudioInit;

    this.ongoingAudioInit = null;
    return this._audioTrack;
  }

  public getDataTrack(): LocalDataTrack {
    if (this._dataTrack) return this._dataTrack;
    this._dataTrack = this.createDataTrack();
    return this._dataTrack;
  }

  public sendDataObject(data: DataTrackObject): void {
    this.getDataTrack().send(JSON.stringify(data));
  }

  public attachVideoTrack(): HTMLMediaElement {
    return this._videoTrack?.attach();
  }

  public detachVideoTrack(): void {
    try {
      if (this._videoTrack) {
        this._videoTrack.disable();
        this._videoTrack.stop();
        this._videoTrack.detach().forEach((element) => element.remove());
        this._videoTrack = null;
        this.ongoingVideoInit = null;
      }
    } catch (e) {
      console.error(e);
    }
  }

  public detachAudioTrack(): void {
    try {
      if (this._audioTrack) {
        this._audioTrack.disable();
        this._audioTrack.stop();
        this._audioTrack.detach().forEach((element) => element.remove());
        this._audioTrack = null;
        this.ongoingAudioInit = null;
      }
    } catch (e) {
      console.error(e);
    }
  }

  // TODO: Singleton, resolve if multiple neeeded
  private async createVideoTrack(): Promise<LocalVideoTrack> {
    const permissions = await firstValueFrom(
      this.permissionService.observeChanges(),
    );
    if (!permissions.isCamGiven()) return null;

    const selectedVideoInput = this.deviceService.getSelectedVideoDeviceId();
    const newVideoTrack = await createLocalVideoTrack({
      deviceId: selectedVideoInput ?? undefined,
      facingMode: selectedVideoInput ? 'user' : null,
      width: { ideal: 1280, min: 640, max: 1920 },
      height: { ideal: 720, min: 480, max: 1080 },
      frameRate: { ideal: 60, min: 10, max: 60 },
      aspectRatio: 16 / 9,
    }).catch((x) => {
      console.warn(x, 'Could not create video track');
      return null;
    });
    this.videoTrackUpdated$.next(newVideoTrack);
    return newVideoTrack;
  }

  private async createAudioTrack(): Promise<LocalAudioTrack> {
    const permissions = await firstValueFrom(
      this.permissionService.observeChanges(),
    );
    if (!permissions.isMicGiven()) return null;

    const selectedAudioInput = this.deviceService.getSelectedAudioDeviceId();
    const newAudioTrack = await createLocalAudioTrack({
      deviceId: selectedAudioInput ?? undefined,
      //suppressLocalAudioPlayback: true,
      noiseSuppression: true,
      echoCancellation: true,
    }).catch((x) => {
      console.warn(x, 'Could not create audio track');
      Sentry.captureException(x);
      return null;
    });
    this.audioTrackUpdated$.next(newAudioTrack);
    return newAudioTrack;
  }
}

export class DataTrackObject {
  type: DataTrackObjectType;
  data?: any;

  private constructor(type: DataTrackObjectType) {
    this.type = type;
  }

  public static withType(type: DataTrackObjectType): DataTrackObject {
    return new DataTrackObject(type);
  }

  public withData(data?: any): DataTrackObject {
    this.data = data;
    return this;
  }
}

export enum DataTrackObjectType {
  PRESENTING = 0,
  SCREENSHOT = 1,
  ANNOTATE = 2,
  CAPABILITIES_ANSWER = 3,
  TOGGLE_FLASH = 4,
  DIAGNOSTICS = 5,
  HEARTBEAT = 6,
  CHANGE_ZOOM = 7,
  LIVE_IMAGE = 8,
}
