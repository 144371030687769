/**
 * Agent Client
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type EmployeeType = 'Agent' | 'Manager' | 'Administrator';

export const EmployeeType = {
    Agent: 'Agent' as EmployeeType,
    Manager: 'Manager' as EmployeeType,
    Administrator: 'Administrator' as EmployeeType
};

