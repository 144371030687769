import { Injectable } from '@angular/core';
import { StaticTemplateService as StaticTemplateApi } from 'projects/helper-client/src/api/gen';
import { StaticTemplate } from '../../model/report/static-template';
import { map, Observable } from 'rxjs';

@Injectable()
export class StaticTemplateService {
  constructor(private readonly staticTemplateService: StaticTemplateApi) {}

  getStaticTemplateByIdentifierAndCulture(
    identifier: string,
    culture: string,
  ): Observable<StaticTemplate> {
    return this.staticTemplateService
      .staticTemplateIdentifierGet(identifier, culture)
      .pipe(map((dto) => StaticTemplate.fromDto(dto)));
  }

  getStaticTemplatesByIdentifier(
    identifier: string,
  ): Observable<StaticTemplate[]> {
    return this.staticTemplateService
      .staticTemplateIdentifierAllCulturesGet(identifier)
      .pipe(map((x) => x.map((dto) => StaticTemplate.fromDto(dto))));
  }

  getStaticTemplates(): Observable<StaticTemplate[]> {
    return this.staticTemplateService
      .staticTemplateGet()
      .pipe(map((x) => x.map((dto) => StaticTemplate.fromDto(dto))));
  }
}
