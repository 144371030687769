<app-heading-with-icon-and-action
  i18n-title
  title="Terminverlauf"
  icon="history"
></app-heading-with-icon-and-action>

<div class="section-container">
  <app-info-text-with-icon
    i18n-text
    text="Hier werden alle Ereignisse des Termins gezeigt."
  ></app-info-text-with-icon>

  <div class="timeline-content mat-elevation-z2">
    <mat-list>
      <mat-list-item *ngFor="let event of timeline">
        <mat-icon matListItemIcon>{{
          getIconForEvent(event.eventType)
        }}</mat-icon>
        <span matListItemTitle>{{ getTitleForEvent(event.eventType) }}</span>
        <span matListItemLine>
          {{ event.date | date: "EEEEEE, dd.MM.yyyy HH:mm" }}
        </span>
      </mat-list-item>
    </mat-list>
  </div>
</div>
