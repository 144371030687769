import { ValidateRegistrationToken } from '../../../../api/gen';

export class ValidateRegistrationTokenRequest {
  token?: string | null;
  tenantIdentifier?: string | null;

  static toDto(
    request: ValidateRegistrationTokenRequest,
  ): ValidateRegistrationToken {
    return {
      token: request.token,
      tenantIdentifier: request.tenantIdentifier,
    } as ValidateRegistrationToken;
  }
}
