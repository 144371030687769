export class InquiryCommands {
  public static get NOTIFY_INQUIRY_CHANGED(): string {
    return 'inquiry_notifyInquiryChanged';
  }

  public static get NOTIFY_APPOINTMENT_CHANGED(): string {
    return 'inquiry_notifyAppointmentChanged';
  }

  public static get NOTIFY_NEW_INQUIRY_AVAILABLE(): string {
    return 'inquiry_notifyNewInquiryAvailable';
  }
}
