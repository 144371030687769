<div class="dialog-header">
  <h2 class="dialog-title" mat-dialog-title i18n>Neuen Anruf starten</h2>
  <mat-icon (click)="closeDialog()" *ngIf="isMobile()" class="close"
    >close</mat-icon
  >
</div>
<mat-dialog-content class="dialog-content">
  <div class="content-section">
    <div class="input-margin">
      <span class="dialog-body-text" i18n
        >Möchtest du einen neuen Anruf mit diesem Teilnehmer starten?</span
      >
    </div>
  </div>

  <div class="actions">
    <button
      *ngIf="!isMobile()"
      class="cancel-button-style"
      color="primary"
      mat-button
      mat-dialog-close
    >
      <mat-icon class="mat-icon-style icon-padding-right cancel-mat-icon-style"
        >close</mat-icon
      >
      <span i18n>Abbrechen</span>
    </button>

    <button
      (click)="closeDialog()"
      class="join-button-style"
      color="primary"
      mat-raised-button
    >
      <mat-icon class="mat-icon-style icon-padding-right send-mat-icon-style"
        >video_call</mat-icon
      >
      <span i18n>Einladung senden</span>
    </button>
  </div>
</mat-dialog-content>
