<div
  class="container"
  (@flashState.done)="flashState = 'default'"
  [@flashState]="flashState"
>
  <div class="icon">
    @if (mode === PictureMode.DEFAULT) {
      <mat-icon>photo_camera</mat-icon>
    } @else if (mode === PictureMode.LIVE) {
      <mat-icon svgIcon="animated_images" aria-hidden="false"></mat-icon>
    }
  </div>
  <div class="text">in</div>
  <div class="value">
    {{ countDown$ | async }}
  </div>
</div>
