import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  ViewChild,
} from '@angular/core';
import { BehaviorSubject, map } from 'rxjs';
import { Notification } from '../../model/notification-center/notification';
import { NotificationCenterService } from '../../services/api/notification-center.service';
import {
  NotificationCenterMessageType,
  NotificationCenterMessagingService,
} from '../../services/signaling/messaging/notification-center-messaging.service';

@Component({
  selector: 'app-notification-center',
  standalone: false,
  templateUrl: './notification-center.component.html',
  styleUrls: ['./notification-center.component.scss'],
  providers: [NotificationCenterService],
})
export class NotificationCenterComponent {
  @ViewChild('openCloseButton') openCloseButton: ElementRef;
  @ViewChild('container') container: ElementRef;

  protected notifications$ = new BehaviorSubject<Notification[]>([]);
  protected isLoading$ = new BehaviorSubject(true);
  protected isNotificationCenterOpen$ = new BehaviorSubject(false);

  private preventOutsideClick = false;

  constructor(
    private readonly notificationCenterService: NotificationCenterService,
    private readonly notificationCenterSignalingService: NotificationCenterMessagingService,
    private readonly cdr: ChangeDetectorRef,
  ) {
    this.notificationCenterSignalingService
      .onMessage(NotificationCenterMessageType.notificationCreated)
      .subscribe(() => {
        this.refreshNotifications();
      });

    this.refreshNotifications();
  }

  private refreshNotifications() {
    this.notificationCenterService
      .getNotificationsOfLastMonth()
      .pipe(map((res) => res.filter((r) => r.readAt === null)))
      .subscribe({
        next: (notifications) => {
          this.notifications$.next(notifications);
          this.cdr.markForCheck();
          this.isLoading$.next(false);
        },
        error: () => {
          this.isLoading$.next(false);
        },
      });
  }

  @HostListener('document:click', ['$event'])
  @HostListener('document:touchstart', ['$event'])
  handleOutsideClick(event: any) {
    if (this.preventOutsideClick) {
      this.preventOutsideClick = false;
      return;
    }
    if (
      this.container &&
      !this.container.nativeElement.contains(event.target) &&
      event.target !== this.openCloseButton
    ) {
      this.isNotificationCenterOpen$.next(false);
    }
  }

  openCloseNotificationCenter() {
    this.isNotificationCenterOpen$.next(!this.isNotificationCenterOpen$.value);
    this.preventOutsideClick = true;
  }

  onNotificationRead(notification: Notification) {
    const current = this.notifications$.value;
    const newNotifications = current.filter((n) => n.id !== notification.id);
    this.notifications$.next(newNotifications);
  }
}
