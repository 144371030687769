@if (length > 0) {
  <div class="pending-attachments">
    <span i18n
      >Lade
      {length, plural, =1 {Anhang} other {{{ length }} Anhänge}}
      hoch...</span
    >
    <mat-spinner diameter="20"></mat-spinner>
  </div>
}
