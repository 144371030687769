<div class="header-container">
  <span class="header-title ns"> {{ title }}</span>
  <span class="spacer"></span>
  <button
    *ngIf="actionEnabled"
    (click)="onActionClicked()"
    color="{{ actionColor }}"
    mat-raised-button
  >
    <mat-icon>{{ actionIcon }}</mat-icon>
    {{ actionText }}
  </button>
</div>
