import { PhoneNumberValidationResult as PhoneNumberValidationResultDto } from '../../../../api/gen';
import { PhoneNumberValidation } from './phone-number-validation';

export class PhoneNumberValidationResult {
  couldValidate?: boolean;
  validation?: PhoneNumberValidation;

  public static fromDto(
    dto: PhoneNumberValidationResultDto,
  ): PhoneNumberValidationResult {
    return {
      couldValidate: dto.couldValidate,
      validation:
        dto.validation != null
          ? PhoneNumberValidation.fromDto(dto.validation)
          : null,
    } as PhoneNumberValidationResult;
  }
}
