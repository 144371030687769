import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { Employee } from '../../model/employee/employee';

@Injectable({
  providedIn: 'root',
})
export class ProfileDataService {
  public employeeInformation$: Observable<Employee>;
  private readonly employeeInformationSubject$: ReplaySubject<Employee>;

  constructor() {
    this.employeeInformationSubject$ = new ReplaySubject<Employee>(1);
    this.employeeInformation$ = this.employeeInformationSubject$.asObservable();
  }

  public updateEmployeeInformation(employeeInfo: Employee) {
    this.employeeInformationSubject$.next(employeeInfo);
  }
}
