@if (shouldInstall()) {
  <button
    mat-flat-button
    class="install-btn"
    type="button"
    color="primary"
    (click)="installPWA()"
  >
    <mat-icon matListItemIcon>install_desktop</mat-icon>
    <span i18n>Installieren</span>
  </button>
}
