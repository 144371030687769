import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  first,
  firstValueFrom,
  map,
  Observable,
  Subject,
  takeUntil,
  timer,
} from 'rxjs';
import { AidarPermissionStatus } from '../../services/permission.service';
import {
  CallCtrlService,
  FlashState,
  ZoomState,
} from '../../services/call-ctrl.service';
import { RecordingService } from '../../services/recording.service';
import {
  ContentType,
  VideochatContentService,
} from '../../services/videochat-content.service';
import {
  ConnectionState,
  DiagnosticsService,
} from '../../services/diagnostics/diagnostics.service';
import { MatDialog } from '@angular/material/dialog';
import { CamState, MicState } from '../../services/device.service';
import { DashboardInformationService } from '../../../scheduling/services/dashboard-information.service';
import { Mode } from '../../../annotations/drawing-canvas/drawing-canvas.component';
import { SettingDialogComponent } from '../../dialogs/setting-dialog/setting-dialog.component';

@Component({
  selector: 'app-call-ctrl',
  templateUrl: './call-ctrl.component.html',
  styleUrls: ['./call-ctrl.component.scss'],
})
export class CallCtrlComponent implements OnInit, OnDestroy {
  @Output() callEnded = new EventEmitter<void>();
  @Output() modeChanged = new EventEmitter<Mode>();
  @Output() clearCanvas = new EventEmitter<void>();
  @Output() takeScreenshot = new EventEmitter<void>();
  @Output() recordLiveImage = new EventEmitter<void>();

  @Input() public mode$: Observable<Mode>;
  @Input() public callStartedAt: string;
  @Input() public callEndedAt: string;
  @Input() public recordingAllowed: boolean;
  @Input() public livePhotoAllowed: boolean;
  @Input() public pictureLoading = false;

  public Mode = Mode;
  public isCameraEnabled$: Observable<boolean>;
  public isMicrophoneEnabled$: Observable<boolean>;
  public isRecording$: Observable<boolean>;
  public FlashState = FlashState;
  public ZoomState = ZoomState;
  protected DeviceAvailability = DeviceAvailability;

  protected timer: Observable<string>;
  protected content: Observable<ContentType>;
  protected ContentType = ContentType;
  private currentMode = Mode.Idle;
  private readonly unsubscribe$ = new Subject<void>();

  protected videoCtrlsEnabled = false;

  protected camAvailability = new Subject<DeviceAvailability>();
  protected micAvailability = new Subject<DeviceAvailability>();

  constructor(
    protected callCtrlService: CallCtrlService,
    protected readonly recordingService: RecordingService,
    protected readonly videochatContentService: VideochatContentService,
    diagnostics: DiagnosticsService,
    public dialog: MatDialog,
  ) {
    this.content = this.videochatContentService.onCurrentContent();

    this.isCameraEnabled$ = callCtrlService.subscribeToCamState().pipe(
      map((x) => x === CamState.On),
      takeUntil(this.unsubscribe$),
    );
    this.isMicrophoneEnabled$ = callCtrlService.subscribeToMicState().pipe(
      map((x) => x === MicState.Unmuted),
      takeUntil(this.unsubscribe$),
    );

    this.isRecording$ = this.recordingService.isRecording$.pipe(
      takeUntil(this.unsubscribe$),
    );

    diagnostics.diagnosticsChanged$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((x) => {
        CallCtrlComponent.evaluateDeviceAvailability(
          this.camAvailability,
          x.localCameraPermissionStatus,
          x.localCameraDevicesAvailable,
        );
        CallCtrlComponent.evaluateDeviceAvailability(
          this.micAvailability,
          x.localMicrophonePermissionStatus,
          x.localMicrophoneDevicesAvailable,
        );
      });

    diagnostics.diagnosticsChanged$
      .pipe(
        takeUntil(this.unsubscribe$),
        map(
          (x) =>
            x.remoteParticipantConnected &&
            !x.remoteConnectionLost &&
            x.remoteCameraTurnedOn &&
            x.connectionState == ConnectionState.Connected,
        ),
      )
      .subscribe((x) => {
        this.videoCtrlsEnabled = x;
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnInit(): void {
    this.mode$.pipe(takeUntil(this.unsubscribe$)).subscribe((newMode) => {
      this.currentMode = newMode;
    });
    this.timer = timer(0, 1000)
      .pipe(takeUntil(this.unsubscribe$))
      .pipe(
        map(() =>
          DashboardInformationService.calculateCallDuration(
            this.callStartedAt,
            this.callEndedAt ?? new Date().toString(),
          ),
        ),
      );
  }

  endCall(): void {
    this.callEnded.emit();
  }

  changeMode(mode: Mode): void {
    if (this.currentMode === mode) this.modeChanged.emit(Mode.Idle);
    else this.modeChanged.emit(mode);
  }

  takePicture(): void {
    this.takeScreenshot.emit();
  }

  takeLivePicture(): void {
    this.recordLiveImage.emit();
  }

  clear(): void {
    this.clearCanvas.emit();
  }

  toggleFlash(): void {
    this.callCtrlService.toggleFlash();
  }

  openSettings(): void {
    this.dialog.open(SettingDialogComponent);
  }

  protected async toggleMic(): Promise<void> {
    await this.callCtrlService.toggleMicState();
  }

  protected async toggleVideo(): Promise<void> {
    await this.callCtrlService.toggleCamState();
  }

  protected async recordCall() {
    this.isRecording$.pipe(first()).subscribe((isRecording) => {
      if (isRecording) this.recordingService.stopRecording().subscribe();
      else this.recordingService.startRecording().subscribe();
    });
  }

  private static evaluateDeviceAvailability(
    subject: Subject<DeviceAvailability>,
    permissionStatus: AidarPermissionStatus,
    isAvailable: boolean,
  ): void {
    if (!isAvailable) subject.next(DeviceAvailability.Missing);
    else if (!AidarPermissionStatus.isGiven(permissionStatus))
      subject.next(DeviceAvailability.PermissionDenied);
    else subject.next(DeviceAvailability.Ready);
  }

  async toggleZoom() {
    const currentZoomState = await firstValueFrom(
      this.callCtrlService.subscribeToZoomState(),
    );
    const zoomState =
      currentZoomState === ZoomState.Default
        ? ZoomState.Zoom1
        : ZoomState.Default;
    this.callCtrlService.changeZoom(zoomState);
  }
}

enum DeviceAvailability {
  Ready = 0,
  PermissionDenied = 1,
  Missing = 2,
}
