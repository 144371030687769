export class TabChipData {
  index: number;
  name: string;
  isSelected: boolean;

  constructor(index: number, name: string, isSelected: boolean) {
    this.index = index;
    this.name = name;
    this.isSelected = isSelected;
  }
}
