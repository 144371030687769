<div [formGroup]="textForm" class="note-container">
  <editor
    (onResizeEditor)="onResizeEditor()"
    [apiKey]="apiKey"
    [init]="options"
    formControlName="notes"
  >
  </editor>
  <mat-progress-bar
    *ngIf="(loadingCount$ | async) > 0"
    mode="indeterminate"
  ></mat-progress-bar>
</div>
