<div class="attachment">
  <app-attachment-list-card
    [title]="data.name"
    [extension]="data.extension"
    [createdAt]="data.createdAt"
    [type]="data.type"
    [noteAvailable]="data.note !== null"
    [previewUrl]="data.annotated_thumbnail_path || data.thumbnail_path"
    [clickable]="
      data.type === AttachmentContentType.Image ||
      data.type === AttachmentContentType.LivePhoto
    "
    [menu]="menu"
    (openAttachment)="openAttachmentClicked(data)"
    (editAttachmentNote)="editAttachmentNotesClicked(data.id)"
  >
  </app-attachment-list-card>
  <mat-menu #menu="matMenu">
    <button
      *ngIf="
        data.type === AttachmentContentType.Image ||
        data.type === AttachmentContentType.LivePhoto
      "
      (click)="openAttachmentClicked(data)"
      mat-menu-item
    >
      <mat-icon>visibility</mat-icon>
      <span i18n>Anzeigen</span>
    </button>
    <button (click)="deleteAttachmentClicked(data.id)" mat-menu-item>
      <mat-icon>delete</mat-icon>
      <span i18n>Löschen</span>
    </button>
    <button (click)="editAttachmentNotesClicked(data.id)" mat-menu-item>
      <mat-icon>description</mat-icon>
      <span i18n>Notiz bearbeiten</span>
    </button>
  </mat-menu>
</div>
