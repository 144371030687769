import { Component, signal } from '@angular/core';
import { RealmService } from '../../../authentication/realm.service';
import { internalRealms } from '../../../../environments/constants';
import { MatTooltip } from '@angular/material/tooltip';
import { buildDate, commitHash } from '../../../../environments/version';

@Component({
  selector: 'app-version-info',
  standalone: true,
  imports: [MatTooltip],
  templateUrl: './version-info.component.html',
  styleUrl: './version-info.component.scss',
})
export class VersionInfoComponent {
  frontendVersionInfo$ = signal<string>('');
  frontendBuildDate$ = signal<string>('');
  apiVersionInfo$ = signal<string>('');
  displayVersionInfo$ = signal(false);

  constructor(private readonly realmService: RealmService) {
    const realm = this.realmService.getRealm();
    this.frontendVersionInfo$.set(commitHash.substring(0, 20));
    this.frontendBuildDate$.set(buildDate);
    this.displayVersionInfo$.set(internalRealms.indexOf(realm) >= 0);
  }
}
