import { Injectable } from '@angular/core';
import { CreateNewScheduledInquiryRequest } from '../../model/inquiry/request/create-new-scheduled-inquiry';
import { Inquiry } from '../../model/inquiry/inquiry';
import { InquiryService as InquiryApi } from '../../../api/gen';
import { map, Observable } from 'rxjs';

@Injectable()
export class InquiryService {
  constructor(private readonly inquiryService: InquiryApi) {}

  createNewInquiry(
    request: CreateNewScheduledInquiryRequest,
  ): Observable<Inquiry> {
    return this.inquiryService
      .contactInquiryPost(CreateNewScheduledInquiryRequest.toDto(request))
      .pipe(map((x) => Inquiry.fromDto(x)));
  }

  getInquiry(inquiryId: string): Observable<Inquiry> {
    return this.inquiryService
      .contactInquiryInquiryIdGet(inquiryId)
      .pipe(map((dto) => Inquiry.fromDto(dto)));
  }

  softDeleteInquiry(inquiryId: string): Observable<Inquiry> {
    return this.inquiryService
      .contactInquiryInquiryIdDelete(inquiryId)
      .pipe(map((x) => Inquiry.fromDto(x)));
  }
}
