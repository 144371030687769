@if (isLoading()) {
  <div class="loading-container">
    <mat-spinner></mat-spinner>
    <span class="loading-text" i18n>Lade Anwendung</span>
  </div>
} @else {
  <div class="container">
    <app-notification-bar
      *ngIf="
        notificationBarVisible &&
        (notificationBarService.getNotificationBarVisibility() | async)
      "
    ></app-notification-bar>
    <app-menu-bar *ngIf="menubarVisible"></app-menu-bar>
    <app-side-nav
      class="main-content"
      (activatedComponentChanged)="activatedComponentChanged($event)"
    ></app-side-nav>
  </div>
}
