<div class="row-alignment">
  <div *ngIf="inquiryState === InquiryState.Open" [ngSwitch]="appointmentState">
    <mat-icon
      *ngSwitchCase="AppointmentState.CONFIRMED"
      class="confirmed"
      i18n-matTooltip
      matTooltip="Termin wurde bestätigt"
      >how_to_reg
    </mat-icon>
    <mat-icon
      *ngSwitchCase="AppointmentState.DECLINED"
      class="declined"
      i18n-matTooltip
      matTooltip="Termin wurde abgelehnt"
      >close
    </mat-icon>
    <mat-icon
      *ngSwitchCase="AppointmentState.ANSWER_REQUESTED"
      class="answer-requested material-icons-outlined"
      i18n-matTooltip
      matTooltip="Termin wurde noch nicht bestätigt"
      >pending
    </mat-icon>
    <div *ngSwitchCase="AppointmentState.NOT_SET">
      <mat-icon
        class="not-set"
        i18n-matTooltip
        matTooltip="Anfrage hat noch keinen Termin"
        >schedule</mat-icon
      >
      <span i18n>Jetzt Termin vorschlagen</span>
    </div>
  </div>
  <app-date-range [endDate]="endDate" [startDate]="startDate"></app-date-range>
</div>
