<h2 mat-dialog-title i18n>Termin löschen</h2>
<mat-dialog-content>
  <mat-spinner *ngIf="isLoading$ | async; else dialogContent"></mat-spinner>
  <ng-template #dialogContent>
    <span i18n
      >Soll dieser Termin wirklich gelöscht werden? Dabei werden alle Anhänge
      gelöscht. Die Grundinformationen zum Termin bleiben erhalten, bis diese
      endgültig gelöscht werden.</span
    >
  </ng-template>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button
    mat-button
    mat-dialog-close
    color="primary"
    [disabled]="isLoading$ | async"
    i18n
  >
    Abbrechen
  </button>
  <button
    (click)="onConfirmDeleteDialog()"
    cdkFocusInitial
    mat-flat-button
    color="warn"
    [disabled]="isLoading$ | async"
  >
    <mat-icon class="icon-padding-left">delete</mat-icon>
    <span i18n>Löschen</span>
  </button>
</mat-dialog-actions>
