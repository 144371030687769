<h2 mat-dialog-title>Welchen Termin möchten Sie beitreten?</h2>
<mat-dialog-content i18n
  >Zurzeit sind {{ dataSource.data.length }} Termine offen welche für Sie oder
  keinen anderen Mitarbeiter zugewiesen sind.
</mat-dialog-content>

<div>
  <table [dataSource]="dataSource" mat-table>
    <ng-container matColumnDef="name">
      <th *matHeaderCellDef mat-header-cell i18n>Kunde</th>
      <td *matCellDef="let element" mat-cell>
        {{ element.firstName }} {{ element.lastName }}
      </td>
    </ng-container>

    <ng-container matColumnDef="scheduledFor">
      <th *matHeaderCellDef mat-header- i18n>Termin</th>
      <td *matCellDef="let element" mat-cell>
        <app-date-range
          [endDate]="null"
          [startDate]="element.scheduledFor"
        ></app-date-range>
      </td>
    </ng-container>

    <ng-container matColumnDef="assignedEmployeeDisplayName">
      <th *matHeaderCellDef mat-header-cell i18n>Mitarbeiter</th>
      <td *matCellDef="let element" mat-cell>
        {{ element.employee.firstName }} {{ element.employee.lastName }}
      </td>
    </ng-container>

    <ng-container matColumnDef="joinAppointment">
      <th *matHeaderCellDef mat-header-cell></th>
      <td *matCellDef="let element" mat-cell>
        <button (click)="onJoinAppointmentClicked(element)" mat-button>
          <span i18n>Beitreten</span>
          <mat-icon class="join-open-appointment-button"
            >arrow_forward</mat-icon
          >
        </button>
      </td>
    </ng-container>

    <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>

    <tr *matRowDef="let row; columns: displayedColumns" mat-row></tr>
  </table>
</div>

<mat-dialog-actions>
  <button mat-button mat-dialog-close i18n>Abbrechen</button>
</mat-dialog-actions>
