import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { constants } from '../../../environments/constants';

@Component({
  selector: 'app-heading-with-icon-and-action',
  templateUrl: './heading-with-icon-and-action.component.html',
  styleUrls: ['./heading-with-icon-and-action.component.scss'],
})
export class HeadingWithIconAndActionComponent implements OnInit {
  @Input() icon: string = 'delete';
  @Input() title: string = $localize`Kontaktdaten`;
  @Input() titleShort: string = null;
  @Input() actionTitle: string;
  @Input() actionTitleShort: string = null;
  @Input() actionIcon: string;
  @Input() actionEnabled: boolean = true;
  @Output() actionTriggered = new EventEmitter();

  ngOnInit(): void {
    if (window.innerWidth < constants.dimension.$sm && this.titleShort) {
      this.title = this.titleShort;
    }

    if (window.innerWidth < constants.dimension.$sm && this.actionTitleShort) {
      this.actionTitle = this.actionTitleShort;
    }
  }

  onActionClicked() {
    this.actionTriggered.emit();
  }
}
