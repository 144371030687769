import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DeviceAvailabilityService {
  private devicesAvailable = new ReplaySubject<DevicesAvailable>();
  devicesAvailable$ = this.devicesAvailable.asObservable();
  constructor() {
    if (navigator.mediaDevices?.enumerateDevices) {
      this.checkIfCameraAndMicrophoneAvailable();
      navigator.mediaDevices.ondevicechange = (_: Event) => {
        this.checkIfCameraAndMicrophoneAvailable();
      };
    }
  }

  // The reason for this method is, that we want to check if video and audio are available BEFORE fetching any
  // permission. Why? Because if we use e.g. getUserMedia({ audio: true, video: true }) without a camera being plugged in
  // we get an exception.
  // When using enumerateDevices before asking for permission, we get the kind, but no labels or anything, but the kind
  // is sufficient
  private checkIfCameraAndMicrophoneAvailable(): void {
    if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
      console.log('This browser does not support the API yet');
    } else {
      navigator.mediaDevices
        .enumerateDevices()
        .then((devices: MediaDeviceInfo[]) => {
          const hasCamera = devices.some((x) => x.kind === 'videoinput');
          const hasMicrophone = devices.some((x) => x.kind === 'audioinput');
          this.devicesAvailable.next({
            camera: hasCamera,
            microphone: hasMicrophone,
          });
        });
    }
  }
}

export interface DevicesAvailable {
  camera: boolean;
  microphone: boolean;
}
