import { NotesDocumentationDto } from '../../../api/gen';

export class NotesDocumentation {
  inquiryIdentifier?: string;
  note?: string | null;

  static fromDto(dto: NotesDocumentationDto): NotesDocumentation {
    return {
      inquiryIdentifier: dto?.inquiryIdentifier,
      note: dto?.note,
    } as NotesDocumentation;
  }
}
