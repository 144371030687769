import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Employee } from '../../model/employee/employee';
import { MatDialog } from '@angular/material/dialog';
import { EditDepartmentDialogComponent } from '../dialogs/edit-department-dialog/edit-department-dialog.component';
import { DepartmentOption } from '../../model/employee/department';

@Component({
  selector: 'app-personal-information-department',
  templateUrl: './personal-information-department.component.html',
  styleUrls: ['./personal-information-department.component.scss'],
})
export class PersonalInformationDepartmentComponent implements OnInit {
  @Input('employee') employee: Employee;
  department: DepartmentOption;

  constructor(
    private readonly dialog: MatDialog,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.department = DepartmentOption.getOption(this.employee.department);
  }

  onChangeDepartment() {
    const dialogRef = this.dialog.open(EditDepartmentDialogComponent, {
      data: this.employee,
      minWidth: '25rem',
    });

    dialogRef.afterClosed().subscribe((result: Employee) => {
      if (result) {
        this.employee = result;
        this.cdr.markForCheck();
      }
    });
  }

  protected readonly DepartmentOption = DepartmentOption;
}
