import { Injectable } from '@angular/core';
import { RegistrationValidationService as RegistrationValidationApi } from '../../../api/gen';
import { map, Observable } from 'rxjs';
import { RegistrationValidation } from '../../model/registration-validation';
import { ValidateRegistrationTokenRequest } from '../../model/signup/request/validate-registration-token-request';

@Injectable()
export class RegistrationValidationService {
  constructor(
    private readonly registrationValidationService: RegistrationValidationApi,
  ) {}

  validateToken(
    request: ValidateRegistrationTokenRequest,
  ): Observable<RegistrationValidation> {
    return this.registrationValidationService
      .registrationValidationValidateTokenPost(
        ValidateRegistrationTokenRequest.toDto(request),
      )
      .pipe(map((dto) => RegistrationValidation.fromDto(dto)));
  }
}
