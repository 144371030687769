<h2 mat-dialog-title i18n>Anruf beenden?</h2>
<mat-dialog-content
  ><span class="dialog-body-text" i18n>Soll dieser Anruf beendet werden?</span>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close color="primary" i18n>Abbrechen</button>
  <button
    [mat-dialog-close]="true"
    cdkFocusInitial
    mat-button
    color="warn"
    i18n
  >
    Beenden
  </button>
</mat-dialog-actions>
