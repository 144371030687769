import { Component, Input } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-inquiry-details-chat',
  templateUrl: './inquiry-details-chat.component.html',
  styleUrls: ['./inquiry-details-chat.component.scss'],
})
export class InquiryDetailsChatComponent {
  @Input() refresh: Subject<void>;
  @Input() inquiryId: string;
}
