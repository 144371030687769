import { Injectable } from '@angular/core';
import { SyncAppointmentService } from 'projects/helper-client/src/api/gen';
import { BehaviorSubject, map, mergeMap, Observable, take } from 'rxjs';
import { AppointmentRecordingService } from '../../../api/gen/api/appointmentRecording.service';
import { VideoChatService } from './videochat.service';

@Injectable({
  providedIn: 'root',
})
export class RecordingService {
  public isRecording$ = new BehaviorSubject<boolean>(false);

  constructor(
    private readonly videoChatService: VideoChatService,
    private readonly syncRepo: SyncAppointmentService,
    private readonly api: AppointmentRecordingService,
  ) {}

  public startRecording(): Observable<boolean> {
    return this.videoChatService.activeRoom$.pipe(
      take(1),
      map((x) => x.data.inquiryIdentifier),
      mergeMap((x) => {
        return this.api.appointmentsRoomInquiryRecordStartPost({
          inquiryId: x,
        });
      }),
      map((x) => !!x),
    );
  }

  public stopRecording(): Observable<boolean> {
    return this.videoChatService.activeRoom$.pipe(
      take(1),
      map((x) => x.data.inquiryIdentifier),
      mergeMap((x) => {
        return this.api.appointmentsRoomInquiryRecordStopPost({ inquiryId: x });
      }),
      map((x) => !!x),
    );
  }

  public changeRecordingStatus(isRecording: boolean): void {
    this.isRecording$.next(isRecording);
  }
}
