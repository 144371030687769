import { Component, Input, Renderer2 } from '@angular/core';
import { Attachment } from '../../../model/attachment/attachment';
import { AttachmentContentType } from '../../../model/attachment/attachment-content-type';

@Component({
  selector: 'app-view-attachment-image',
  templateUrl: './view-attachment-image.component.html',
  styleUrls: ['./view-attachment-image.component.scss'],
})
export class ViewAttachmentImageComponent {
  @Input() attachment: Attachment;

  protected currentRotation = 0;

  constructor(private readonly renderer: Renderer2) {}

  onRotateAttachmentLeft() {
    this.currentRotation -= 90;
    if (this.currentRotation === -360) {
      this.currentRotation = 0;
    }
    this.updateRotation();
  }

  onRotateAttachmentRight() {
    this.currentRotation += 90;
    if (this.currentRotation === 360) {
      this.currentRotation = 0;
    }
    this.updateRotation();
  }

  private updateRotation() {
    const image = document.getElementById('attachmentImage');
    this.renderer.setStyle(
      image,
      'transform',
      `rotate(${this.currentRotation}deg)`,
    );

    if (this.currentRotation === 90 || this.currentRotation === 270) {
      this.renderer.setStyle(image, 'width', '60vh');
      this.renderer.setStyle(image, 'height', '80vw');
    } else {
      this.renderer.setStyle(image, 'width', '80vw');
      this.renderer.setStyle(image, 'height', '60vh');
    }
  }

  protected readonly AttachmentContentType = AttachmentContentType;
}
