<div class="attachments-placeholder">
  <mat-icon class="dad-icon">upload-file</mat-icon>
  <div class="title" i18n>
    Dateien hier ablegen oder aus der Zwischenablage einfügen
  </div>
  <div class="description" i18n>
    Dateien können präsentiert werden und stehen auch dem blankmile Call zur
    Verfügung.
  </div>
</div>
