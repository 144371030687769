<h2 mat-dialog-title i18n>Verbindungsproblem</h2>
<mat-dialog-content i18n>
  Tut uns leider - diesem Raum kann aufgrund eines Fehlers nicht mehr beigereten
  werden.<br />
  Bitte erstelle einen neuen.
</mat-dialog-content>
<mat-dialog-actions>
  <button cdkFocusInitial mat-button color="primary" mat-dialog-close i18n>
    Zur Übersicht
  </button>
</mat-dialog-actions>
