import { Injectable } from '@angular/core';
import { filter, map, Observable, Subject } from 'rxjs';
import { SignalingService } from '../signaling.service';
import { PlanCommands } from '../commands/plan-commands';

@Injectable({ providedIn: 'root' })
export class PlanMessagingService {
  private readonly messageStream = new Subject<Message>();

  constructor(private readonly signalingService: SignalingService) {}

  public onMessage(type: PlanMessageType): Observable<any> {
    return this.messageStream.asObservable().pipe(
      filter((x) => x.type === type),
      map((x) => x.data),
    );
  }

  public addPlanListener(): void {
    this.signalingService
      .getHubConnection()
      .on(PlanCommands.availableQuotaChanged, (data) => {
        this.messageStream.next(
          new Message(PlanMessageType.availableQuotaChanged, data),
        );
      });
  }
}

export enum PlanMessageType {
  availableQuotaChanged,
}

class Message {
  constructor(
    public type: PlanMessageType,
    public data: any,
  ) {}
}
