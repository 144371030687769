<div class="container">
  <div
    #attachmentContainer
    (onFileDropped)="uploadFileList($event)"
    [scrollTop]="attachmentContainer.scrollHeight"
    appDropzone
    class="attachment-container"
  >
    <div class="attachment-list">
      @for (attachment of attachments; track trackImageBy) {
        <app-attachment-list-item
          [data]="attachment"
          (openAttachment)="openAttachment($event)"
          (editAttachmentNotes)="editAttachmentNotes($event)"
          (deleteAttachment)="deleteAttachment($event)"
        ></app-attachment-list-item>
      }
    </div>
    @if (attachments?.length === 0) {
      <app-attachment-list-placeholder></app-attachment-list-placeholder>
    }
  </div>
  <app-attachment-list-uploading
    [length]="uploadingAttachments.length"
  ></app-attachment-list-uploading>
  <app-add-attachment-button
    (filesSelected)="uploadFiles($event)"
  ></app-add-attachment-button>
</div>
