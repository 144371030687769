import { Injectable } from '@angular/core';
import { jwtDecode } from 'jwt-decode';
import { BehaviorSubject, filter, map } from 'rxjs';
import { User } from 'oidc-client-ts';

@Injectable({ providedIn: 'root' })
export class RoleService {
  private readonly roles = new BehaviorSubject<string[]>([]);

  public hasRole(role: string) {
    return this.roles.value.indexOf(role) >= 0;
  }

  public isInRole(role: string) {
    return this.roles.asObservable().pipe(
      filter((x) => x.indexOf(role) > 0),
      map((x) => x.length > 0),
    );
  }

  public updateRoles(user: User) {
    this.roles.next(this.parseRolesFromAccessToken(user.access_token));
  }

  private parseRolesFromAccessToken(accessToken: string): string[] {
    const roles: string[] = [];
    const decoded = jwtDecode<JwtPayload>(accessToken);
    roles.push(...decoded.realm_access.roles);
    roles.push(...decoded.resource_access['aidar-backend'].roles);
    return roles;
  }
}

export class JwtPayload {
  realm_access: any;
  resource_access: any;
}
