import { Injectable } from '@angular/core';

import { MatDialog } from '@angular/material/dialog';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { DevicesForbiddenDialogComponent } from '../dialogs/devices-forbidden-dialog/devices-forbidden-dialog.component';
import { first, map, mergeMap } from 'rxjs/operators';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';
import { PermissionService } from './permission.service';
import { StorageService } from './storage.service';

export type Devices = MediaDeviceInfo[];

export enum DeviceType {
  AUDIO_INPUT = 'audioinput',
  AUDIO_OUTPUT = 'audiooutput',
  VIDEO_INPUT = 'videoinput',
}

@Injectable({
  providedIn: 'root',
})
export class DeviceService {
  settingsChanged$ = new Subject<MediaDeviceInfo>();

  public outputSelectionPossible = true;

  private readonly deviceBroadcast = new ReplaySubject<Promise<Devices>>();
  devicesUpdated$: Observable<Promise<Devices>> =
    this.deviceBroadcast.asObservable();

  constructor(
    private readonly permissionService: PermissionService,
    private readonly storageService: StorageService,
    private readonly dialog: MatDialog,
  ) {
    if (!this.areMediaDevicesSupported()) {
      this.dialog.open(DevicesForbiddenDialogComponent, {
        hasBackdrop: true,
        disableClose: true,
      });
    } else {
      navigator.mediaDevices.ondevicechange = (_: Event) => {
        this.deviceBroadcast.next(this.getDeviceOptions());
        this.checkIfSelectedDevicesStillAvailable().then();
      };

      this.permissionService.observeChanges().subscribe((x) => {
        this.deviceBroadcast.next(this.getDeviceOptions());
        this.checkIfSelectedDevicesStillAvailable().then();
      });

      this.deviceBroadcast.next(this.getDeviceOptions());
      this.checkIfSelectedDevicesStillAvailable().then();
    }

    this.outputSelectionPossible = this.checkIfOutputSelectionIsPossible();
  }

  public async checkIfSelectedDevicesStillAvailable() {
    const currentAudioOutput = this.getSelectedAudioOutputDevice();
    const currentVideoInput = this.getSelectedAudioDeviceId();
    const currentAudioInput = this.getSelectedAudioDeviceId();

    const options = await this.getDeviceOptions();

    // TODO: trigger selection changed! => select default device and trigger with according id
    if (options?.findIndex((x) => x.deviceId === currentAudioInput) < 0) {
      this.storageService.remove('audioInputId');
    }
    if (options?.findIndex((x) => x.deviceId === currentVideoInput) < 0) {
      this.storageService.remove('videoInputId');
    }
    if (options?.findIndex((x) => x.deviceId === currentAudioOutput) < 0) {
      this.storageService.remove('audioOutputId');
    }
  }

  public checkIfOutputSelectionIsPossible(
    elementToTest?: SinkedHTMLMediaElement,
  ) {
    if (!elementToTest) {
      elementToTest = document.createElement('audio') as SinkedHTMLMediaElement;
    }
    return typeof elementToTest.setSinkId == 'function';
  }

  public getSelectedAudioOutputDevice(): string | undefined {
    return this.storageService.get('audioOutputId');
  }

  public getSelectedAudioDeviceId(): string | undefined {
    return this.storageService.get('audioInputId');
  }

  public getSelectedVideoDeviceId(): string | undefined {
    return this.storageService.get('videoInputId');
  }

  public getMicState(): MicState {
    const value = this.storageService.get('micState');
    return MicState[value as keyof typeof MicState];
  }

  public setMicState(state: MicState) {
    return this.storageService.set('micState', MicState[state]);
  }

  public getCamState(): CamState {
    const value = this.storageService.get('camState');
    return CamState[value as keyof typeof CamState];
  }

  public setCamState(state: CamState) {
    return this.storageService.set('camState', CamState[state]);
  }

  private areMediaDevicesSupported(): boolean {
    return !!navigator.mediaDevices && !!navigator.mediaDevices.getUserMedia;
  }

  private async getDeviceOptions(): Promise<Devices> {
    if (this.areMediaDevicesSupported()) {
      let devices = await this.readDevices();
      if (devices.every((d) => !d.label)) {
        // in firefox, we dont get the device labels if the stream generated for fetching permissions via getUserMedia
        // does not exist any more when enumerating
        // https://stackoverflow.com/questions/46648645/navigator-mediadevices-enumeratedevices-not-display-device-label-on-firefo
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
          video: true,
        });
        devices = await this.readDevices();
        // Cleanup track again, just to be sure
        stream.getTracks().forEach((track: MediaStreamTrack) => {
          track.stop();
        });
      }
      return devices.filter((d) => !!d.label);
    }

    return null;
  }

  // hacky hack hack hack hack
  public switchToFrontCam(): void {
    this.deviceBroadcast
      .pipe(
        first(),
        mergeMap((x) => fromPromise(x)),
        map((devices) => {
          const videoDevices = devices.filter((x) => x.kind === 'videoinput');
          // using facing allows us to differentiate between android phones and iphones with english system language (Front Camera)
          const frontCams = videoDevices.filter((x) =>
            x.label.toLowerCase().includes('facing front'),
          );
          if (frontCams.length >= 1) {
            // This is basically the android scenario, as labels are english in here
            // yet, some phones dont put the default back cam on first index, but sort them via label like "camera2, 0"
            const sorted = this.sortByLabel(frontCams);
            return sorted[0];
          } else {
            // This is basically the ios scenario, as labels are language dependent here
            // Explanation: https://stackoverflow.com/questions/65485170/getusermedia-detect-front-camera
            return videoDevices[0];
          }
        }),
      )
      .subscribe((x) => {
        this.settingsChanged$.next(x);
      });
  }

  private sortByLabel(devices: MediaDeviceInfo[]): MediaDeviceInfo[] {
    return devices.sort((a, b) => {
      return a.label.localeCompare(b.label);
    });
  }

  private async readDevices() {
    const mediaDevices = await navigator.mediaDevices.enumerateDevices();
    return [
      DeviceType.AUDIO_INPUT,
      DeviceType.AUDIO_OUTPUT,
      DeviceType.VIDEO_INPUT,
    ].reduce((options, kind) => {
      return options.concat(
        mediaDevices.filter((device) => device.kind === kind),
      );
    }, [] as Devices);
  }
}

export enum MicState {
  Muted,
  Unmuted,
}

export enum CamState {
  On,
  Off,
}

export interface SinkedHTMLMediaElement extends HTMLMediaElement {
  setSinkId(id: string): Promise<void>;
}
