import { VideoAppointmentDto } from '../../../api/gen';

export class VideoAppointment {
  inquiryIdentifier?: string;
  scheduledFor?: string;
  roomName?: string | null;
  roomSid?: string | null;
  roomFinishedAt?: string | null;

  static fromDto(dto: VideoAppointmentDto): VideoAppointment {
    return {
      inquiryIdentifier: dto.inquiryIdentifier,
      scheduledFor: dto.scheduledFor,
      roomName: dto.roomName,
      roomSid: dto.roomSid,
      roomFinishedAt: dto.roomFinishedAt,
    } as VideoAppointment;
  }
}
