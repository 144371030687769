import { LineTypeIntelligenceDto } from '../../../../api/gen';

export class LineTypeIntelligence {
  carrierName?: string | null;
  errorCode?: string | null;
  mobileCountryCode?: string | null;
  mobileNetworkCode?: string | null;
  type?: string | null;

  public static fromDto(dto: LineTypeIntelligenceDto): LineTypeIntelligence {
    return {
      carrierName: dto.carrierName,
      errorCode: dto.errorCode,
      mobileCountryCode: dto.mobileCountryCode,
      mobileNetworkCode: dto.mobileNetworkCode,
      type: dto.type,
    } as LineTypeIntelligence;
  }
}

export enum LineTypeIntelligenceType {
  Landline = 'landline',
  Mobile = 'mobile',
  FixedVoip = 'fixedVoip',
  NonFixedVoip = 'nonFixedVoip',
  Personal = 'personal',
  TollFree = 'tollFree',
  Premium = 'premium',
  SharedCost = 'sharedCost',
  Uan = 'uan',
  Voicemail = 'voicemail',
  Pager = 'pager',
  Unknown = 'unknown',
}
