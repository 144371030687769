import { Component } from '@angular/core';

@Component({
  selector: 'app-connection-error-dialog',
  templateUrl: './connection-error-dialog.component.html',
  styleUrls: ['./connection-error-dialog.component.scss'],
})
export class ConnectionErrorDialogComponent {
  constructor() {}
}
