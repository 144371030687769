import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import {
  EmployeeService,
  EmployeeType,
  InquiryDashboardService,
  InquiryState,
} from 'projects/helper-client/src/api/gen';
import { BehaviorSubject, map, Subject, takeUntil } from 'rxjs';
import {
  EmployeeData,
  InquiryStateData,
  InquirySummary,
} from '../models/inquiry';
import { InquiryDashboardSummary } from '../../model/inquiry/inquiry-dashboard-summary';
import { ProfileDataService } from '../../services/data/profile-data.service';
import { AssignInquiryService } from '../../services/api/assign-inquiry/assign-inquiry.service';
import {
  AssignEmployeeToOpenInquiryRequest,
  UnAssignEmployeeFromOpenInquiryRequest,
} from '../../services/api/assign-inquiry/requests';
import { constants } from '../../../environments/constants';
import { InquiryMessagingService } from '../../services/signaling/messaging/inquiry-messaging.service';

@Component({
  selector: 'app-call-history-overview',
  templateUrl: './call-history-overview.component.html',
  styleUrls: ['./call-history-overview.component.scss'],
  providers: [AssignInquiryService],
})
export class CallHistoryOverviewComponent implements OnInit, OnDestroy {
  @ViewChild(MatSort) sort: MatSort;

  protected InquiryState = InquiryState;
  protected isManagerOrAdmin$ = new BehaviorSubject(false);

  protected loading = false;
  protected dataSource: MatTableDataSource<InquirySummary> =
    new MatTableDataSource([]);
  protected states = new FormControl<InquiryState[]>([]);
  protected statesList: InquiryStateData[] = [
    { value: InquiryState.Cancelled, displayName: $localize`Abgebrochen` },
    { value: InquiryState.Expired, displayName: $localize`Abgelaufen` },
    { value: InquiryState.Open, displayName: $localize`Offen` },
    {
      value: InquiryState.SuccessfullyFinished,
      displayName: $localize`Fertig`,
    },
    { value: InquiryState.Deleted, displayName: $localize`Gelöscht` },
  ];
  protected employees = new FormControl<string[]>([]);
  protected employeeList: EmployeeData[] = [];
  protected range = new FormGroup<{
    start: FormControl<Date | null>;
    end: FormControl<Date | null>;
  }>({
    start: new FormControl<Date | null>(null),
    end: new FormControl<Date | null>(null),
  });
  protected displayedColumns: string[] = [
    'state',
    'firstName',
    'scheduledFor',
    'duration',
    'assignedEmployeeDisplayName',
    'attachmentCount',
    'inquiryActive',
  ];
  private readonly unsubscribe$ = new Subject<void>();

  constructor(
    private readonly dashboardService: InquiryDashboardService,
    private readonly employeeService: EmployeeService,
    private readonly assignInquiryService: AssignInquiryService,
    private readonly dialog: MatDialog,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly cdr: ChangeDetectorRef,
    private readonly inquirySignalingService: InquiryMessagingService,
    private readonly profileDataService: ProfileDataService,
  ) {
    if (window.innerWidth < constants.dimension.$sm) {
      this.displayedColumns = ['firstName', 'scheduledFor'];
    } else if (window.innerWidth < constants.dimension.$lg) {
      this.displayedColumns = [
        'state',
        'firstName',
        'scheduledFor',
        'assignedEmployeeDisplayName',
        'attachmentCount',
      ];
    }
  }

  ngOnInit(): void {
    this.refresh();
    this.refreshEmployees();
    this.profileDataService.employeeInformation$.subscribe((employee) => {
      const isManagerOrAdmin =
        employee.type === EmployeeType.Manager ||
        employee.type === EmployeeType.Administrator;
      this.isManagerOrAdmin$.next(isManagerOrAdmin);
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  employeeFilterChanged() {
    this.refresh();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  getAssignableEmployees(element: InquirySummary): EmployeeData[] {
    const assignableEmployees: EmployeeData[] = [];
    for (const employee of this.employeeList) {
      if (employee.value) {
        if (employee.value !== element?.assignedEmployeeIdentifier) {
          assignableEmployees.push(employee);
        }
      }
    }
    return assignableEmployees;
  }

  onEmployeeAssigned(element: InquirySummary, employee: EmployeeData) {
    const request = {
      externalIdentifier: employee.value,
      inquiryIdentifier: element.inquiryIdentifier,
    } as AssignEmployeeToOpenInquiryRequest;
    this.assignInquiryService.assignAgent(request).subscribe((inquiry) => {
      const x = this.dataSource.data.find(
        (i) => i.inquiryIdentifier == inquiry.inquiryIdentifier,
      );
      x.assignedEmployeeDisplayName = employee.displayName;
      x.assignedEmployeeIdentifier = inquiry.assignedAgent;
      this.cdr.markForCheck();
    });
  }

  onEmployeeUnassigned(inquirySummary: InquirySummary) {
    const request = {
      inquiryIdentifier: inquirySummary.inquiryIdentifier,
    } as UnAssignEmployeeFromOpenInquiryRequest;
    this.assignInquiryService.unAssignAgent(request).subscribe((inquiry) => {
      const x = this.dataSource.data.find(
        (i) => i.inquiryIdentifier == inquiry.inquiryIdentifier,
      );
      x.assignedEmployeeDisplayName = $localize`Nicht zugewiesen`;
      x.assignedEmployeeIdentifier = null;
      this.cdr.markForCheck();
    });
  }

  canUpdateAssignedEmployee(element: InquirySummary): boolean {
    return element.state == InquiryState.Open;
  }

  protected refresh() {
    this.refreshInquiries();
  }

  protected onInquirySelected(inquiry: InquirySummary) {
    this.router.navigate(['details'], {
      queryParams: { inquiryId: inquiry.inquiryIdentifier },
      queryParamsHandling: 'merge',
      relativeTo: this.route,
    });
  }

  protected isActiveCall(inquiry: InquirySummary): boolean {
    return (
      inquiry.state === InquiryState.Open &&
      inquiry.scheduledFor &&
      !inquiry.endedAt &&
      new Date(inquiry.scheduledFor) <= new Date()
    );
  }

  private refreshEmployees() {
    this.loading = true;
    this.cdr.markForCheck();

    this.employeeService
      .employeeGet()
      .pipe(
        map((x) => {
          return x.map(
            (y) =>
              ({
                value: y.externalIdentifier,
                displayName: `${y.firstName} ${y.lastName}`,
              }) as EmployeeData,
          );
        }),
      )
      .subscribe({
        next: (values) => {
          this.loading = false;

          this.employeeList = [...values];
          this.employeeList.push({
            value: null,
            displayName: $localize`Nicht zugewiesen`,
          });
          this.cdr.markForCheck();
        },
        error: (error) => {
          console.log('Could not fetch employee');
          console.error(error);
          this.loading = false;
          this.cdr.markForCheck();
        },
      });
  }

  private refreshInquiries() {
    this.loading = true;
    this.cdr.markForCheck();

    const shouldIncludeUnassignedInquiries =
      this.employees.value.indexOf(null) >= 0;
    const employeesForCall = this.employees.value.filter(
      (item) => item !== null,
    );
    this.dashboardService
      .contactInquiryDashboardPost({
        employees: employeesForCall.length > 0 ? employeesForCall : null,
        states: this.states.value,
        from: this.range.value.start?.toDateString(),
        to: this.range.value.end?.toDateString(),
        includeUnassignedInquiries: shouldIncludeUnassignedInquiries,
      })
      .pipe(
        map((x) => {
          return x.map((dto) =>
            InquirySummary.fromInquiryDashboardSummary(
              InquiryDashboardSummary.fromDto(dto),
            ),
          );
        }),
      )
      .subscribe({
        next: (values) => {
          this.loading = false;
          this.dataSource.data = [...values];
          this.dataSource.sort = this.sort;
          this.dataSource.filterPredicate = (
            data: InquiryDashboardSummary,
            filter: string,
          ) => {
            let valueConcated =
              data.firstName +
              ' ' +
              data.lastName +
              ' ' +
              data.phoneNumber +
              ' ' +
              data.email +
              ' ' +
              data.employee.firstName +
              ' ' +
              data.employee.lastName;
            valueConcated = valueConcated.toLowerCase();
            return valueConcated.includes(filter.toLowerCase());
          };
          this.cdr.markForCheck();
        },
        error: (error) => {
          console.error(error);
          this.loading = false;
          this.cdr.markForCheck();
        },
      });

    this.initDataChangeSubscriptions();
  }

  private initDataChangeSubscriptions() {
    //listens for changes in an inquiry and updates it when event is fired
    this.inquirySignalingService.notifyInquiryDataChangedSubject$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((inquirySummary) => {
        //some element was updated => find index of element and update all respective values
        const index: number = this.dataSource.data.findIndex(
          (summary) =>
            summary.inquiryIdentifier === inquirySummary.inquiryIdentifier,
        );
        this.updateInquirySummaryDataAtIndex(index, inquirySummary);
        this.cdr.markForCheck();
      });

    //listens for new inquiries and adds them on first row in data source
    this.inquirySignalingService.notifyNewInquiryAvailableSubject$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((inquirySummary) => {
        //some element was updated => find index of element and update all respective values
        this.insertNewInquiry(inquirySummary);
        this.cdr.markForCheck();
      });

    //listens for appointment updates in inquiries
    this.inquirySignalingService.notifyAppointmentChangedSubject$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((inquirySummary) => {
        //some element was updated => find index of element and update all respective values
        const index: number = this.dataSource.data.findIndex(
          (summary) =>
            summary.inquiryIdentifier === inquirySummary.inquiryIdentifier,
        );
        this.updateInquirySummaryDataAtIndex(index, inquirySummary);
        this.cdr.markForCheck();
      });
  }

  private updateInquirySummaryDataAtIndex(
    index: number,
    inquirySummary: InquirySummary,
  ) {
    this.dataSource.data[index].inquiryIdentifier =
      inquirySummary.inquiryIdentifier;
    this.dataSource.data[index].firstName = inquirySummary.firstName;
    this.dataSource.data[index].lastName = inquirySummary.lastName;
    this.dataSource.data[index].email = inquirySummary.email;
    this.dataSource.data[index].phoneNumber = inquirySummary.phoneNumber;
    this.dataSource.data[index].scheduledFor = inquirySummary.scheduledFor;
    this.dataSource.data[index].appointmentConfirmed =
      inquirySummary.appointmentConfirmed;
    this.dataSource.data[index].appointmentDeclined =
      inquirySummary.appointmentDeclined;
    this.dataSource.data[index].endedAt = inquirySummary.endedAt;
    this.dataSource.data[index].duration = inquirySummary.duration;
    this.dataSource.data[index].assignedEmployeeDisplayName =
      inquirySummary.assignedEmployeeDisplayName;
    this.dataSource.data[index].state = inquirySummary.state;
    this.dataSource.data[index].inquiryCreatedAt =
      inquirySummary.inquiryCreatedAt;
    this.dataSource.data[index].autoDeletionScheduledAt =
      inquirySummary.autoDeletionScheduledAt;
    this.dataSource.data[index].autoDeletedAt = inquirySummary.autoDeletedAt;
    this.dataSource.data[index].manuallyDeletedAt =
      inquirySummary.manuallyDeletedAt;
    this.dataSource.data[index].manuallyDeletedBy =
      inquirySummary.manuallyDeletedBy;
    this.dataSource.data[index].attachmentCount =
      inquirySummary.attachmentCount;
  }

  private insertNewInquiry(newInquiry: InquirySummary) {
    const tableData = this.dataSource.data;
    tableData.splice(0, 0, newInquiry);
    this.dataSource.data = tableData;
  }

  employeeDisplayNameClicked($event: MouseEvent) {
    $event.stopPropagation();
  }
}
