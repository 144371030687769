<button (click)="fileInput.click()" color="primary" mat-raised-button>
  <mat-icon>add</mat-icon>
  <span i18n>Dateien hinzufügen</span>
</button>
<input
  type="file"
  #fileInput
  (change)="onFilesSelected($event)"
  multiple
  hidden
/>
