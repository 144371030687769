/**
 * Agent Client
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type InquiryState = 'Open' | 'SuccessfullyFinished' | 'Cancelled' | 'Expired' | 'Error' | 'Deleted';

export const InquiryState = {
    Open: 'Open' as InquiryState,
    SuccessfullyFinished: 'SuccessfullyFinished' as InquiryState,
    Cancelled: 'Cancelled' as InquiryState,
    Expired: 'Expired' as InquiryState,
    Error: 'Error' as InquiryState,
    Deleted: 'Deleted' as InquiryState
};

