import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { Distance } from '../../../shared/distance-input/distance-input.component';
import { DistanceService } from '../../../services/api/distance.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-inquiry-details-distance',
  templateUrl: './inquiry-details-distance.component.html',
  styleUrls: ['./inquiry-details-distance.component.scss'],
})
export class InquiryDetailsDistanceComponent implements OnChanges {
  @Input() inquiryId: string;
  protected selectedDistance: Distance;
  protected allowSelection = false;
  protected loading = true;

  protected textFinished: string = $localize`Fertig`;
  protected textEdit: string = $localize`Bearbeiten`;

  constructor(
    private readonly distanceRepo: DistanceService,
    private readonly snackBarService: MatSnackBar,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['inquiryId'] && this.inquiryId) {
      this.loading = true;
      this.distanceRepo.getDistance(this.inquiryId).subscribe((x) => {
        this.selectedDistance = x;
        this.loading = false;
        this.cdr.markForCheck();
      });
    }
  }

  public toggleEdit() {
    this.allowSelection = !this.allowSelection;
  }

  selectionChanged($event: Distance) {
    this.selectedDistance = $event;
    this.distanceRepo
      .setNewDistance(this.inquiryId, this.selectedDistance)
      .subscribe({
        error: () => {
          this.snackBarService.open('Speichern fehlgeschlagen');
        },
      });
  }
}
