export type InquiryState =
  | 'Open'
  | 'SuccessfullyFinished'
  | 'Cancelled'
  | 'Expired'
  | 'Error'
  | 'Deleted';

export const InquiryState = {
  Open: 'Open' as InquiryState,
  SuccessfullyFinished: 'SuccessfullyFinished' as InquiryState,
  Cancelled: 'Cancelled' as InquiryState,
  Expired: 'Expired' as InquiryState,
  Error: 'Error' as InquiryState,
  Deleted: 'Deleted' as InquiryState,
};
