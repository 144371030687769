/**
 * Agent Client
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type SmsStatus = 'Sent' | 'Delivered' | 'Clicked' | 'Error';

export const SmsStatus = {
    Sent: 'Sent' as SmsStatus,
    Delivered: 'Delivered' as SmsStatus,
    Clicked: 'Clicked' as SmsStatus,
    Error: 'Error' as SmsStatus
};

