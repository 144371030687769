import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-distance-input',
  templateUrl: './distance-input.component.html',
  styleUrls: ['./distance-input.component.scss'],
})
export class DistanceInputComponent {
  @Input()
  public selection?: Distance;

  @Input()
  public enabled = true;

  @Output()
  public selectionChange = new EventEmitter<Distance>();

  public distances: DistanceOption[] = [
    { value: Distance.OneToFifty, label: '1 - 50 km' },
    { value: Distance.FiftyToHundred, label: '50 - 100 km' },
    { value: Distance.HundredToTwoHundred, label: '100 - 200 km' },
    { value: Distance.MoreThanTwoHundred, label: '200+ km' },
  ];

  protected changeSelection(newSelection?: Distance) {
    if (!this.enabled) return;
    this.selectionChange.emit(
      newSelection === this.selection ? null : newSelection,
    );
  }
}

export interface DistanceOption {
  value: Distance;
  label: string;
}

export enum Distance {
  OneToFifty,
  FiftyToHundred,
  HundredToTwoHundred,
  MoreThanTwoHundred,
}
