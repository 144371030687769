import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { saveAs } from 'file-saver-es';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  ViewAttachmentData,
  ViewAttachmentDialogComponent,
} from '../../dialogs/view-attachment-dialog/view-attachment-dialog.component';
import { CallNotesComponent } from '../../../shared/call-notes/call-notes.component';
import {
  EditAttachmentDialogData,
  EditAttachmentNoteDialogComponent,
} from '../../../shared/dialogs/edit-attachment-note-dialog/edit-attachment-note-dialog.component';
import {
  DeleteAttachmentDialogComponent,
  DeleteAttachmentDialogData,
} from '../../../shared/dialogs/delete-attachment-dialog/delete-attachment-dialog.component';
import { AttachmentService } from '../../../services/api/attachment.service';
import { AssociatedCallPeriod } from '../../../model/attachment/associated-call-period';
import { AttachmentContentType } from '../../../model/attachment/attachment-content-type';
import { AttachmentWithState } from '../attachments/data/attachment-with-state';
import { AttachmentState } from '../attachments/data/attachment-state';

@Component({
  selector: 'app-inquiry-details-call-data',
  templateUrl: './inquiry-details-call-data.component.html',
  styleUrls: [
    './inquiry-details-call-data.component.scss',
    '../inquiry-details-tabs.scss',
  ],
})
export class InquiryDetailsCallDataComponent implements OnInit, OnDestroy {
  @Input() refresh: Subject<void>;
  @Input() inquiryId: string;

  @ViewChild('notes') notes: CallNotesComponent;

  duringAppointmentAttachments: AttachmentWithState[] = [];

  protected editNotes = false;
  private readonly unsubscribe$ = new Subject<void>();

  protected textFinished: string = $localize`Fertig`;
  protected textEdit: string = $localize`Bearbeiten`;

  constructor(
    private readonly attachmentService: AttachmentService,
    private readonly dialog: MatDialog,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.refreshAttachments();
    this.refresh.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.notes.refresh();
      this.refreshAttachments();
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  protected toggleFormCtrl() {
    this.editNotes = !this.editNotes;
  }

  protected async openAttachment(data: AttachmentWithState) {
    const viewAttachmentData = {
      currentAttachment: data.attachment,
      allAttachments: this.duringAppointmentAttachments.map(
        (x) => x.attachment,
      ),
    } as ViewAttachmentData;
    this.dialog.open(ViewAttachmentDialogComponent, {
      data: viewAttachmentData,
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      width: '100%',
      panelClass: 'fullscreen-dialog',
    });
  }

  deleteAttachment(data: AttachmentWithState) {
    const dialogRef = this.dialog.open(DeleteAttachmentDialogComponent, {
      data: {
        attachment: data.attachment,
      } as DeleteAttachmentDialogData,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.refreshAttachments();
      }
    });
  }

  protected downloadFile(file: AttachmentWithState) {
    const fileName = `${file.attachment.fileName}-edited.${file.attachment.extension}`;
    saveAs(file.attachment.annotationBlobUrl, fileName);
  }

  protected downloadFileOriginal(file: AttachmentWithState) {
    const fileName = `${file.attachment.fileName}.${file.attachment.extension}`;
    saveAs(file.attachment.blobUrl, fileName);
  }

  protected editAttachmentNotes(data: AttachmentWithState) {
    const dialogRef = this.dialog.open(EditAttachmentNoteDialogComponent, {
      data: {
        attachmentIdentifier: data.attachment.attachmentIdentifier,
        attachmentName: data.attachment.fileName,
      } as EditAttachmentDialogData,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.refreshAttachments();
      }
    });
  }

  private refreshAttachments() {
    this.attachmentService.getAttachments(this.inquiryId, {}).subscribe({
      next: (attachments) => {
        this.duringAppointmentAttachments = attachments
          .filter((a) => a.period === AssociatedCallPeriod.During)
          .map((x) => new AttachmentWithState(x.name, x, AttachmentState.NONE));
        this.cdr.markForCheck();
      },
    });
  }

  protected readonly AttachmentContentType = AttachmentContentType;
}
