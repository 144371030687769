import { Injectable } from '@angular/core';
import { ArgumentOutOfRangeError, Observable } from 'rxjs';
import { Distance } from '../../shared/distance-input/distance-input.component';
import {
  Distance as ApiDistance,
  DistanceService as Api,
} from '../../../api/gen';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DistanceService {
  constructor(private readonly api: Api) {}
  public setNewDistance(
    inquiryId: string,
    newDistance?: Distance,
  ): Observable<boolean> {
    return this.api
      .inquiryIdDistancePost(inquiryId, {
        newDistance: this.cast(newDistance),
      })
      .pipe(map((x) => true));
  }

  public getDistance(inquiryId: string) {
    return this.api
      .inquiryIdDistanceGet(inquiryId)
      .pipe(map((x) => this.castReverse(x)));
  }

  private castReverse(distance?: ApiDistance): Distance | null {
    if (!distance) return null;
    switch (distance) {
      case ApiDistance.OneToFifty:
        return Distance.OneToFifty;
      case ApiDistance.FiftyToHundred:
        return Distance.FiftyToHundred;
      case ApiDistance.HundredToTwoHundred:
        return Distance.HundredToTwoHundred;
      case ApiDistance.MoreThanTwoHundred:
        return Distance.MoreThanTwoHundred;
      default:
        throw ArgumentOutOfRangeError;
    }
  }

  private cast(distance?: Distance): ApiDistance | null {
    if (distance == null) return null; // otherwise enum problems
    switch (distance) {
      case Distance.OneToFifty:
        return ApiDistance.OneToFifty;
      case Distance.FiftyToHundred:
        return ApiDistance.FiftyToHundred;
      case Distance.HundredToTwoHundred:
        return ApiDistance.HundredToTwoHundred;
      case Distance.MoreThanTwoHundred:
        return ApiDistance.MoreThanTwoHundred;
      default:
        throw ArgumentOutOfRangeError;
    }
  }
}
