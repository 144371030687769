import { Component } from '@angular/core';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'app-attachment-list-placeholder',
  standalone: true,
  imports: [MatIcon],
  templateUrl: './attachment-list-placeholder.component.html',
  styleUrl: './attachment-list-placeholder.component.scss',
})
export class AttachmentListPlaceholderComponent {}
