<div class="container">
  <div class="setup-stepper-container" *ngIf="(isLoading$ | async) === false">
    <mat-stepper linear #stepper>
      <mat-step
        [stepControl]="companySettingsFormGroup"
        [editable]="isEditable"
      >
        <ng-template matStepLabel i18n>Unternehmensdaten</ng-template>
        <div class="stepper-content">
          <form [formGroup]="companySettingsFormGroup">
            <div class="setup-stepper-content-container">
              <div class="setup-stepper-content">
                <h1 i18n>Anzeigenamen des Unternehmens</h1>
                <p i18n>
                  Hier können Sie den Anzeigenamen Ihres Unternehmens festlegen.
                  Dieser wird beim Versenden von Anfragen und in Ihrem
                  Kundenportal zur Personalisierung verwendet.
                </p>
                <mat-form-field>
                  <mat-label i18n>Unternehmensname</mat-label>
                  <input
                    matInput
                    formControlName="companyDisplayName"
                    i18n-placeholder
                    placeholder="Mein Unternehmen"
                    required
                  />
                </mat-form-field>
              </div>
            </div>
            <div class="setup-stepper-content-container">
              <div class="setup-stepper-content">
                <h1 i18n>Firmenlogo</h1>
                <p i18n>
                  Hier können Sie Ihr Firmenlogo auswählen. Dieses kann auch
                  später jederzeit geändert werden.
                </p>
                <div class="setup-stepper-company-logo-input">
                  <button
                    type="button"
                    mat-raised-button
                    color="primary"
                    (click)="fileInput.click()"
                    [disabled]="(canSelectCompanyLogo | async) === false"
                    i18n
                  >
                    Datei auswählen
                  </button>
                  <input
                    class="setup-stepper-company-logo-file"
                    hidden
                    (change)="onCompanyLogoSelected($event)"
                    #fileInput
                    type="file"
                  />
                  <span class="setup-stepper-company-logo-file-name">{{
                    selectedCompanyLogoName | async
                  }}</span>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="stepper-actions">
          <button
            mat-button
            (click)="companySettingsNextClicked(stepper)"
            i18n
            [disabled]="updatingData$ | async"
          >
            Weiter
          </button>
        </div>
      </mat-step>
      <mat-step [stepControl]="dataProtectionFormGroup" [editable]="isEditable">
        <ng-template matStepLabel i18n>Datenschutz</ng-template>
        <div class="stepper-content">
          <div class="setup-stepper-content-container">
            <div class="setup-stepper-content">
              <h1 i18n>Adresse zum Datenschutz</h1>
              <p i18n>
                Hinterlegen Sie hier bitte die URL zu Ihrer
                Datenschutzerklärung. Dies kann auch später in den Einstellungen
                erledigt werden.
              </p>
              <form [formGroup]="dataProtectionFormGroup">
                <mat-form-field>
                  <mat-label i18n>Datenschutz URL</mat-label>
                  <input
                    matInput
                    formControlName="dataProtectionUrl"
                    i18n-placeholder
                    placeholder="https://www.meinunternehmen.at/datenschutz"
                  />
                </mat-form-field>
              </form>
            </div>
          </div>
        </div>
        <div class="stepper-actions">
          <button mat-button matStepperPrevious i18n>Zurück</button>
          <button mat-button (click)="privacyUrlNextClicked(stepper)" i18n>
            Weiter
          </button>
        </div>
      </mat-step>
      <mat-step>
        <ng-template matStepLabel i18n>Fertig</ng-template>
        <div class="stepper-content">
          <div class="setup-stepper-content-container">
            <div class="setup-stepper-content">
              <h1 i18n>Gratulation!</h1>
              <p i18n>
                Sie sind mit der Einrichtung fertig. Legen Sie noch gleich los
                um Ihren ersten Anruf mit blankmile zu machen.
              </p>
            </div>
          </div>
        </div>
        <div class="stepper-actions">
          <button mat-button matStepperPrevious i18n>Zurück</button>
          <button
            mat-raised-button
            color="primary"
            (click)="onSetupCompleted()"
            i18n
          >
            Loslegen
          </button>
        </div>
      </mat-step>
    </mat-stepper>
  </div>

  <div class="loading-container" *ngIf="isLoading$ | async">
    <mat-spinner></mat-spinner>
  </div>
</div>
