<h1 mat-dialog-title i18n>Notiz für {{ data.attachmentName }} bearbeiten</h1>
<div mat-dialog-content>
  <span i18n
    >Notizen für Anhänge sind nur für Sie sichtbar und werden nicht mit Ihrem
    Gegenüber geteilt.</span
  >
  <div [formGroup]="textForm" class="note-container">
    <editor
      [apiKey]="apiKey"
      [init]="options"
      (resize)="onResize()"
      formControlName="notes"
    >
    </editor>
    <mat-progress-bar
      *ngIf="(loadingCount$ | async) > 0"
      mode="indeterminate"
    ></mat-progress-bar>
  </div>
</div>
<div mat-dialog-actions class="dialog-actions">
  <button
    (click)="onUpdateAttachmentNote()"
    cdkFocusInitial
    color="primary"
    mat-button
    i18n
  >
    Ok
  </button>
</div>
