<mat-card>
  <mat-card-content>
    <div class="first-line">
      <div class="status">
        <mat-icon>check_circle</mat-icon>
      </div>
      <div class="avatar">
        <mat-icon>person</mat-icon>
      </div>
      <div class="username ns">{{ userName }}</div>
      <div
        class="device-troubles"
        *ngIf="!allPermissionsGiven() || !devicesAvailable()"
      >
        <app-device-trouble
          *ngIf="!isPermissionGiven(cameraPermission) || !camerasAvailable"
          i18n-title
          title="Kamera"
        ></app-device-trouble>
        <app-device-trouble
          *ngIf="
            !isPermissionGiven(microphonePermission) || !microphonesAvailable
          "
          i18n-title
          title="Mikrofon"
        ></app-device-trouble>
      </div>
      <div class="status-icons">
        <mat-icon *ngIf="cameraDisabled" class="device-disabled"
          >videocam_off
        </mat-icon>
        <mat-icon
          *ngIf="!micDisabled"
          [ngClass]="value >= 2 ? 'speaking' : 'silent'"
          >mic
        </mat-icon>
        <mat-icon *ngIf="micDisabled" class="device-disabled">mic_off</mat-icon>
      </div>
    </div>
    <div class="info-line" *ngIf="!allPermissionsGiven()">
      <span class="ns" i18n>Nicht alle Berechtigungen erteilt!</span>
    </div>
    <div
      class="info-line"
      *ngIf="
        allPermissionsGiven() && (!microphonesAvailable || !camerasAvailable)
      "
    >
      <span>{{ getMissingDeviceText() }}</span>
    </div>
  </mat-card-content>
</mat-card>
