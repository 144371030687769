import { Component, signal } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatButton } from '@angular/material/button';
import { CompanyLoginService } from '../../services/api/company-login.service';
import { CompanyLoginInfo } from '../../model/company-login/company-login-info';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { ValidateEmailService } from '../../services/api/validate-email.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-validate-email',
  standalone: true,
  imports: [MatButton, MatProgressSpinner],
  templateUrl: './validate-email.component.html',
  styleUrl: './validate-email.component.scss',
  providers: [CompanyLoginService, ValidateEmailService],
})
export class ValidateEmailComponent {
  private readonly externalEmployeeIdentifier$ = signal<string>('');
  private readonly token$ = signal<string>('');
  protected readonly email$ = signal<string>('');
  protected readonly isValid$ = signal(false);
  protected readonly isLoading$ = signal(true);
  protected readonly companyLoginInfo$ = signal<CompanyLoginInfo>(null);

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
    private readonly snackBar: MatSnackBar,
    private readonly companyLoginService: CompanyLoginService,
    private readonly validateEmailService: ValidateEmailService,
  ) {
    this.activatedRoute.queryParams.subscribe((params) => {
      if (params['token'] && params['email'] && params['eid']) {
        this.isValid$.set(true);
        this.externalEmployeeIdentifier$.set(params['eid']);
        this.token$.set(params['token']);
        this.email$.set(params['email']);
      }
    });

    this.companyLoginService.getCompanyLoginInformation().subscribe((info) => {
      this.companyLoginInfo$.set(info);
      this.isLoading$.set(false);
    });
  }

  onValidateClick() {
    this.isLoading$.set(true);
    this.validateEmailService
      .validateEmail(
        this.email$(),
        this.externalEmployeeIdentifier$(),
        this.token$(),
      )
      .subscribe({
        next: () => {
          this.router.navigate(['']);
        },
        error: () => {
          this.isLoading$.set(false);
          this.snackBar.open(
            $localize`E-Mail konnte nicht validiert werden. Versuchen Sie es später erneut oder kontaktieren Sie Ihren Administrator.`,
            $localize`Ok`,
            { duration: 5000 },
          );
        },
      });
  }
}
