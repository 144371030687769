import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { InquiryState } from '../../../api/gen/model/inquiryState';

@Component({
  selector: 'app-status-tag',
  templateUrl: './status-tag.component.html',
  styleUrls: ['./status-tag.component.scss'],
})
export class StatusTagComponent implements OnChanges {
  @Input() status: InquiryState;
  @Input() autoDeletionDate: string;

  InquiryState = InquiryState;

  protected daysLeft: number;
  protected daysLeftText: string = null;
  protected daysLeftTextDefault: string = $localize`Fertig`;

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.autoDeletionDate) {
      this.daysLeft = this.calculateDaysLeftFromDate(this.autoDeletionDate);
      if (this.daysLeft == 1) {
        this.daysLeftText = $localize`${this.daysLeft} Tag`;
      } else {
        this.daysLeftText = $localize`${this.daysLeft} Tage`;
      }
    } else {
      this.daysLeft = null;
      this.daysLeftText = null;
    }
  }

  private calculateDaysLeftFromDate(dateString: string): number {
    const millisecondsPerDay = 24 * 60 * 60 * 1000;

    const date = new Date(dateString);
    const now = new Date();

    return Math.round((date.getTime() - now.getTime()) / millisecondsPerDay);
  }
}
