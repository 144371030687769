import {
  Component,
  ElementRef,
  EventEmitter,
  Output,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'app-add-attachment-button',
  templateUrl: './add-attachment-button.component.html',
  styleUrls: ['./add-attachment-button.component.scss'],
})
export class AddAttachmentButtonComponent {
  @ViewChild('fileInput') fileInput!: ElementRef;
  @Output() filesSelected = new EventEmitter<File[]>();

  onFilesSelected(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const selectedFiles = Array.from(input.files);
      // Reset the file input to allow selecting the same files again
      this.fileInput.nativeElement.value = '';
      this.filesSelected.emit(selectedFiles);
    }
  }
}
