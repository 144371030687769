<h2 mat-dialog-title i18n>⚠️ Fehlende Berechtigung</h2>
<mat-dialog-content i18n>
  Dein Gerät scheint die Verwendung von Video und Audio innerhalb deines
  Browsers nicht zu unterstützen oder erlauben.<br />
  Bitte überprüfe deine Einstellungen und lade die Seite neu.
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button (click)="reload()" cdkFocusInitial color="primary" mat-button i18n>
    Neu laden
  </button>
</mat-dialog-actions>
