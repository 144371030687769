import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { SignalingService } from '../signaling.service';
import { InquiryCommands } from '../commands/inquiry-commands';
import { InquirySummary } from '../../../scheduling/models/inquiry';
import { DashboardInformationService } from '../../../scheduling/services/dashboard-information.service';

@Injectable({ providedIn: 'root' })
export class InquiryMessagingService {
  notifyInquiryDataChangedSubject$ = new Subject<InquirySummary>();
  notifyNewInquiryAvailableSubject$ = new Subject<InquirySummary>();
  notifyAppointmentChangedSubject$ = new Subject<InquirySummary>();

  constructor(private readonly signalingService: SignalingService) {}

  public addInquiryListener(): void {
    this.signalingService
      .getHubConnection()
      .on(InquiryCommands.NOTIFY_INQUIRY_CHANGED, (data) => {
        this.notifyInquiryDataChangedSubject$.next(
          this.createInquirySummaryFromRemoteData(data),
        );
      });

    this.signalingService
      .getHubConnection()
      .on(InquiryCommands.NOTIFY_NEW_INQUIRY_AVAILABLE, (data) => {
        this.notifyNewInquiryAvailableSubject$.next(
          this.createInquirySummaryFromRemoteData(data),
        );
      });

    this.signalingService
      .getHubConnection()
      .on(InquiryCommands.NOTIFY_APPOINTMENT_CHANGED, (data) => {
        this.notifyAppointmentChangedSubject$.next(
          this.createInquirySummaryFromRemoteData(data),
        );
      });
  }

  private createInquirySummaryFromRemoteData(data: any) {
    return {
      inquiryIdentifier: data.inquiryIdentifier,
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      phoneNumber: data.phoneNumber,
      inquiryCreatedAt: data.inquiryCreatedAt,
      scheduledFor: data.appointmentScheduledFor,
      appointmentConfirmed: data.scheduledForConfirmed,
      appointmentDeclined: data.scheduledForDeclined,
      endedAt: data.appointmentEndedAt,
      assignedEmployeeDisplayName:
        DashboardInformationService.getEmployeeDisplayName(data.employee),
      state: data.state,
      duration: DashboardInformationService.calculateCallDuration(
        data.appointmentScheduledFor,
        data.appointmentEndedAt,
      ),
      attachmentCount: data.attachments?.length,
    } as InquirySummary;
  }
}
