<div class="attachments-container">
  <table
    [dataSource]="attachmentList"
    class="mat-elevation-z2 attachments-table"
    mat-table
    matSort
  >
    <ng-container matColumnDef="fileIcon">
      <th *matHeaderCellDef mat-header-cell mat-sort-header i18n>Datei</th>
      <td
        *matCellDef="let attachment"
        mat-cell
        (click)="onRowClicked(attachment)"
        matRipple
        [matRippleRadius]="50"
      >
        <app-attachment
          [attachment]="attachment"
          matBadge="✎"
          matBadgeSize="medium"
          matBadgePosition="above before"
          matBadgeHidden="{{ attachment?.note === null }}"
        ></app-attachment>
      </td>
    </ng-container>

    <ng-container matColumnDef="fileName">
      <th *matHeaderCellDef mat-header-cell i18n>Dateiname</th>
      <td
        *matCellDef="let attachment"
        mat-cell
        (click)="onRowClicked(attachment)"
        matRipple
        [matRippleRadius]="50"
      >
        <span>{{ attachment.name }}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="download">
      <th
        *matHeaderCellDef
        mat-header-cell
        class="attachments-preview-download-cell"
      >
        <ng-container *ngIf="menu" i18n>Aktionen</ng-container>
        <ng-container *ngIf="!menu" i18n>Download</ng-container>
      </th>
      <td
        *matCellDef="let attachment"
        class="attachments-preview-download-cell"
        mat-cell
      >
        <button
          *ngIf="!menu"
          (click)="onDownloadAttachment(attachment.blobUrl)"
          i18n-aria-label
          aria-label="Download attachment"
          color="primary"
          mat-icon-button
        >
          <mat-icon>download</mat-icon>
        </button>
        <button
          mat-icon-button
          *ngIf="menu"
          [matMenuTriggerFor]="menu"
          [matMenuTriggerData]="{ data: attachment }"
        >
          <mat-icon>more_vert</mat-icon>
        </button>
      </td>
      <td *matCellDef="let attachment" mat-cell>{{ attachment.name }}</td>
    </ng-container>

    <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>

    <tr
      class="attachments-table-row"
      *matRowDef="let row; columns: displayedColumns"
      mat-row
    ></tr>

    <tr *matNoDataRow class="mat-row">
      <td [attr.colspan]="displayedColumns.length" class="mat-cell no-data-row">
        {{ emptyText ?? defaultEmptyText }}
      </td>
    </tr>
  </table>
</div>
