import { InvitationDto } from '../../../api/gen';

export class Invitation {
  language?: InvitationLanguage;
  type?: InvitationType;
  method?: InvitationMethod;
  text?: string | null;
  lastTimeSent?: string | null;
  shareableLink?: string | null;

  static fromDto(dto: InvitationDto): Invitation {
    return {
      language: dto.language,
      type: dto.type,
      method: dto.method,
      text: dto.text,
      lastTimeSent: dto.lastTimeSent,
      shareableLink: dto.shareableLink,
    } as Invitation;
  }
}

export type InvitationLanguage = 'German' | 'English' | 'Unknown';

export const InvitationLanguage = {
  German: 'German' as InvitationLanguage,
  English: 'English' as InvitationLanguage,
  Unknown: 'Unknown' as InvitationLanguage,
};

export type InvitationType = 'Default' | 'Custom';

export const InvitationType = {
  Default: 'Default' as InvitationType,
  Custom: 'Custom' as InvitationType,
};

export type InvitationMethod = 'Sms' | 'Email' | 'SmsAndEmail' | 'None';

export const InvitationMethod = {
  Sms: 'Sms' as InvitationMethod,
  Email: 'Email' as InvitationMethod,
  SmsAndEmail: 'SmsAndEmail' as InvitationMethod,
  None: 'None' as InvitationMethod,
};
