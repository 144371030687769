export function getPlatform(): Platform {
  const userAgent = navigator.userAgent;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return Platform.WindowsPhone;
  }

  if (/android/i.test(userAgent)) {
    return Platform.Android;
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent)) {
    return Platform.iOS;
  }

  return Platform.unknown;
}

export enum Platform {
  WindowsPhone,
  Android,
  iOS,
  unknown,
}
