import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AttachmentContentType } from '../../../model/attachment/attachment-content-type';
import { MatIcon } from '@angular/material/icon';
import { MatMenu, MatMenuItem } from '@angular/material/menu';
import { DatePipe, NgIf } from '@angular/common';
import { SharedModule } from '../../shared.module';
import { CallAttachment } from '../../../video-appointment/services/appointment-attachments.service';

@Component({
  selector: 'app-attachment-list-item',
  standalone: true,
  imports: [MatIcon, MatMenu, MatMenuItem, NgIf, SharedModule, DatePipe],
  templateUrl: './attachment-list-item.component.html',
  styleUrl: './attachment-list-item.component.scss',
})
export class AttachmentListItemComponent {
  @Input() data: CallAttachment;
  @Output() openAttachment = new EventEmitter<CallAttachment>();
  @Output() editAttachmentNotes = new EventEmitter<string>();
  @Output() deleteAttachment = new EventEmitter<string>();

  protected readonly AttachmentContentType = AttachmentContentType;

  openAttachmentClicked(data: CallAttachment) {
    if (this.canOpenAttachment(data)) {
      this.openAttachment.emit(data);
    }
  }

  editAttachmentNotesClicked(id: string) {
    this.editAttachmentNotes.emit(id);
  }

  deleteAttachmentClicked(id: string) {
    this.deleteAttachment.emit(id);
  }

  private canOpenAttachment(attachment: CallAttachment) {
    return (
      attachment.type === AttachmentContentType.Image ||
      attachment.type === AttachmentContentType.LivePhoto
    );
  }
}
