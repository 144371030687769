import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../authentication.service';
import { InitializationStatusService } from '../../../api/gen';
import { map } from 'rxjs/operators';
import { InitializationStatus } from '../../model/initialization-status';
import { RoleService } from '../role.service';
import { BackendRole } from '../backend-role';
import { firstValueFrom } from 'rxjs';
import { BrandingDataService } from '../../../../../aidar-branding/src/lib/branding-data-service';
import { SignalingConnectionService } from '../../services/signaling/signaling-connection.service';

@Component({
  selector: 'app-auth-callback',
  templateUrl: './auth-callback.component.html',
  styleUrls: ['./auth-callback.component.scss'],
})
export class AuthCallbackComponent implements OnInit {
  constructor(
    private readonly router: Router,
    private readonly authService: AuthenticationService,
    private readonly roleService: RoleService,
    private readonly initializationStatusService: InitializationStatusService,
    private readonly signalingConnectionService: SignalingConnectionService,
    private readonly brandingService: BrandingDataService,
  ) {}

  async ngOnInit() {
    try {
      await this.authService.authCallback();
    } catch (error) {
      this.router.navigate(['/login']);
    }

    if (this.roleService.hasRole(BackendRole.Admin)) {
      await this.checkInitializationStatus();
    } else {
      await this.navigateToHome();
    }
  }

  private async checkInitializationStatus() {
    this.initializationStatusService
      .initializationGet()
      .pipe(map((dto) => InitializationStatus.fromDto(dto)))
      .subscribe(async (initializationStatus) => {
        if (
          initializationStatus.companySettingsInitialized === false ||
          initializationStatus.inquiryReportTemplateInitialized === false
        ) {
          await this.router.navigate(['setup']);
        } else {
          await this.navigateToHome();
        }
      });
  }

  private async navigateToHome() {
    this.authService.getUser().then((user) => {
      firstValueFrom(this.brandingService.applyBranding());
      this.signalingConnectionService.connect(user.profile.sub);
      this.router.navigate(['scheduling']);
    });
  }
}
