import {
  AttachmentDto,
  InquiryDashboardSummaryDto,
  InquiryState,
} from '../../../api/gen';
import { Employee } from '../employee/employee';

export class InquiryDashboardSummary {
  inquiryIdentifier?: string;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  phoneNumber?: string | null;
  street?: string | null;
  houseNumber?: string | null;
  zipCode?: string | null;
  city?: string | null;
  startInstant?: boolean;
  notes?: string | null;
  state?: InquiryState;
  employee?: Employee;
  inquiryCreatedAt?: string;
  appointmentScheduledFor?: string | null;
  appointmentEndedAt?: string | null;
  scheduledForConfirmed?: boolean;
  scheduledForDeclined?: boolean;
  autoDeletionScheduledAt?: string | null;
  autoDeletedAt?: string | null;
  manuallyDeletedAt?: string | null;
  manuallyDeletedBy?: string | null;
  attachments?: Array<AttachmentDto> | null;

  static fromDto(dto: InquiryDashboardSummaryDto): InquiryDashboardSummary {
    return {
      inquiryIdentifier: dto.inquiryIdentifier,
      firstName: dto.firstName,
      lastName: dto.lastName,
      email: dto.email,
      phoneNumber: dto.phoneNumber,
      street: dto.street,
      houseNumber: dto.houseNumber,
      zipCode: dto.zipCode,
      city: dto.city,
      startInstant: dto.startInstant,
      notes: dto.notes,
      state: dto.state,
      employee: Employee.fromDto(dto.employee),
      inquiryCreatedAt: dto.inquiryCreatedAt,
      appointmentScheduledFor: dto.appointmentScheduledFor,
      appointmentEndedAt: dto.appointmentEndedAt,
      scheduledForConfirmed: dto.scheduledForConfirmed,
      scheduledForDeclined: dto.scheduledForDeclined,
      autoDeletionScheduledAt: dto.autoDeletionScheduledAt,
      autoDeletedAt: dto.autoDeletedAt,
      manuallyDeletedAt: dto.manuallyDeletedAt,
      manuallyDeletedBy: dto.manuallyDeletedBy,
      attachments: dto.attachments,
    } as InquiryDashboardSummary;
  }
}
