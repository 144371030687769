<app-heading-with-icon-and-action
  i18n-title
  title="Chatverlauf"
  icon="forum"
></app-heading-with-icon-and-action>

<div class="section-container">
  <app-info-text-with-icon
    i18n-text
    text="Chatverlauf zeigt die Originalnachrichten und kann nicht mehr bearbeitet werden."
  ></app-info-text-with-icon>
  <div class="mat-elevation-z2 chat-container">
    <app-live-chat
      [noMessagesTemplate]="noMessagesTemplate"
      [inquiryId]="inquiryId"
      [displayInput]="false"
    ></app-live-chat>
  </div>
</div>

<ng-template #noMessagesTemplate>
  <div class="no-messages no-data-row">
    <span i18n>Keine Chat-Nachrichten ausgetauscht.</span>
  </div>
</ng-template>
