import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import {
  ConnectionState,
  Diagnostics,
  DiagnosticsService,
} from '../services/diagnostics/diagnostics.service';

@Component({
  selector: 'app-diagonstics',
  templateUrl: './diagnostics.component.html',
  styleUrls: ['./diagnostics.component.scss'],
})
export class DiagnosticsComponent {
  protected diagnosticsObserver: Observable<Diagnostics>;
  protected ConnectionState = ConnectionState;

  constructor(readonly diagnosticsService: DiagnosticsService) {
    this.diagnosticsObserver = diagnosticsService.diagnosticsChanged$;
  }

  connectionSuccessful(diagnostics: Diagnostics): boolean {
    return (
      !diagnostics.remoteConnectionLost &&
      diagnostics.remoteParticipantConnected
    );
  }
}
