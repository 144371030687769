import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Output,
} from '@angular/core';

@Directive({
  selector: '[isOutside]',
})
export class IsOutsideDirective {
  @Output()
  public isOutside = new EventEmitter();

  constructor(private readonly elementRef: ElementRef) {}

  @HostListener('document:click', ['$event.target'])
  public onClick(targetElement: any) {
    if (this.elementRef) {
      const clickedInside =
        this.elementRef.nativeElement.contains(targetElement) ||
        targetElement.classList.contains('close-when-outside');
      if (!clickedInside) {
        this.isOutside.emit(true);
      }
    }
  }
}
