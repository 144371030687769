<h1 mat-dialog-title i18n>Link konnte nicht versendet werden</h1>
<div mat-dialog-content i18n>
  Der Anruf konnte nicht gemacht werden, da du bereits alle deine Credits
  verbraucht hast.
  <br />
  Um weiter zu telefonieren buche eines unserer Pakete.
</div>
<div mat-dialog-actions>
  <button mat-button mat-dialog-close i18n>Schließen</button>
</div>
