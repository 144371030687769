<div #list id="participant-list">
  <ng-container
    *ngIf="
      isAlone && (isInRoom$ | async);
      then waitingPlaceholder;
      else noVideoWrapper
    "
  ></ng-container>
  <div
    [hidden]="isAlone || !videoTrackEnabled"
    class="videoWrapper"
    #videoWrapper
  ></div>
</div>

<ng-template #waitingPlaceholder>
  <mat-card *ngIf="!participateOnceJoined && !roomFinishedAt">
    <mat-card-content>
      @if (invitationAlreadySent()) {
        <app-invitation-status></app-invitation-status>
      } @else {
        <app-invitation></app-invitation>
      }
    </mat-card-content>
  </mat-card>
</ng-template>

<ng-template #noVideoWrapper>
  <div class="no-video" *ngIf="!videoTrackEnabled">
    <mat-card>
      <mat-card-content>
        <div class="no-video-icon-wrapper">
          <mat-icon>videocam_off</mat-icon>
        </div>
        <h3 i18n>Dein/e Gesprächspartner/in hat die Kamera ausgeschaltet.</h3>
      </mat-card-content>
    </mat-card>
  </div>
</ng-template>
