<mat-card class="authentication-container">
  <app-card-header-with-action
    i18n-title
    title="Authentifizierung"
    (actionClicked)="onResetPassword()"
    i18n-actionText
    actionText="Passwort ändern"
  ></app-card-header-with-action>

  <div *ngIf="employee">
    <mat-list>
      <mat-list-item>
        <mat-icon matListItemIcon>lock</mat-icon>
        <div matListItemTitle class="ns">••••••••</div>
      </mat-list-item>
    </mat-list>
  </div>
</mat-card>
