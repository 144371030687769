import { Component, Input } from '@angular/core';
import { AttachmentWithState } from '../data/attachment-with-state';
import { AttachmentState } from '../data/attachment-state';
import { AttachmentContentType } from '../../../../model/attachment/attachment-content-type';

@Component({
  selector: 'app-attachment',
  templateUrl: './attachment.component.html',
  styleUrls: ['./attachment.component.scss'],
})
export class AttachmentComponent {
  @Input() attachment: AttachmentWithState;
  protected readonly AttachmentState = AttachmentState;
  protected readonly AttachmentContentType = AttachmentContentType;
}
