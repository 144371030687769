import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-info-text-with-icon',
  templateUrl: './info-text-with-icon.component.html',
  styleUrls: ['./info-text-with-icon.component.scss'],
})
export class InfoTextWithIconComponent {
  @Input() icon: string = 'info';
  @Input() text: string;
}
