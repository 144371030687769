import { Component, Input } from '@angular/core';
import { MatProgressSpinner } from '@angular/material/progress-spinner';

@Component({
  selector: 'app-attachment-list-uploading',
  standalone: true,
  imports: [MatProgressSpinner],
  templateUrl: './attachment-list-uploading.component.html',
  styleUrl: './attachment-list-uploading.component.scss',
})
export class AttachmentListUploadingComponent {
  @Input() length: number;
}
