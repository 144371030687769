<h2 mat-dialog-title i18n>Kontaktdaten bearbeiten</h2>
<mat-dialog-content>
  <form [formGroup]="employeeContactForm" class="form-container">
    <div class="name-container">
      <mat-form-field>
        <mat-label i18n>Vorname</mat-label>
        <input
          formControlName="firstName"
          id="firstName"
          matInput
          placeholder="Vorname"
          i18n-placeholder
          type="text"
          value="{{ employee.firstName }}"
        />
      </mat-form-field>

      <mat-form-field>
        <mat-label i18n>Nachname</mat-label>
        <input
          formControlName="lastName"
          id="lastName"
          matInput
          placeholder="Nachname"
          i18n-placeholder
          type="text"
          value="{{ employee.lastName }}"
        />
      </mat-form-field>
    </div>

    <mat-form-field>
      <mat-label i18n>E-Mail</mat-label>
      <input
        formControlName="email"
        id="email"
        matInput
        placeholder="E-Mail"
        i18n-placeholder
        type="email"
        value="{{ employee.lastName }}"
        readonly
      />
    </mat-form-field>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close color="warn">
    <mat-icon>close</mat-icon>
    <span i18n>Abbrechen</span>
  </button>
  <button
    color="primary"
    mat-raised-button
    (click)="onClickSaveEmployee()"
    [disabled]="loading$ | async"
  >
    <mat-icon>save</mat-icon>
    <span i18n>Speichern</span>
  </button>
</mat-dialog-actions>
