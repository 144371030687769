<div>
  <app-heading-with-icon-and-action i18n-title title="Termin gelöscht">
  </app-heading-with-icon-and-action>

  <span *ngIf="inquiry?.manuallyDeletedAt" i18n
    >Die Anfrage wurde am {{ deletedDateText | async }} um
    {{ deletedTimeText | async }} von <b>{{ deletedByText | async }}</b> manuell
    gelöscht.</span
  >

  <span *ngIf="inquiry?.autoDeletedAt" i18n>
    Die Anfrage wurde am {{ deletedDateText | async }} um
    {{ deletedTimeText | async }} automatisch gelöscht.
  </span>
</div>
