import { Injectable } from '@angular/core';
import { InquiryEditService as Api } from '../../../api/gen';
import { EditInquiryBaseDataRequest } from '../../model/inquiry/request/edit-inquiry-base-data';
import { map, Observable } from 'rxjs';
import { Inquiry } from '../../model/inquiry/inquiry';

@Injectable()
export class InquiryEditService {
  constructor(private readonly inquiryEditService: Api) {}

  public editInquiryBaseData(
    inquiryId: string,
    data: EditInquiryBaseDataRequest,
  ): Observable<Inquiry> {
    return this.inquiryEditService
      .contactInquiryInquiryIdEditPut(
        inquiryId,
        EditInquiryBaseDataRequest.toBody(data),
      )
      .pipe(map((dto) => Inquiry.fromDto(dto)));
  }
}
