import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  constructor(private readonly router: Router) {}

  public navigateToVideoAppointment(
    inquiryId: string,
    invitationAlreadySent = true,
  ): void {
    this.router.navigate(['/video-appointment'], {
      queryParams: {
        inquiryId: inquiryId,
        ias: invitationAlreadySent,
      },
      queryParamsHandling: 'merge',
    });
  }
}
