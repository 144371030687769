<div
  *ngIf="notificationBarService.getNotification() | async as notification"
  class="container"
  [ngStyle]="{ 'background-color': notificationColor$ | async }"
>
  <div class="spacer"></div>
  <span class="text">{{ notification.text }}</span>
  <div class="spacer"></div>
  <button
    *ngIf="notification.closeable"
    mat-icon-button
    class="close-button"
    aria-label="Close the notification"
    (click)="onClose()"
  >
    <mat-icon style="color: white">close</mat-icon>
  </button>
</div>
