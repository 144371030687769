<h2 mat-dialog-title i18n>Department ändern</h2>
<mat-dialog-content>
  <form [formGroup]="departmentForm" class="form-container">
    <mat-form-field class="department-form">
      <mat-label i18n>Department</mat-label>
      <mat-select formControlName="department">
        <mat-option
          *ngFor="let department of departments"
          [value]="department.department"
        >
          {{ department.displayName }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close color="warn">
    <mat-icon>close</mat-icon>
    <span i18n>Abbrechen</span>
  </button>
  <button color="primary" mat-raised-button (click)="onClickSaveDepartment()">
    <mat-icon>save</mat-icon>
    <span i18n>Speichern</span>
  </button>
</mat-dialog-actions>
