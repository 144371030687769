<div class="rename-report-container">
  <h1 mat-dialog-title i18n>Report umbenennen</h1>
  <div mat-dialog-content class="rename-report-content-container">
    <div *ngIf="(isLoading | async) === false; else loadingSpinner">
      <mat-form-field class="name-input-form-field">
        <mat-label i18n>Name</mat-label>
        <input matInput [formControl]="reportName" required />
        <mat-error *ngIf="reportName.invalid">{{
          getErrorMessage()
        }}</mat-error>
      </mat-form-field>
    </div>
    <ng-template #loadingSpinner>
      <div class="loading-spinner">
        <mat-spinner></mat-spinner>
      </div>
    </ng-template>
  </div>
  <div mat-dialog-actions class="dialog-actions-container">
    <button mat-button mat-dialog-close color="warn" i18n>Abbrechen</button>
    <button mat-button color="primary" (click)="saveReportName()" i18n>
      Speichern
    </button>
  </div>
</div>
