<div class="container">
  <ng-lottie
    width="10rem"
    height="10rem"
    [options]="shareableLinkAnimationConfig"
  ></ng-lottie>
  <h2 class="copy-inquiry-link-heading" i18n>blankmile™ Link zum Teilen</h2>
  <app-copy-inquiry-link
    [inquiry$]="inquiry$"
    class="copy-inquiry-link"
  ></app-copy-inquiry-link>
  <span class="copy-inquiry-link-info" i18n
    >Einfach den Einladungslink kopieren und versenden</span
  >
</div>
