<div class="container">
  <div class="header">
    <!-- TODO: Show image name as soon as we can change it in client -->
    <!--<span>{{currentImage.name}}.{{currentImage.extension}}</span> -->
    <span></span>
    <div>
      <mat-icon (click)="close()">close</mat-icon>
    </div>
  </div>
  <div class="content">
    <div class="wrapper">
      <video [src]="currentFile.path" autoplay muted loop></video>
    </div>
  </div>
</div>
