import { Injectable } from '@angular/core';
import {
  RequestValidateEmail,
  ValidateEmail,
  ValidateEmailService as Api,
} from '../../../api/gen';
import { Observable } from 'rxjs';

@Injectable()
export class ValidateEmailService {
  constructor(private readonly validateEmailService: Api) {}

  requestValidateEmail(
    email: string,
    externalEmployeeIdentifier: string,
  ): Observable<any> {
    const request = {
      email: email,
      externalEmployeeIdentifier: externalEmployeeIdentifier,
    } as RequestValidateEmail;
    return this.validateEmailService.validateEmailRequestPost(request);
  }

  validateEmail(
    email: string,
    externalEmployeeIdentifier: string,
    token: string,
  ): Observable<any> {
    const request = {
      email: email,
      externalEmployeeIdentifier: externalEmployeeIdentifier,
      token: token,
    } as ValidateEmail;
    return this.validateEmailService.validateEmailPost(request);
  }
}
