<div>
  <app-heading-with-icon-and-action
    i18n-title
    title="Kundendaten"
    icon="person"
    i18n-actionTitle
    actionTitle="Bearbeiten"
    actionIcon="edit"
    (actionTriggered)="editContactDetails()"
  >
  </app-heading-with-icon-and-action>

  <div class="customer-info-container">
    <div class="multi-line-info">
      <div class="header ns" i18n>Name</div>
      <div *ngIf="inquiry?.firstName || inquiry?.lastName" class="data">
        {{ inquiry?.firstName }} {{ inquiry?.lastName }}
      </div>
      <div
        *ngIf="!(inquiry?.firstName || inquiry?.lastName)"
        class="no-value-set ns"
        i18n
      >
        Unbekannt
      </div>
    </div>

    <div class="multi-line-info">
      <div class="header ns" i18n>E-Mail</div>
      <div *ngIf="inquiry?.email" class="data">
        {{ inquiry?.email }}
      </div>
      <div *ngIf="!inquiry?.email" class="no-value-set ns" i18n>
        Keine Email angegeben
      </div>
    </div>

    <div class="multi-line-info">
      <div class="header ns" i18n>Telefon</div>
      <div *ngIf="inquiry?.phoneNumber" class="data">
        {{ inquiry?.phoneNumber }}
      </div>
      <div *ngIf="!inquiry?.phoneNumber" class="no-value-set ns" i18n>
        Keine Handynummer angegeben
      </div>
    </div>
  </div>
</div>
