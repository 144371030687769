import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import {
  CompanyService,
  InitializationStatusService,
  UpdateCompanyInformation,
  UpdateCompanyLogo,
  UpdateInitializationStatus,
} from '../../../api/gen';
import { BehaviorSubject, switchMap } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { MatStepper } from '@angular/material/stepper';
import { SupportedFileTypes } from '../../util/supported-file-types';

@Component({
  selector: 'app-setup-platform-overview',
  templateUrl: './setup-platform-overview.component.html',
  styleUrls: ['./setup-platform-overview.component.scss'],
})
export class SetupPlatformOverviewComponent {
  protected isLoading$ = new BehaviorSubject(false);
  protected updatingData$ = new BehaviorSubject(false);

  companySettingsFormGroup = this.formBuilder.group({
    companyDisplayName: ['', Validators.required],
  });

  dataProtectionFormGroup = this.formBuilder.group({
    dataProtectionUrl: [''],
  });

  isEditable = true;
  canSelectCompanyLogo = new BehaviorSubject(false);
  selectedCompanyLogoName = new BehaviorSubject<string>(null);
  selectedCompanyLogoData: string;

  companyDataInitialized = new BehaviorSubject(false);

  constructor(
    private readonly snackBar: MatSnackBar,
    private readonly router: Router,
    private readonly formBuilder: FormBuilder,
    private readonly initializationStatusService: InitializationStatusService,
    private readonly companyService: CompanyService,
  ) {
    this.companyService.companyGet().subscribe({
      next: (settings) => {
        if (!settings) {
          this.createDefaultCompanyData();
        } else {
          this.companyDataInitialized.next(true);
        }
        this.canSelectCompanyLogo.next(true);
      },
      error: () => {
        this.isLoading$.next(true);
      },
    });
  }

  onCompanyLogoSelected(event: Event) {
    const input = event.target as HTMLInputElement;
    const file = input.files[0];

    if (file == null) {
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (e) => {
      if (e.target && e.target.result) {
        const mimeType = file.type;
        if (mimeType.match(SupportedFileTypes.IMAGE)) {
          this.selectedCompanyLogoName.next(file.name);
          this.selectedCompanyLogoData = e.target.result.toString();
          this.updateCompanyLogo();
        } else {
          this.snackBar.open(
            $localize`Hochgeladene Datei ist kein Foto.`,
            $localize`Ok`,
            {
              duration: 3000,
            },
          );
        }
      }
    };
  }

  onSetupCompleted() {
    this.isLoading$.next(true);

    const updateInitializationStatus = {
      companySettingsInitialized: this.companyDataInitialized.value,
      inquiryReportTemplateInitialized: true,
    } as UpdateInitializationStatus;

    this.initializationStatusService
      .initializationPut(updateInitializationStatus)
      .subscribe({
        next: () => {
          this.router.navigate(['']);
        },
        error: () => {
          this.snackBar.open('');
        },
      });
  }

  companySettingsNextClicked(stepper: MatStepper) {
    this.updateCompanyDisplayName(stepper);
  }

  privacyUrlNextClicked(stepper: MatStepper) {
    const url = this.dataProtectionFormGroup.controls.dataProtectionUrl.value;
    if (url) {
      this.updateCompanyPrivacyUrl(url, stepper);
    } else {
      stepper.next();
    }
  }

  private updateCompanyPrivacyUrl(privacyUrl: string, stepper: MatStepper) {
    this.companyService
      .companyGet()
      .pipe(
        switchMap((company) => {
          const updateCompanyInformation = {
            displayName: company.displayName,
            privacyUrl: privacyUrl,
          } as UpdateCompanyInformation;
          return this.companyService.companyPut(updateCompanyInformation);
        }),
      )
      .subscribe({
        next: () => {
          stepper.next();
        },
        error: () => {
          this.snackBar.open(
            $localize`Datenschutz URL konnte nicht gesetzt werden`,
            $localize`Ok`,
            {
              duration: 3000,
            },
          );
        },
      });
  }

  private updateCompanyLogo() {
    this.updatingData$.next(true);
    const updateCompanyLogo = {
      logoFileName: this.selectedCompanyLogoName.value
        ? this.selectedCompanyLogoName.value
        : null,
      base64LogoData: this.selectedCompanyLogoData
        ? this.selectedCompanyLogoData
        : null,
    } as UpdateCompanyLogo;

    this.companyService.companyLogoPost(updateCompanyLogo).subscribe({
      next: () => {
        this.updatingData$.next(false);
      },
      error: () => {
        this.snackBar.open(
          $localize`Logo konnte nicht geändert werden`,
          $localize`Ok`,
          {
            duration: 3000,
          },
        );
        this.updatingData$.next(false);
      },
    });
  }

  private updateCompanyDisplayName(stepper: MatStepper) {
    this.companyService
      .companyGet()
      .pipe(
        switchMap((company) => {
          const displayName =
            this.companySettingsFormGroup.controls.companyDisplayName.value;
          const updateCompanyInformation = {
            displayName: displayName,
            privacyUrl: company.privacyUrl,
          } as UpdateCompanyInformation;
          return this.companyService.companyPut(updateCompanyInformation);
        }),
      )
      .subscribe({
        next: () => {
          stepper.next();
        },
        error: () => {
          this.snackBar.open(
            $localize`Anzeigename konnte nicht gesetzt werden`,
            $localize`Ok`,
            {
              duration: 3000,
            },
          );
        },
      });
  }

  private createDefaultCompanyData() {
    this.companyService.companyPost().subscribe(() => {
      this.companyDataInitialized.next(true);
    });
  }
}
