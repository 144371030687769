<div #preview [hidden]="isInitializing" id="preview"></div>
<div
  *ngIf="isInitializing"
  class="loading"
  [style.width]="size ?? '10rem'"
  [style.height]="size ?? '10rem'"
>
  <mat-progress-spinner
    [diameter]="50"
    mode="indeterminate"
  ></mat-progress-spinner>
</div>
