import { AttachmentUploadRequest } from '../../../api/gen';

export class AttachmentUpload {
  sasUri?: string | null;
  blobUri?: string | null;
  container?: string | null;
  blobPath?: string | null;
  storageIdentifier?: string;

  static fromDto(dto: AttachmentUploadRequest): AttachmentUpload {
    return {
      sasUri: dto.sasUri,
      blobUri: dto.blobUri,
      container: dto.container,
      blobPath: dto.blobPath,
      storageIdentifier: dto.storageIdentifier,
    } as AttachmentUploadRequest;
  }
}
