import { ConnectivityInterceptorService } from './util/interceptors/connectivity-interceptor.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TenantHeaderInterceptorService } from './util/interceptors/tenant-header-interceptor-service';
import { AuthenticationTokenInterceptorService } from './authentication/authentication-token-interceptor.service';

export const interceptorProviders = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: ConnectivityInterceptorService,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: TenantHeaderInterceptorService,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthenticationTokenInterceptorService,
    multi: true,
  },
];
