import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { fromEvent, merge, Observable, Subject, Subscription } from 'rxjs';
import { map, pairwise, switchMap, takeUntil } from 'rxjs/operators';
import { CanvasSyncService } from '../services/canvas-sync.service';

// TODO: Maybe it would be better if the canvas does not know about modes and origins (local, remote) etc, but rather just
// receives events which should be drawn
@Component({
  selector: 'app-drawing-canvas',
  templateUrl: './drawing-canvas.component.html',
  styleUrls: ['./drawing-canvas.component.scss'],
})
export class DrawingCanvasComponent implements OnInit, OnDestroy {
  @ViewChild('canvas', { static: true })
  public canvas: ElementRef;

  @Input() public mode$: Observable<Mode>;
  @Input() public externalDrawEvent$: Observable<DrawEvent>;
  @Input() public canvasSyncService: CanvasSyncService;
  @Output()
  public drawn = new EventEmitter<DrawEvent>();
  private mode: Mode;
  private drawingStack: { start: Point; end: Point }[][] = [];
  private cx: CanvasRenderingContext2D;

  private readonly drawUnsubscribe$ = new Subject<void>();
  private readonly unsubscribeFromExternalEvent$ = new Subject<void>();

  private modeListener: Subscription;

  private readonly drawStyle = {
    lineWidth: 3,
    color: '#fff000',
  };
  private readonly pointStyle = {
    lineWidth: 10,
    color: '#ff0000',
  };

  private readonly externalPointStyle = {
    lineWidth: 10,
    color: '#5468ea',
  };

  public ngOnInit() {
    this.setupCanvas();

    this.modeListener = this.mode$.subscribe((mode) => {
      if (mode === Mode.Idle) {
        this.drawUnsubscribe$.next();
      } else if (mode !== Mode.Annotate_Attachment) {
        this.drawUnsubscribe$.next();
        this.mode = mode;
        if (this.mode !== Mode.Arrow) {
          this.captureEvents(this.canvas.nativeElement);
        } else {
          this.setupAnnotationClickListener();
        }
      }
    });

    if (this.externalDrawEvent$) {
      this.externalDrawEvent$
        .pipe(takeUntil(this.unsubscribeFromExternalEvent$))
        .subscribe((drawEvent) => {
          const canvasEl: HTMLCanvasElement = this.canvas.nativeElement;
          if (!this.cx) {
            this.cx = canvasEl.getContext('2d');
          }
          if (drawEvent.mode === Mode.Point || drawEvent.mode === Mode.Draw) {
            this.drawOnCanvas(
              {
                x: drawEvent.prevPosition.x * canvasEl.width,
                y: drawEvent.prevPosition.y * canvasEl.height,
              },
              {
                x: drawEvent.currentPosition.x * canvasEl.width,
                y: drawEvent.currentPosition.y * canvasEl.height,
              },
              drawEvent.mode === Mode.Point
                ? this.externalPointStyle
                : this.drawStyle,
            );
          } else if (drawEvent.mode == Mode.Delete) {
            this.resetCanvas();
          }
        });
    }
  }

  ngOnDestroy() {
    this.resetCanvas();
    this.drawUnsubscribe$.next();
    this.drawUnsubscribe$.complete();
    this.unsubscribeFromExternalEvent$.next();
    this.unsubscribeFromExternalEvent$.complete();
    this.modeListener.unsubscribe();
  }

  clear() {
    this.resetCanvas();
  }

  public setupCanvas() {
    const canvasEl: HTMLCanvasElement = this.canvas.nativeElement;
    this.cx = canvasEl.getContext('2d');
    if (this.canvasSyncService)
      this.canvasSyncService.setDrawingCanvas(canvasEl);
  }

  public undoLine() {
    const idx = this.drawingStack.findIndex((x) => x.length === 0) - 1;
    if (idx >= 0) {
      this.drawingStack.splice(idx, 1);
    }
    this.redrawFromStack();
  }

  // we might want to use host listeners here as well: https://pusher.com/tutorials/collaborative-painting-angular/
  private captureEvents(canvasEl: HTMLCanvasElement) {
    down(canvasEl)
      .pipe(
        takeUntil(this.drawUnsubscribe$),
        switchMap((e) => {
          return move(canvasEl).pipe(
            takeUntil(up(canvasEl)),
            takeUntil(this.drawUnsubscribe$),
            // pairwise lets us get the previous value to draw a line from
            // the previous point to the current point
            pairwise(),
          );
        }),
      )
      .subscribe(
        (res: [{ x: number; y: number }, { x: number; y: number }]) => {
          const rect = canvasEl.getBoundingClientRect();

          // previous and current position with the offset
          const prevPos = {
            x: res[0].x - rect.left,
            y: res[0].y - rect.top,
          };

          const currentPos = {
            x: res[1].x - rect.left,
            y: res[1].y - rect.top,
          };

          this.drawOnCanvas(
            prevPos,
            currentPos,
            this.mode === Mode.Point ? this.pointStyle : this.drawStyle,
          );

          this.escalateDrawEvent(prevPos, currentPos, rect);
          if (this.mode === Mode.Draw) {
            if (this.drawingStack.length === 0) this.drawingStack.push([]);
            this.drawingStack[this.drawingStack.length - 1].push({
              start: prevPos,
              end: currentPos,
            });
          }
        },
      );

    up(canvasEl)
      .pipe(takeUntil(this.drawUnsubscribe$))
      .subscribe((_) => {
        if (this.mode === Mode.Point) {
          setTimeout(() => {
            this.redrawFromStack();
            this.drawn.emit({
              mode: Mode.Delete,
              prevPosition: { x: -1, y: -1 },
              currentPosition: { x: -1, y: -1 },
            });
          }, 1000);
        } else if (this.mode === Mode.Draw) {
          this.drawingStack.push([]);
        }
      });
  }

  private drawOnCanvas(
    prevPos: Point,
    currentPos: Point,
    style: { lineWidth: number; color: string },
  ) {
    if (!this.cx) {
      return;
    }
    this.cx.lineWidth = style.lineWidth;
    this.cx.lineCap = 'round';
    this.cx.strokeStyle = style.color;

    this.cx.beginPath();

    if (prevPos) {
      this.cx.moveTo(prevPos.x, prevPos.y); // from
      this.cx.lineTo(currentPos.x, currentPos.y);
      this.cx.stroke();
    }
  }

  private setupAnnotationClickListener() {
    const canvasEl: HTMLCanvasElement = this.canvas.nativeElement;
    down(canvasEl)
      .pipe(
        map((e) => e as MouseEvent),
        takeUntil(this.drawUnsubscribe$),
      )
      .subscribe((event: MouseEvent) => {
        this.drawn.emit({ mode: Mode.Arrow, currentPosition: event });
      });
  }

  private escalateDrawEvent(prevPos: Point, currentPos: Point, rect: any) {
    const prevNorm = {
      x: prevPos.x / rect.width,
      y: prevPos.y / rect.height,
    };
    const currNorm = {
      x: currentPos.x / rect.width,
      y: currentPos.y / rect.height,
    };

    this.drawn.emit({
      mode: this.mode,
      currentPosition: currNorm,
      prevPosition: prevNorm,
    });
  }

  private resetCanvas() {
    this.clearCanvas();
    this.drawingStack = [];
  }

  private clearCanvas() {
    this.cx &&
      this.cx.clearRect(
        0,
        0,
        this.canvas.nativeElement.width,
        this.canvas.nativeElement.height,
      );
  }

  private redrawFromStack() {
    this.clearCanvas();
    this.drawingStack.forEach((x) => {
      x.forEach((y) => {
        this.drawOnCanvas(y.start, y.end, this.drawStyle);
      });
    });
  }
}

const down = (element: HTMLElement) =>
  merge(fromEvent(element, 'mousedown'), fromEvent(element, 'touchstart')).pipe(
    map(mapEvent),
  );
const up = (element: HTMLElement) =>
  merge(fromEvent(element, 'mouseup'), fromEvent(element, 'touchend')).pipe(
    map(mapEvent),
  );
const move = (element: HTMLElement) =>
  merge(fromEvent(element, 'mousemove'), fromEvent(element, 'touchmove')).pipe(
    map(mapEvent),
  );
const leave = (element: HTMLElement) =>
  merge(
    fromEvent(element, 'mouseleave'),
    fromEvent(element, 'touchcancel'),
  ).pipe(map(mapEvent));

const mapEvent = (event: Event) => {
  // Attention: PreventDefault also prevents creation of a click event. Thus, if focus of listener is wrong, we cant click on anything anymore
  event.preventDefault();
  event.stopPropagation();
  // Needed, as Safari does not know the type "TouchEvent"
  if ('touches' in event) {
    return {
      x: (event as any).touches[0]?.clientX ?? -1,
      y: (event as any).touches[0]?.clientY ?? -1,
    };
  }
  if (event instanceof MouseEvent) {
    return { x: event.clientX, y: event.clientY };
  }
  return null;
};

export enum Mode {
  Idle,
  Draw,
  Point,
  Delete,
  Arrow,
  Object,
  Annotate_Attachment, // This might not be the right place, depending if we introduce ar. Yet, its nice as we block drawing on the canvas this way as well
}

export interface DrawEvent {
  mode: Mode;
  currentPosition?: Point;
  prevPosition?: Point;
}

export interface Point {
  x: number;
  y: number;
}
