<mat-tab-group
  (selectedTabChange)="tabChanged($event)"
  [dynamicHeight]="true"
  [selectedIndex]="activeIndex"
  mat-stretch-tabs
  preserveContent
>
  <mat-tab label="Infos">
    <div class="info-content">
      <mat-accordion class="side-nav-accordion" multi>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title><span i18n>Termin</span></mat-panel-title>
            <mat-panel-description>
              <span i18n>Details & Einladungslink zum Termin</span>
              <mat-icon>account_circle</mat-icon>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <app-inquiry-infos></app-inquiry-infos>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title><span i18n>Verbindung</span> </mat-panel-title>
            <mat-panel-description>
              <span i18n>Details zum Verbindungsstatus</span>
              <mat-icon>wifi</mat-icon>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <app-diagonstics></app-diagonstics>
        </mat-expansion-panel>
      </mat-accordion>

      <mat-card class="notes-content">
        <div class="notes-header-container">
          <span class="notes-header ns" i18n> Notizen </span>
          <span class="spacer"></span>
          <span class="notes-subheader ns" i18n>Nur für Sie sichtbar</span>
          <span class="spacer"></span>
          <span class="end-element"></span>
        </div>

        <mat-card-content>
          <app-call-notes
            [enabled]="true"
            [inquiryId]="inquiryId"
          ></app-call-notes>
        </mat-card-content>
      </mat-card>
    </div>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
      <span
        [matBadgeHidden]="(attachmentCount$ | async) <= 0"
        [matBadgeOverlap]="false"
        [matBadge]="attachmentCount$ | async"
        i18n
        >Dateien</span
      >
    </ng-template>
    <app-appointment-attachments></app-appointment-attachments>
  </mat-tab>
  <mat-tab aria-labelledby="chat-tab" *ngIf="liveChatEnabled">
    <ng-template mat-tab-label>
      <span
        [matBadgeHidden]="(unreadMessagesCount$ | async) <= 0"
        [matBadgeOverlap]="false"
        [matBadge]="unreadMessagesCount$ | async"
        i18n
        >Chat</span
      >
    </ng-template>
    <app-live-chat [inquiryId]="inquiryId"></app-live-chat>
  </mat-tab>
</mat-tab-group>
