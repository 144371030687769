import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { map, Observable, Subject } from 'rxjs';
import { AppointmentAttachmentsService } from '../../../services/appointment-attachments.service';
import { takeUntil } from 'rxjs/operators';
import { UnreadMessagesService } from '@mad-tech/aidar-live-chat';
import { MatTabChangeEvent } from '@angular/material/tabs';

@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.scss'],
})
export class SideBarComponent implements OnInit, OnDestroy {
  @Input()
  public inquiryId: string;
  @Input()
  public liveChatEnabled = false;

  public activeIndex = 0;
  protected attachmentCount$ = new Observable<number>();
  protected unreadMessagesCount$ = new Observable<number>();
  private readonly unsubscribe$ = new Subject<void>();

  constructor(
    private readonly attachmentService: AppointmentAttachmentsService,
    private readonly unreadMessagesService: UnreadMessagesService,
  ) {}

  ngOnInit(): void {
    // TODO: Switching to correct tab can only be done when tinymce is fixing their resize bug
    // TODO: => Already fixed but still needs to be pushed to tinymce-angular https://github.com/tinymce/tinymce/pull/8662
    //this.activeIndex = +localStorage.getItem('index');
    this.attachmentCount$ = this.attachmentService.attachments$.pipe(
      takeUntil(this.unsubscribe$),
      map((x) => x?.length ?? 0),
    );

    this.unreadMessagesCount$ = this.unreadMessagesService
      .onUnreadMessagesChanged()
      .pipe(takeUntil(this.unsubscribe$));
    this.unreadMessagesService.startCounting();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  tabChanged(changeEvent: MatTabChangeEvent) {
    const index = changeEvent.index;
    localStorage.setItem('index', index.toString());
    this.activeIndex = index;
    if (this.liveChatEnabled) {
      if (changeEvent.tab.ariaLabelledby === 'chat-tab') {
        this.unreadMessagesService.stopCounting();
      } else {
        this.unreadMessagesService.startCounting();
      }
    }
  }
}
