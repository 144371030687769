import { FoundTextDto } from '../../../api/gen';

export class FoundText {
  id?: string;
  text?: string | null;

  public static fromDto(dto: FoundTextDto): FoundText {
    return {
      id: dto.id,
      text: dto.text,
    } as FoundText;
  }
}
