import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-subscription-expired-dialog',
  templateUrl: './trial-expired-dialog.component.html',
  styleUrls: ['./trial-expired-dialog.component.scss'],
})
export class TrialExpiredDialogComponent {
  constructor(
    private readonly dialogRef: MatDialogRef<TrialExpiredDialogComponent>,
  ) {}
}
