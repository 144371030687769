import { Component, Input, OnInit } from '@angular/core';
import { InquiryState } from 'projects/helper-client/src/api/gen';

@Component({
  selector: 'app-appointment-date',
  templateUrl: './appointment-date.component.html',
  styleUrls: ['./appointment-date.component.scss'],
})
export class AppointmentDateComponent implements OnInit {
  @Input() inquiryState: InquiryState;
  @Input() startDate: Date | string;
  @Input() endDate: Date | string | null;
  @Input() appointmentConfirmed: boolean | null;
  @Input() appointmentDeclined: boolean | null;

  appointmentState: AppointmentState;

  AppointmentState = AppointmentState;
  InquiryState = InquiryState;

  constructor() {}

  ngOnInit(): void {
    this.updateAppointmentState();
  }

  private updateAppointmentState() {
    if (this.startDate == null) {
      this.appointmentState = AppointmentState.NOT_SET;
      return;
    }

    if (this.appointmentConfirmed != null && this.appointmentConfirmed) {
      this.appointmentState = AppointmentState.CONFIRMED;
    } else if (this.appointmentDeclined != null && this.appointmentDeclined) {
      this.appointmentState = AppointmentState.DECLINED;
    } else {
      this.appointmentState = AppointmentState.ANSWER_REQUESTED;
    }
  }
}

export enum AppointmentState {
  CONFIRMED = 'confirmed',
  DECLINED = 'declined',
  ANSWER_REQUESTED = 'answer_requested',
  NOT_SET = 'not_set',
}
