import { ChangeDetectorRef, Component, Input, signal } from '@angular/core';
import { Employee } from '../../model/employee/employee';
import { EmployeeType } from '../../../api/gen';
import { MatDialog } from '@angular/material/dialog';
import { EditContactDialogComponent } from '../dialogs/edit-contact-dialog/edit-contact-dialog.component';
import { ValidateEmailService } from '../../services/api/validate-email.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-personal-information-contact',
  templateUrl: './personal-information-contact.component.html',
  styleUrls: ['./personal-information-contact.component.scss'],
  providers: [ValidateEmailService],
})
export class PersonalInformationContactComponent {
  @Input('employee') employee: Employee;

  protected validatingEmail$ = signal(false);
  protected emailValidationSent$ = signal(false);

  constructor(
    private readonly validateEmailService: ValidateEmailService,
    private readonly snackBar: MatSnackBar,
    private readonly dialog: MatDialog,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  getRoleText(): string {
    if (this.employee == null) {
      return '-';
    }

    switch (this.employee.type) {
      case EmployeeType.Administrator:
        return $localize`Adminstrator`;
      case EmployeeType.Manager:
        return $localize`Manager`;
      case EmployeeType.Agent:
        return $localize`Benutzer`;
    }

    return $localize`Keine Rolle`;
  }

  showEditContactDialog() {
    const dialogRef = this.dialog.open(EditContactDialogComponent, {
      data: this.employee,
    });

    dialogRef.afterClosed().subscribe((employee: Employee) => {
      if (employee) {
        this.employee = employee;
        this.cdr.markForCheck();
      }
    });
  }

  onSendValidationEmail(employee: Employee) {
    this.validatingEmail$.set(true);
    this.validateEmailService
      .requestValidateEmail(employee.email, employee.externalIdentifier)
      .subscribe(() => {
        this.emailValidationSent$.set(true);
        this.validatingEmail$.set(false);
        this.snackBar.open(
          $localize`Validierungs E-Mail erfolgreich gesendet.`,
          $localize`Ok`,
          { duration: 3000 },
        );
      });
  }
}
