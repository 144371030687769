import { Employee } from '../employee/employee';
import { InquiryOpenDto } from '../../../api/gen';

export class InquiryOpen {
  inquiryIdentifier?: string;
  firstName?: string | null;
  lastName?: string | null;
  scheduledFor?: string | null;
  employee?: Employee;

  static fromDto(dto: InquiryOpenDto): InquiryOpen {
    return {
      inquiryIdentifier: dto.inquiryIdentifier,
      firstName: dto.firstName,
      lastName: dto.lastName,
      scheduledFor: dto.scheduledFor,
      employee: Employee.fromDto(dto.employee),
    } as InquiryOpen;
  }
}
