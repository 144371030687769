<mat-card class="legal-entity-container">
  <app-card-header-with-action
    i18n-title
    title="Unternehmens Standort"
    (actionClicked)="onChangeLegalEntity()"
  ></app-card-header-with-action>

  @if (legalEntity$ | async; as le) {
    <div>
      <mat-list>
        <mat-list-item>
          <mat-icon matListItemIcon>location_city</mat-icon>
          <div matListItemTitle class="ns">
            {{ le.displayName }}
          </div>
        </mat-list-item>
      </mat-list>
    </div>
  } @else {
    <div class="no-legal-entity-assigned-container" i18n>
      Kein Standort zugewiesen
    </div>
  }
</mat-card>
