<app-heading-with-icon-and-action
  i18n-title
  title="Anhänge"
  icon="attachment"
  i18n-actionTitle
  actionTitle="Anhang hochladen"
  i18n-actionTitleShort
  actionTitleShort="Hochladen"
  actionIcon="add"
  (actionTriggered)="fileUpload.click()"
></app-heading-with-icon-and-action>

<div class="section-container">
  <app-info-text-with-icon
    i18n-text
    text="Kunden werden per SMS über hochgeladene Anhänge benachrichtigt."
  ></app-info-text-with-icon>
  <div>
    <input
      #fileUpload
      (change)="onFileSelected($event)"
      accept="image/*, application/pdf"
      class="file-input"
      multiple
      type="file"
    />
  </div>

  <div class="scroll-row">
    <app-attachment-preview
      i18n-emptyText
      emptyText="Keine nachträglichen Anhänge hochgeladen."
      [attachmentList]="attachmentsToShow"
      [menu]="menu"
    ></app-attachment-preview>
    <mat-menu #menu="matMenu">
      <ng-template matMenuContent let-data="data">
        <button (click)="editAttachmentNotes(data)" mat-menu-item>
          <mat-icon>description</mat-icon>
          <span i18n>Notiz bearbeiten</span>
        </button>
        <button (click)="downloadAttachment(data)" mat-menu-item>
          <mat-icon>download</mat-icon>
          <span>Download</span>
        </button>
        <button (click)="deleteAttachment(data)" mat-menu-item>
          <mat-icon>delete</mat-icon>
          <span>Löschen</span>
        </button>
      </ng-template>
    </mat-menu>
  </div>
</div>
