import { EditInquiryBaseDataBody } from '../../../../api/gen';

export class EditInquiryBaseDataRequest {
  newFirstName?: string | null;
  newLastName?: string | null;
  newEmail?: string | null;

  static toBody(request: EditInquiryBaseDataRequest): EditInquiryBaseDataBody {
    return {
      newFirstName: request.newFirstName,
      newLastName: request.newLastName,
      newEmail: request.newEmail,
    } as EditInquiryBaseDataBody;
  }
}
