<div
  class="menu-bar-badge-container"
  *ngIf="quotaDataService.employeeQuota | async as quota"
>
  <div *ngIf="currentSubscriptionType | async as subscriptionType">
    <div
      *ngIf="
        subscriptionType === SubscriptionType.Trial ||
        subscriptionType === SubscriptionType.Starter
      "
    >
      <div *ngIf="(showBuyAction | async) === false; else buyAction">
        <div
          class="menu-bar-badge"
          *ngIf="quota.callsLeftForEmployee !== null; else tenantQuota"
        >
          <span class="menu-bar-badge-text" i18n
            >{{ quota.callsLeftForEmployee }} Anrufe verfügbar</span
          >
        </div>
      </div>
    </div>
  </div>

  <ng-template #buyAction>
    <button mat-flat-button color="accent" (click)="onBuyClicked()" i18n>
      blankmile.kaufen
    </button>
  </ng-template>

  <ng-template #tenantQuota>
    <div class="menu-bar-badge" *ngIf="quota.callsLeftForTenant !== null">
      <span class="menu-bar-badge-text" i18n
        >{{ quota.callsLeftForTenant }} Anrufe verfügbar</span
      >
    </div>
  </ng-template>
</div>
