import { Injectable } from '@angular/core';
import { filter, map, Observable, Subject } from 'rxjs';
import { SignalingService } from '../signaling.service';
import { NotificationCenterCommands } from '../commands/notification-center-commands';

@Injectable({ providedIn: 'root' })
export class NotificationCenterMessagingService {
  private readonly messageStream = new Subject<Message>();

  constructor(private readonly signalingService: SignalingService) {}

  public onMessage(type: NotificationCenterMessageType): Observable<any> {
    return this.messageStream.asObservable().pipe(
      filter((x) => x.type === type),
      map((x) => x.data),
    );
  }

  public addNotificationCenterListener(): void {
    this.signalingService
      .getHubConnection()
      .on(NotificationCenterCommands.notificationCreated, (data) => {
        this.messageStream.next(
          new Message(NotificationCenterMessageType.notificationCreated, data),
        );
      });
  }
}

export enum NotificationCenterMessageType {
  notificationCreated,
}

class Message {
  constructor(
    public type: NotificationCenterMessageType,
    public data: any,
  ) {}
}
