import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import {
  AuthenticationTokenService,
  VideoAppointmentAccessDto,
} from 'projects/helper-client/src/api/gen';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { Subject } from 'rxjs/internal/Subject';
import {
  connect,
  ConnectOptions,
  RemoteParticipant,
  RemoteTrack,
  RemoteTrackPublication,
  Room,
} from 'twilio-video';
import { DrawEvent } from '../../annotations/drawing-canvas/drawing-canvas.component';
import { videoAppointmentRoute } from '../../app-routing.module';
import { ConnectionErrorDialogComponent } from '../dialogs/connection-error-dialog/connection-error-dialog.component';
import { DisconnectedDialogComponent } from '../dialogs/disconnected-dialog/disconnected-dialog.component';
import { CallCtrlService } from './call-ctrl.service';
import { CapabilitiesService } from './capabilities.service';
import { CamState, MicState } from './device.service';
import { DiagnosticsService } from './diagnostics/diagnostics.service';
import {
  DataTrackObject,
  DataTrackObjectType,
  LocalTrackCtrlService,
} from './local-track-ctrl.service';
import { AppointmentService } from '../../services/api/appointment.service';
import {
  AppointmentMessageType,
  AppointmentMessagingService,
} from '../../services/signaling/messaging/appointment-messaging.service';

@Injectable({
  providedIn: 'root',
})
export class VideoChatService {
  userDrawEvent$: Subject<DrawEvent> = new Subject<DrawEvent>();
  localDrawEvent$: Subject<DrawEvent> = new Subject<DrawEvent>();

  activeRoom$ = new BehaviorSubject<AidarRoom>(null);
  activeRoom: AidarRoom;

  private inquiryToJoin: string;

  private currentCancelDialog: MatDialogRef<any>;
  private connectingSnackbar: MatSnackBarRef<any>;

  constructor(
    private readonly tokenService: AuthenticationTokenService,
    private readonly appointmentService: AppointmentService,
    private readonly callCtrl: CallCtrlService,
    private readonly trackCtrl: LocalTrackCtrlService,
    private readonly router: Router,
    private readonly location: Location,
    private readonly dialog: MatDialog,
    private readonly appointmentMessagingService: AppointmentMessagingService,
    private readonly snackBarService: MatSnackBar,
    private readonly capabilitiesService: CapabilitiesService,
    private readonly diagnosticsService: DiagnosticsService,
  ) {
    this.activeRoom$.subscribe((room?: AidarRoom) => {
      this.activeRoom = room;
      this.diagnosticsService.isConnectingToRoom(room);
      this.registerRoomEvents();
      // TODO: Move to own service / class
      // Or even better: make an active room service
      // Also, group should be left upon disconnect
      if (room) {
        this.diagnosticsService.participantConnected(
          this.activeRoom?.room?.participants.size > 0,
        );
        this.callCtrl.subscribeToCamState().subscribe((x) => {
          if (x === CamState.Off) {
            this.activeRoom?.room?.localParticipant.videoTracks.forEach((x) =>
              x.track.disable(),
            );
          } else {
            this.activeRoom?.room?.localParticipant.videoTracks.forEach((x) =>
              x.track.enable(),
            );
          }
        });
        this.callCtrl.subscribeToMicState().subscribe((x) => {
          if (x === MicState.Muted) {
            this.activeRoom?.room?.localParticipant.audioTracks.forEach((x) =>
              x.track.disable(),
            );
          } else {
            this.activeRoom?.room?.localParticipant.audioTracks.forEach((x) =>
              x.track.enable(),
            );
          }
        });

        if (room.data.roomFinishedAt) this.trackCtrl.clearTracks();
      } else if (!room) {
        this.trackCtrl.clearTracks();
      }
    });

    this.trackCtrl.videoTrackUpdated$.subscribe((newTrack) => {
      if (!this.activeRoom?.room) return;
      const user = this.activeRoom.room.localParticipant;
      user.videoTracks.forEach((x) => x.unpublish());
      user.publishTrack(newTrack);
    });
    this.trackCtrl.audioTrackUpdated$.subscribe((newTrack) => {
      if (!this.activeRoom?.room) return;
      const user = this.activeRoom.room.localParticipant;
      user.audioTracks.forEach((x) => x.unpublish());
      user.publishTrack(newTrack);
    });

    this.appointmentMessagingService
      .onMessage(AppointmentMessageType.Hang_Up)
      .subscribe(() => {
        this.disconnect();
        if (this.router.url.includes(videoAppointmentRoute)) {
          this.showAppointmentClosedDialog();
        }
        // If the "real" data is needed, we should send it via the connection hub
        this.activeRoom$.next(
          new AidarRoom({ roomFinishedAt: Date.now().toString() }, null),
        );
      });
  }

  disconnect() {
    this.activeRoom?.room?.disconnect();
    this.activeRoom$.next(null);
  }

  async joinRoom(inquiryId: string): Promise<void> {
    if (this.activeRoom) {
      this.disconnect();
    }
    this.inquiryToJoin = inquiryId;

    const tracks = (
      await Promise.all([
        await this.trackCtrl.getAudioTrack(),
        await this.trackCtrl.getVideoTrack(),
        this.trackCtrl.getDataTrack(),
      ])
    ).filter((x) => !!x);

    this.connectingSnackbar = this.snackBarService.open('Verbindungsaufbau...');

    const connectionData = await this.getRoomConnectionData(inquiryId);
    if (!connectionData.roomFinishedAt) {
      try {
        const room = await connect(connectionData.accessToken, {
          tracks,
          region: 'ie1',
        } as ConnectOptions);
        this.activeRoom$.next(new AidarRoom(connectionData, room));
      } catch (error) {
        this.showAppointmentClosedDialog(true);
      } finally {
        this.connectingSnackbar.dismiss();
      }
    } else {
      this.activeRoom$.next(new AidarRoom(connectionData, null));
      this.showAppointmentClosedDialog();
      this.connectingSnackbar.dismiss();
    }
  }

  async endCall(): Promise<void> {
    await firstValueFrom(
      this.appointmentService.finishAppointment(this.inquiryToJoin),
    );
  }

  private async getRoomConnectionData(inquiryId: string) {
    const result = await this.tokenService
      .appointmentsTokenAgentPost({
        inquiryIdentifier: inquiryId,
      })
      .toPromise();
    //const auth = await this.http.get<AuthToken>(`api/video/token`).toPromise();
    return result;
  }

  private showAppointmentClosedDialog(isError = false) {
    // This is needed as otherwise it could happen that the dialog is shown twice if both end the dialog at the same time
    if (this.currentCancelDialog) return;

    this.currentCancelDialog = this.dialog.open(
      isError ? ConnectionErrorDialogComponent : DisconnectedDialogComponent,
      {
        hasBackdrop: true,
        disableClose: true,
        data: this.inquiryToJoin,
      },
    );
    this.currentCancelDialog.afterClosed().subscribe(async (_) => {
      this.currentCancelDialog = null;
      if (isError && this.inquiryToJoin) {
        await this.endCall();
      }
      // TODO: Also handle "reconnect" scenarios and possiblities
      // TODO: We should distinguish why we got disconnected (end call, connection issues, ...)
      // TODO; Check if we should create a "end call" data track event to check if call has ended
      if (this.router.getCurrentNavigation()?.previousNavigation) {
        this.location.back();
      } else {
        this.router.navigate(['/scheduling/details'], {
          replaceUrl: true,
          queryParams: { inquiryId: this.inquiryToJoin },
          queryParamsHandling: 'merge',
        });
      }
    });
  }

  private registerRoomEvents() {
    const onBrowserClosed = () => {
      this.disconnect();
    };
    window.removeEventListener('beforeunload', onBrowserClosed);
    window.removeEventListener('pagehide', onBrowserClosed);
    window.addEventListener('beforeunload', onBrowserClosed);
    window.addEventListener('pagehide', onBrowserClosed, false);

    // TODO: This should not be here, especially the data track handling

    this.activeRoom?.room
      ?.on('participantConnected', (participant: RemoteParticipant) => {
        this.diagnosticsService.participantConnected(true);
      })
      .on('participantDisconnected', (participant: RemoteParticipant) => {
        this.diagnosticsService.participantConnected(false);
      })
      .on(
        'trackSubscribed',
        (
          track: RemoteTrack,
          publication: RemoteTrackPublication,
          participant: RemoteParticipant,
        ) => {
          if (track.kind === 'data') {
            track.on('message', (message: string) => {
              const cmd = JSON.parse(message) as DataTrackObject;

              switch (cmd.type) {
                case DataTrackObjectType.ANNOTATE:
                  this.userDrawEvent$.next(cmd.data);
                  break;

                case DataTrackObjectType.CAPABILITIES_ANSWER:
                  this.capabilitiesService.updateCapabilities(cmd.data);
                  break;

                case DataTrackObjectType.DIAGNOSTICS:
                  this.diagnosticsService.updateDiagnostics(cmd.data);
                  break;

                case DataTrackObjectType.HEARTBEAT:
                  this.diagnosticsService.heartbeatReceived();
                  break;
              }
            });
          }
        },
      );
  }

  public drawOnVideo(data: DrawEvent) {
    this.localDrawEvent$.next(data);
    this.trackCtrl.sendDataObject(
      DataTrackObject.withType(DataTrackObjectType.ANNOTATE).withData(data),
    );
  }
}

export class AidarRoom {
  constructor(
    public data: VideoAppointmentAccessDto,
    public room?: Room,
  ) {}
}
