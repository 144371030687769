import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { RealmService } from '../../authentication/realm.service';

@Injectable()
export class TenantHeaderInterceptorService implements HttpInterceptor {
  constructor(private readonly realmService: RealmService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    const clonedRequest = req.clone({
      setHeaders: { __tenant__: this.realmService.getRealm() },
    });
    return next.handle(clonedRequest);
  }
}
