import { Attachment } from '../../../../model/attachment/attachment';
import { AttachmentState } from './attachment-state';

export class AttachmentWithState {
  name: string;
  attachment?: Attachment;
  state: AttachmentState;

  constructor(
    name: string,
    attachment: Attachment = null,
    state: AttachmentState,
  ) {
    this.name = name;
    this.attachment = attachment;
    this.state = state;
  }

  static toAttachment(aws: AttachmentWithState): Attachment {
    return aws.attachment;
  }
}
