import { CreateInquirySummaryReport } from 'projects/helper-client/src/api/gen';
import { InquirySummaryReportConfiguration } from '../inquiry-summary-report-configuration';

export class CreateInquirySummaryReportRequest {
  inquiryIdentifier?: string;
  configuration?: InquirySummaryReportConfiguration;
  cultureCode?: string | null;
  emailRecipient?: string | null;

  static toDto(
    request: CreateInquirySummaryReportRequest,
  ): CreateInquirySummaryReport {
    return {
      inquiryIdentifier: request.inquiryIdentifier,
      configuration: InquirySummaryReportConfiguration.fromDto(
        request.configuration,
      ),
      cultureCode: request.cultureCode,
      emailRecipient: request.emailRecipient,
    } as CreateInquirySummaryReport;
  }
}
