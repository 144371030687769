import { PhoneNumberValidationErrorDto } from '../../../../api/gen';

export class PhoneNumberValidationError {
  value?: string | null;

  public static fromDto(
    dto: PhoneNumberValidationErrorDto,
  ): PhoneNumberValidationError {
    return { value: dto.value } as PhoneNumberValidationError;
  }
}
