import { CompanyLoginInfoDto } from '../../../api/gen';

export class CompanyLoginInfo {
  displayName?: string | null;
  logoUrl?: string | null;

  static fromDto(dto: CompanyLoginInfoDto): CompanyLoginInfo {
    return {
      displayName: dto.displayName,
      logoUrl: dto.logoUrl,
    } as CompanyLoginInfo;
  }
}
