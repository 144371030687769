import { Injectable } from '@angular/core';
import { CountryPhoneData } from './country-data.service';

@Injectable()
export class CountryFilterService {
  getFilteredCountries(
    countries: CountryPhoneData[],
    filter: string,
  ): CountryPhoneData[] {
    return countries
      .filter((entry) =>
        CountryFilterService.countryNameIncludesFilter(entry, filter),
      )
      .sort((a, b) => CountryFilterService.sortCountriesByEnglishName(a, b));
  }

  private static countryNameIncludesFilter(
    entry: CountryPhoneData,
    filter: string,
  ): boolean {
    return (
      entry.countryNameLocal.toLowerCase().includes(filter.toLowerCase()) ||
      entry.countryNameEnglish.toLowerCase().includes(filter.toLowerCase())
    );
  }

  private static sortCountriesByEnglishName(
    a: CountryPhoneData,
    b: CountryPhoneData,
  ) {
    const nameA = a.countryNameEnglish.toUpperCase();
    const nameB = b.countryNameEnglish.toUpperCase();

    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    return 0;
  }
}
