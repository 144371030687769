<div class="wrapper">
  <div class="call-controls">
    <ng-container [ngSwitch]="camAvailability | async">
      <div
        *ngSwitchCase="DeviceAvailability.Ready"
        (click)="toggleVideo()"
        class="action"
      >
        <mat-icon aria-hidden="false">
          {{ (isCameraEnabled$ | async) ? "videocam" : "videocam_off" }}
        </mat-icon>
      </div>
      <div
        *ngSwitchCase="DeviceAvailability.PermissionDenied"
        class="action-disabled"
      >
        <mat-icon
          i18n-matTooltip
          matTooltip="Kamera Berechtigung nicht erteilt!"
          matTooltipPosition="right"
        >
          videocam_off
        </mat-icon>
      </div>
      <div *ngSwitchCase="DeviceAvailability.Missing" class="action-disabled">
        <mat-icon
          i18n-matTooltip
          matTooltip="Keine Kamera verfügbar!"
          matTooltipPosition="right"
        >
          videocam_off
        </mat-icon>
      </div>
    </ng-container>

    <ng-container [ngSwitch]="micAvailability | async">
      <div
        *ngSwitchCase="DeviceAvailability.Ready"
        (click)="toggleMic()"
        class="action"
      >
        <mat-icon aria-hidden="false">
          {{ (isMicrophoneEnabled$ | async) ? "mic" : "mic_off" }}
        </mat-icon>
      </div>
      <div
        *ngSwitchCase="DeviceAvailability.PermissionDenied"
        class="action-disabled"
      >
        <mat-icon
          i18n-matTooltip
          matTooltip="Mikrofon Berechtigung nicht erteilt!"
          matTooltipPosition="right"
        >
          mic_off
        </mat-icon>
      </div>
      <div *ngSwitchCase="DeviceAvailability.Missing" class="action-disabled">
        <mat-icon
          i18n-matTooltip
          matTooltip="Kein Mikrofon verfügbar!"
          matTooltipPosition="right"
        >
          mic_off
        </mat-icon>
      </div>
    </ng-container>
    <div (click)="openSettings()" class="action">
      <mat-icon aria-hidden="false">settings</mat-icon>
    </div>
  </div>
  <div class="ar-actions">
    <div *ngIf="(content | async) === ContentType.DEFAULT" class="controls">
      <ng-container [ngTemplateOutlet]="controls"></ng-container>
    </div>
  </div>
  <div *ngIf="!!timer" class="timer">
    {{ timer | async }}
  </div>
  <button (click)="endCall()" class="hang-up-btn" mat-mini-fab>
    <mat-icon>call_end</mat-icon>
  </button>
</div>

<ng-template #controls>
  <div *ngIf="callCtrlService.subscribeToFlashState() | async as state">
    <div
      *ngIf="state === FlashState.On || state === FlashState.Off"
      [ngSwitch]="state"
      [class.action-disabled]="!videoCtrlsEnabled"
      class="action"
      (click)="toggleFlash()"
    >
      <mat-icon *ngSwitchDefault aria-hidden="false" class="disabled"
        >flashlight_off
      </mat-icon>
      <mat-icon *ngSwitchCase="FlashState.On" aria-hidden="false">
        flashlight_on
      </mat-icon>
      <mat-icon *ngSwitchCase="FlashState.Off" aria-hidden="false">
        flashlight_off
      </mat-icon>
      <span class="action-title hide-on-small-height ns" i18n>Flash</span>
    </div>
  </div>
  <div
    (click)="videoCtrlsEnabled && changeMode(Mode.Point)"
    [class.selected]="(mode$ | async) === Mode.Point"
    [class.action-disabled]="!videoCtrlsEnabled"
    class="action"
  >
    <mat-icon svgIcon="stylus_laser_pointer" aria-hidden="false"></mat-icon>
    <span class="action-title hide-on-small-height ns">Laser</span>
  </div>
  <hr class="divider hide-on-small-height" />
  <div
    (click)="videoCtrlsEnabled && !pictureLoading && takePicture()"
    [class.action-disabled]="!videoCtrlsEnabled || pictureLoading"
    class="action"
  >
    <mat-icon aria-hidden="false">photo_camera</mat-icon>
    <mat-spinner
      *ngIf="pictureLoading"
      class="picture-spinner"
      [diameter]="25"
    ></mat-spinner>
    <span class="action-title hide-on-small-height ns" i18n>Foto</span>
  </div>
  <div
    *ngIf="livePhotoAllowed"
    (click)="videoCtrlsEnabled && !pictureLoading && takeLivePicture()"
    [class.action-disabled]="!videoCtrlsEnabled || pictureLoading"
    class="action"
  >
    <mat-icon svgIcon="animated_images" aria-hidden="false"></mat-icon>
    <mat-spinner
      *ngIf="pictureLoading"
      class="picture-spinner"
      [diameter]="25"
    ></mat-spinner>
    <span class="action-title hide-on-small-height ns" i18n>Live</span>
  </div>
  <hr class="divider hide-on-small-height" />
  <div
    [ngSwitch]="callCtrlService.subscribeToZoomState() | async"
    [class.action-disabled]="!videoCtrlsEnabled"
    class="action"
    (click)="videoCtrlsEnabled && toggleZoom()"
  >
    <mat-icon *ngSwitchCase="ZoomState.Default" aria-hidden="false">
      zoom_in
    </mat-icon>
    <mat-icon *ngSwitchCase="ZoomState.Zoom1" aria-hidden="false">
      zoom_out
    </mat-icon>
    <mat-icon *ngSwitchDefault aria-hidden="false" class="disabled">
      zoom_in
    </mat-icon>
    <span class="action-title hide-on-small-height ns" i18n>Zoom</span>
  </div>
  <div
    (click)="videoCtrlsEnabled && recordCall()"
    *ngIf="recordingAllowed"
    [class.selected]="isRecording$ | async"
    [class.action-disabled]="!videoCtrlsEnabled"
    class="action"
  >
    <mat-icon aria-hidden="false">radio_button_checked</mat-icon>
    <span class="action-title hide-on-small-height ns" i18n>Video</span>
  </div>
</ng-template>
