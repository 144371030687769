import { CredentialsDto } from '../../../api/gen';

export class Credentials {
  username?: string | null;
  password?: string | null;

  static fromDto(dto: CredentialsDto): Credentials {
    return { username: dto.username, password: dto.password } as Credentials;
  }
}
