import { Component, Input } from '@angular/core';
import { Attachment } from '../../../model/attachment/attachment';
import { AttachmentContentType } from '../../../model/attachment/attachment-content-type';

@Component({
  selector: 'app-view-attachment-live-photo',
  templateUrl: './view-attachment-live-photo.component.html',
  styleUrl: './view-attachment-live-photo.component.scss',
})
export class ViewAttachmentLivePhotoComponent {
  @Input() attachment: Attachment;
  constructor() {}
  protected readonly AttachmentContentType = AttachmentContentType;
}
