import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CallAttachment } from '../../../services/appointment-attachments.service';

@Component({
  selector: 'app-present-live-image',
  templateUrl: './present-live-image.component.html',
  styleUrls: ['./present-live-image.component.scss'],
})
export class PresentLiveImageComponent {
  @Input() public currentFile: CallAttachment;
  @Output() public closeAttachment = new EventEmitter<void>();

  close(): void {
    this.closeAttachment.emit();
  }
}
