import { BrandingColorService as Api } from '../../../api/gen';
import { map, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { BrandingColor, BrandingColorApiInterface } from 'aidar-branding';
import { BrandingColorDtoCasts } from '../../model/company/branding/branding-color';

@Injectable({ providedIn: 'root' })
export class BrandingColorService implements BrandingColorApiInterface {
  constructor(private readonly brandingColorService: Api) {}

  getActiveBrandingColor(): Observable<BrandingColor> {
    return this.brandingColorService
      .brandingColorGet()
      .pipe(map((dto) => BrandingColorDtoCasts.fromDto(dto)));
  }
}
