import { EmployeeService as EmployeeApi } from '../../../api/gen';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { Employee } from '../../model/employee/employee';
import { CreateUserFromValidationTokenRequest } from '../../model/signup/request/create-user-from-validation-token-request';
import { CreateUserManuallyRequest } from '../../model/employee/request/create-user-manually-request';

@Injectable()
export class EmployeeService {
  constructor(private readonly employeeService: EmployeeApi) {}

  createUserFromValidationToken(
    request: CreateUserFromValidationTokenRequest,
  ): Observable<Employee> {
    return this.employeeService
      .employeePost(CreateUserFromValidationTokenRequest.toDto(request))
      .pipe(map((dto) => Employee.fromDto(dto)));
  }

  createUserManually(request: CreateUserManuallyRequest): Observable<Employee> {
    return this.employeeService
      .employeeManuallyPost(CreateUserManuallyRequest.toDto(request))
      .pipe(map((dto) => Employee.fromDto(dto)));
  }

  getEmployeeInformationById(employeeIdentifier: string): Observable<Employee> {
    return this.employeeService
      .employeeIdIdentifierGet(employeeIdentifier)
      .pipe(map((x) => Employee.fromDto(x)));
  }
}
