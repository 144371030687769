import { Component, Inject } from '@angular/core';
import { Employee } from '../../../model/employee/employee';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { ProfileService } from '../../../services/api/profile-service';
import { UpdateEmployeeRequest } from '../../../model/employee/request/update-employee-request';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-edit-contact-dialog',
  templateUrl: './edit-contact-dialog.component.html',
  styleUrls: ['./edit-contact-dialog.component.scss'],
  providers: [ProfileService],
})
export class EditContactDialogComponent {
  protected readonly loading$ = new BehaviorSubject(false);
  protected employee: Employee;

  employeeContactForm = new FormGroup({
    firstName: new FormControl('', Validators.required),
    lastName: new FormControl('', Validators.required),
    email: new FormControl({ value: '', disabled: true }, [
      Validators.email,
      Validators.required,
    ]),
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Employee,
    private readonly dialogRef: MatDialogRef<EditContactDialogComponent>,
    private readonly profileService: ProfileService,
    private readonly snackBar: MatSnackBar,
  ) {
    this.employee = data;
    this.employeeContactForm.controls.firstName.setValue(data.firstName);
    this.employeeContactForm.controls.lastName.setValue(data.lastName);
    this.employeeContactForm.controls.email.setValue(data.email);
  }

  onClickSaveEmployee() {
    this.loading$.next(true);
    const request = {
      externalIdentifier: this.employee.externalIdentifier,
      firstName: this.employeeContactForm.controls.firstName.value,
      lastName: this.employeeContactForm.controls.lastName.value,
      eMail: this.employee.email,
      department: this.employee.department,
    } as UpdateEmployeeRequest;

    this.profileService.updateProfile(request).subscribe((employee) => {
      this.snackBar.open(
        $localize`Kontaktdaten wurden erfolgreich aktualisiert`,
        $localize`Ok`,
        {
          duration: 3_000,
        },
      );
      this.dialogRef.close(employee);
      this.loading$.next(false);
    });
  }
}
