import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[OnlyNumber]',
})
export class OnlyNumberDirective {
  @Input() OnlyNumber: boolean;

  constructor(private el: ElementRef) {}

  @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent) {
    const e = event;
    if (this.OnlyNumber) {
      if (allowedKeys.has(e.key)) {
        return;
      }

      if ((e.metaKey || e.ctrlKey) && copyKeys.has(e.key)) {
        return;
      }

      e.preventDefault();
    }
  }
}

@Directive({
  selector: '[OnlyNumberAndSpace]',
})
export class OnlyNumberAndSpaceDirective {
  @Input() OnlyNumberAndSpace: boolean;

  constructor(private readonly el: ElementRef) {}

  @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent) {
    const e = event;
    if (this.OnlyNumberAndSpace) {
      if (allowedKeys.has(e.key) || e.key === ' ') {
        return;
      }

      if ((e.metaKey || e.ctrlKey) && copyKeys.has(e.key)) {
        return;
      }

      e.preventDefault();
    }
  }
}

const copyKeys = new Set(['a', 'c', 'v']);

const allowedKeys = new Set([
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '0',
  'Backspace',
  'Clear',
  'Delete',
  'End',
  'ArrowLeft',
  'ArrowRight',
  '+',
  'Tab',
]);
