import { PluginService as Api } from '../../../api/gen';
import { map, Observable } from 'rxjs';
import { Credentials } from '../../model/plugin/credentials';
import { GetPluginCredentialsRequest } from '../../model/plugin/request/get-plugin-credentials-request';
import { Injectable } from '@angular/core';

@Injectable()
export class PluginService {
  constructor(private readonly pluginService: Api) {}

  getCredentials(
    externalTenantId: string,
    externalUserId: string,
  ): Observable<Credentials> {
    const request = {
      externalTenantId: externalTenantId,
      externalUserId: externalUserId,
    } as GetPluginCredentialsRequest;
    return this.pluginService
      .pluginCredentialsPost(request)
      .pipe(map((dto) => Credentials.fromDto(dto)));
  }
}
