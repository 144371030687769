<mat-expansion-panel
  class="expansion-panel mat-elevation-z0"
  (opened)="panelOpenState = true"
  (closed)="panelOpenState = false"
>
  <mat-expansion-panel-header class="expansion-panel-header">
    <mat-panel-title i18n class="title"> Einladung </mat-panel-title>
    <mat-panel-description>
      <span>{{ selectedInvitationTemplate?.title }}</span>
    </mat-panel-description>
  </mat-expansion-panel-header>

  <div class="expansion-panel-content">
    <mat-form-field>
      <mat-label i18n>Vorlage</mat-label>
      <mat-select
        [(value)]="selectedInvitationTemplateId"
        (selectionChange)="onTemplateSelectionChanged($event)"
      >
        @for (template of invitationTemplates(); track template.id) {
          <mat-option value="{{ template.id }}">{{
            template.title
          }}</mat-option>
        }
      </mat-select>
    </mat-form-field>

    <app-invitation-preview
      [isLoading]="isLoading"
      [invitationText]="invitationText"
    ></app-invitation-preview>
  </div>
</mat-expansion-panel>
