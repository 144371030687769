/**
 * Agent Client
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type TimelineEventType = 'InquiryCreated' | 'AppointmentSuggested' | 'AppointmentAccepted' | 'CallFinished' | 'Expired' | 'Canceled' | 'Finished' | 'InstantInquiryCreated';

export const TimelineEventType = {
    InquiryCreated: 'InquiryCreated' as TimelineEventType,
    AppointmentSuggested: 'AppointmentSuggested' as TimelineEventType,
    AppointmentAccepted: 'AppointmentAccepted' as TimelineEventType,
    CallFinished: 'CallFinished' as TimelineEventType,
    Expired: 'Expired' as TimelineEventType,
    Canceled: 'Canceled' as TimelineEventType,
    Finished: 'Finished' as TimelineEventType,
    InstantInquiryCreated: 'InstantInquiryCreated' as TimelineEventType
};

