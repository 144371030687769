import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Clipboard } from '@angular/cdk/clipboard';
import { FoundText } from '../../../model/attachment/found-text';

@Component({
  selector: 'app-ocr-result-dialog',
  templateUrl: './ocr-result-dialog.component.html',
  styleUrls: ['./ocr-result-dialog.component.scss'],
})
export class OcrResultDialogComponent {
  displayedColumns: string[] = ['text', 'copy'];
  dataSource = this.data.foundTexts;

  constructor(
    private clipboard: Clipboard,
    private snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<OcrResultDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: OcrResultDialogData,
  ) {}

  copyElementToClipboard(element: FoundText) {
    this.copyToClipboard(element.text);
  }

  copyEverythingToClipboard() {
    this.copyToClipboard(this.getCompleteOcrText());
  }

  private getCompleteOcrText(): string {
    const finalText = [];

    for (const element of this.data.foundTexts) {
      finalText.push(element.text);
      finalText.push('\n');
    }

    return finalText.join('');
  }

  private copyToClipboard(text: string): void {
    const pending = this.clipboard.beginCopy(text);

    let remainingAttempts = 3;
    const attempt = () => {
      const result = pending.copy();
      if (!result && --remainingAttempts) {
        setTimeout(attempt);
      } else if (!result) {
        this.snackBar.open(
          $localize`Fehler beim Kopieren in die Zwischenablage!`,
          null,
          { duration: 5000 },
        );
      } else {
        this.snackBar.open($localize`In die Zwischenablage kopiert!`, null, {
          duration: 5000,
        });
        pending.destroy();
      }
    };
    attempt();
  }
}

export interface OcrResultDialogData {
  foundTexts: FoundText[];
}
