<div class="headline">
  <div class="inquiry-detail-header">
    <app-back-button></app-back-button>
    <div class="inquiry-detail-header-text">
      <span class="page-header ns" i18n>Termindetails</span>
      <span
        class="page-header ns"
        *ngIf="inquiry?.firstName || inquiry?.lastName"
        >-</span
      >
      <span
        class="page-header page-header-bold ns"
        *ngIf="inquiry?.firstName || inquiry?.lastName"
      >
        {{ inquiry?.firstName }} {{ inquiry?.lastName }}
      </span>
    </div>

    <span class="page-header-short" i18n> Details </span>
    <app-status-tag
      class="page-header-status-tag"
      [status]="inquiry?.state"
      [autoDeletionDate]="inquiry?.autoDeletionScheduledAt"
    ></app-status-tag>
  </div>
  <div>
    <div class="inquiry-detail-header-action-container">
      <button
        class="delete-appointment-button"
        mat-flat-button
        color="warn"
        (click)="deleteInquiry(inquiry)"
        *ngIf="
          inquiry &&
          inquiry.state !== InquiryState.Open &&
          !inquiry.manuallyDeletedAt &&
          !inquiry.autoDeletedAt
        "
      >
        <mat-icon class="icon-padding-left">delete</mat-icon>
        <span i18n>Termin Löschen</span>
      </button>
      <button
        mat-flat-button
        color="primary"
        (click)="redial()"
        *ngIf="inquiry && inquiry?.state !== InquiryState.Open"
      >
        <mat-icon class="icon-padding-left">call</mat-icon>
        <span i18n>Neuer Anruf</span>
      </button>
    </div>

    <div
      class="inquiry-detail-header-action-container"
      *ngIf="
        inquiry?.state === InquiryState.Open && inquiry?.appointmentScheduledFor
      "
    >
      <button (click)="cancelCall()" color="warn" mat-raised-button>
        <mat-icon>close</mat-icon>
        <span i18n>Beenden</span>
      </button>
      <button (click)="joinCall()" color="primary" mat-raised-button>
        <mat-icon>call</mat-icon>
        <span i18n>Beitreten</span>
      </button>
    </div>
  </div>
</div>
