export type Department =
  | 'None'
  | 'Sales'
  | 'Technology'
  | 'Procurement'
  | 'Marketing'
  | 'Support'
  | 'Management';

export const Department = {
  None: 'None' as Department,
  Sales: 'Sales' as Department,
  Technology: 'Technology' as Department,
  Procurement: 'Procurement' as Department,
  Marketing: 'Marketing' as Department,
  Support: 'Support' as Department,
  Management: 'Management' as Department,
};

export class DepartmentOption {
  displayName: string;
  department: Department;

  constructor(displayName: string, department: Department) {
    this.displayName = displayName;
    this.department = department;
  }

  public static getOptions(): DepartmentOption[] {
    return [
      new DepartmentOption($localize`Kein Department`, Department.None),
      new DepartmentOption($localize`Verkauf`, Department.Sales),
      new DepartmentOption($localize`Entwicklung`, Department.Technology),
      new DepartmentOption($localize`Einkauf`, Department.Procurement),
      new DepartmentOption($localize`Marketing`, Department.Marketing),
      new DepartmentOption($localize`Support`, Department.Support),
      new DepartmentOption($localize`Management`, Department.Management),
    ];
  }

  public static getOption(department: Department) {
    const departments = this.getOptions();
    return departments.find((d) => d.department === department);
  }
}
