import { Clipboard } from '@angular/cdk/clipboard';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatStepper } from '@angular/material/stepper';
import { AnimationOptions } from 'ngx-lottie';
import { filter, first, map, Subject, takeUntil } from 'rxjs';
import {
  InvitationStatus,
  InvitationStatusService,
} from '../services/invitation-status.service';
import { VideoChatService } from '../services/videochat.service';

@Component({
  selector: 'app-invitation-status',
  templateUrl: './invitation-status.component.html',
  styleUrls: ['./invitation-status.component.scss'],
  providers: [InvitationStatusService],
})
export class InvitationStatusComponent implements OnInit, OnDestroy {
  private readonly sending_animation_path = 'assets/animations/sending.json';
  private readonly delivered_animation_path =
    'assets/animations/delivered.json';
  private readonly clicked_animation_path = 'assets/animations/clicked.json';
  private readonly error_animation_path = 'assets/animations/error.json';

  @ViewChild('stepper') private stepper: MatStepper;

  private readonly unsubscribe$ = new Subject<void>();
  protected invitationStatus = InvitationStatus.Sent;

  protected InvitationStatus = InvitationStatus;

  private readonly sendingAnimationConfig: AnimationOptions = {
    path: this.sending_animation_path,
    loop: true,
  };

  private readonly deliveredAnimationConfig: AnimationOptions = {
    path: this.delivered_animation_path,
    loop: false,
  };

  private readonly clickedAnimationConfig: AnimationOptions = {
    path: this.clicked_animation_path,
    loop: true,
  };

  private readonly errorAnimationConfig: AnimationOptions = {
    path: this.error_animation_path,
    loop: true,
  };

  protected currentAnimationConfig = this.sendingAnimationConfig;

  constructor(
    private readonly snackBar: MatSnackBar,
    private readonly clipboard: Clipboard,
    private readonly invitationStatusService: InvitationStatusService,
    private readonly videoChatService: VideoChatService,
  ) {}

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnInit(): void {
    this.videoChatService.activeRoom$
      .pipe(
        filter((x) => !!x.data.inquiryIdentifier),
        map((x) => x.data.inquiryIdentifier),
        first(),
      )
      .subscribe((inquiryId) => {
        this.invitationStatusService
          .subscribeToInvitationStatus(inquiryId)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe((invitationStatus) => {
            this.invitationStatus = invitationStatus ?? InvitationStatus.Sent;

            if (this.invitationStatus !== InvitationStatus.Error) {
              switch (this.invitationStatus) {
                case InvitationStatus.Clicked:
                  this.stepper.selectedIndex = 2;
                  this.currentAnimationConfig = this.clickedAnimationConfig;
                  break;
                case InvitationStatus.Delivered:
                  this.stepper.selectedIndex = 1;
                  this.currentAnimationConfig = this.deliveredAnimationConfig;
                  break;
                default:
                case InvitationStatus.Sent:
                  this.stepper.selectedIndex = 0;
                  this.currentAnimationConfig = this.sendingAnimationConfig;
                  break;
              }

              this.stepper.steps
                .toArray()
                .slice(0, this.stepper.selectedIndex)
                .forEach((step) => {
                  step.completed = true;
                });
            } else {
              this.stepper.selectedIndex = 0;
              this.currentAnimationConfig = this.errorAnimationConfig;
            }
          });
      });
  }
}
