import { Component } from '@angular/core';

@Component({
  selector: 'app-devices-forbidden-dialog',
  templateUrl: './devices-forbidden-dialog.component.html',
  styleUrls: ['./devices-forbidden-dialog.component.scss'],
})
export class DevicesForbiddenDialogComponent {
  constructor() {}

  reload(): void {
    location.reload();
  }
}
