import {
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Subject, take } from 'rxjs';
import { filter, map, mergeMap, takeUntil } from 'rxjs/operators';
import { Mode } from '../../../annotations/drawing-canvas/drawing-canvas.component';
import { DateFormatService } from '../../../services/util/date-format.service';
import {
  AppointmentAttachmentsService,
  CallAttachment,
  CurrentAttachmentData,
} from '../../services/appointment-attachments.service';
import { CallCtrlService } from '../../services/call-ctrl.service';
import { VideoChatService } from '../../services/videochat.service';
import { MatDialog } from '@angular/material/dialog';
import {
  EditAttachmentDialogData,
  EditAttachmentNoteDialogComponent,
} from '../../../shared/dialogs/edit-attachment-note-dialog/edit-attachment-note-dialog.component';
import { AttachmentWithState } from '../../../scheduling/inquiry-details/attachments/data/attachment-with-state';
import { AttachmentContentType } from '../../../model/attachment/attachment-content-type';
import { AttachmentState } from '../../../scheduling/inquiry-details/attachments/data/attachment-state';

@Component({
  selector: 'app-appointment-attachments',
  templateUrl: './appointment-attachments.component.html',
  styleUrls: ['./appointment-attachments.component.scss'],
  providers: [DateFormatService],
})
export class AppointmentAttachmentsComponent implements OnInit, OnDestroy {
  @ViewChild('attachmentContainer') attachmentContainer: ElementRef;

  @HostListener('window:paste', ['$event']) onPaste(event: ClipboardEvent) {
    if (event && event.clipboardData.files.length > 0) {
      this.uploadFiles(Array.from(event.clipboardData.files));
    }
  }

  public attachments: CallAttachment[] = [];
  public uploadingAttachments: AttachmentWithState[] = [];
  private readonly unsubscribe$ = new Subject<void>();

  constructor(
    private readonly dialog: MatDialog,
    private readonly attachmentService: AppointmentAttachmentsService,
    private readonly videoChatService: VideoChatService,
    protected readonly callCtrlService: CallCtrlService,
    protected readonly dateFormatService: DateFormatService,
  ) {}

  ngOnInit(): void {
    const attachments$ = this.attachmentService.attachments$.pipe(
      takeUntil(this.unsubscribe$),
    );

    const pendingAttachments$ = this.attachmentService.pendingAttachments$.pipe(
      takeUntil(this.unsubscribe$),
    );

    attachments$.subscribe((attachments) => {
      this.attachments = attachments;
    });

    pendingAttachments$.pipe(filter((x) => !!x)).subscribe((attachments) => {
      this.uploadingAttachments = attachments.filter(
        (a) => a.state === AttachmentState.UPLOADING,
      );
    });

    // TODO: Move this to attachment service
    // TODO: For real, this should not be here
    this.videoChatService.activeRoom$
      .pipe(
        filter((x) => !!x),
        take(1),
      )
      .subscribe((room) => {
        if (room?.data?.inquiryIdentifier) {
          attachments$
            .pipe(
              filter((x) => !!x?.length),
              take(1),
              mergeMap((x) =>
                this.attachmentService
                  .checkIfInPresentationMode(room.data.inquiryIdentifier)
                  .pipe(
                    filter((y) => !!y),
                    map((y) => {
                      return {
                        attachments: x,
                        presented: y,
                      };
                    }),
                  ),
              ),
            )
            .subscribe((x) => {
              if (this.attachments) this.attachments = x.attachments;
              this.openAttachment(x.presented, true).then();
            });
        }
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  protected trackImageBy(index: number, item: CallAttachment): string {
    if (!item) return null;
    return item.id;
  }

  protected async deleteAttachment(attachmentId: string) {
    await this.attachmentService.deleteAttachment(attachmentId);
    await this.attachmentService.refreshAttachmentsForAppointment();
  }

  protected async openAttachment(
    file: CallAttachment,
    isInPresentationMode = false,
  ) {
    // Maybe use an "allowSelection" Input instead
    this.callCtrlService.modeChannel$.pipe(take(1)).subscribe((mode) => {
      if (mode !== Mode.Annotate_Attachment) {
        const index = this.attachments.findIndex((x) => x.id === file.id);

        this.attachmentService.changeCurrentAttachment({
          attachments: this.attachments,
          currentIndex: index,
          canBePresented: true,
          isInPresentationMode: isInPresentationMode,
        } as CurrentAttachmentData);
      }
    });
  }

  uploadFileList(allFiles: FileList): void {
    this.uploadFiles(Array.from(allFiles));
  }

  uploadFiles(allFiles: File[]): void {
    this.attachmentService.uploadFiles(allFiles);
  }

  editAttachmentNotes(attachmentIdentifier: string) {
    const attachment = this.attachments.find(
      (a) => a.id === attachmentIdentifier,
    );

    if (attachment) {
      const dialogRef = this.dialog.open(EditAttachmentNoteDialogComponent, {
        data: {
          attachmentIdentifier: attachmentIdentifier,
          attachmentName: attachment.name,
        } as EditAttachmentDialogData,
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.attachmentService.refreshAttachmentsForAppointment();
        }
      });
    }
  }

  protected readonly AttachmentContentType = AttachmentContentType;
  protected readonly open = open;
}
