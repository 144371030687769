import { UpdateBrandingInvitation } from '../../../../../api/gen';

export class UpdateBrandingInvitationRequest {
  id?: string;
  title?: string | null;
  text?: string | null;
  isDefault?: boolean;

  static toRequest(
    request: UpdateBrandingInvitationRequest,
  ): UpdateBrandingInvitation {
    return {
      id: request.id,
      title: request.title,
      text: request.text,
      isDefault: request.isDefault,
    } as UpdateBrandingInvitation;
  }
}
