<div class="profile-header">
  <div class="profile-gradient mat-elevation-z2"></div>
  <div class="profile-image">
    <div class="clipped-background"></div>
    <img
      class="clipped-image"
      src="{{
        (employeeInformation$ | async)?.avatarPath ??
          'assets/images/default_profile_icon.svg'
      }}"
      (click)="$event.stopPropagation(); avatarUpload.click()"
    />
  </div>
</div>

<div class="page-container">
  <span class="sub-title ns" i18n>Persönliche Informationen</span>

  <div *ngIf="loading$ | async; else section" class="loading-spinner-container">
    <mat-spinner></mat-spinner>
  </div>
  <ng-template #section>
    <div class="section-container">
      <app-personal-information
        [employee]="employeeInformation$ | async"
      ></app-personal-information>
    </div>
  </ng-template>
</div>

<div>
  <input
    #avatarUpload
    (change)="newAvatarSelected($event)"
    accept="image/*"
    hidden
    type="file"
  />
</div>
