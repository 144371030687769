<div class="inquiry-details-header">
  <mat-icon>{{ icon }}</mat-icon>
  <span class="inquiry-details-header-text ns">{{ title }}</span>
  <span class="spacer"></span>
  <div *ngIf="actionTitle">
    <button
      mat-flat-button
      color="primary"
      (click)="onActionClicked()"
      [disabled]="!actionEnabled"
    >
      <mat-icon *ngIf="actionIcon">{{ actionIcon }}</mat-icon>
      <span>{{ actionTitle }}</span>
    </button>
  </div>
</div>
