import { Injectable } from '@angular/core';
import { CompanyLoginService as CompanyLoginApi } from '../../../api/gen';
import { map, Observable } from 'rxjs';
import { CompanyLoginInfo } from '../../model/company-login/company-login-info';

@Injectable()
export class CompanyLoginService {
  constructor(private readonly companyLoginService: CompanyLoginApi) {}

  getCompanyLoginInformation(): Observable<CompanyLoginInfo> {
    return this.companyLoginService
      .companyLoginGet()
      .pipe(map((dto) => CompanyLoginInfo.fromDto(dto)));
  }
}
