export function sameDay(date1: string | Date, date2: string | Date) {
  const day1 = new Date(date1);
  const day2 = new Date(date2);

  return (
    day1.getFullYear() === day2.getFullYear() &&
    day1.getMonth() === day2.getMonth() &&
    day1.getDate() === day2.getDate()
  );
}
