import { CompanyAddressDto } from '../../../api/gen';

export class CompanyAddress {
  firstAddressLine?: string | null;
  secondAddressLine?: string | null;
  locality?: string | null;
  postCode?: string | null;
  region?: string | null;
  countryCode?: string | null;

  static fromDto(dto: CompanyAddressDto): CompanyAddress {
    return {
      firstAddressLine: dto.firstAddressLine,
      secondAddressLine: dto.secondAddressLine,
      locality: dto.locality,
      postCode: dto.postCode,
      region: dto.region,
      countryCode: dto.countryCode,
    } as CompanyAddress;
  }
}
