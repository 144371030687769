import { InquiryDto, InquiryState } from '../../../api/gen';
import { Invitation } from './invitation';

export class Inquiry {
  inquiryIdentifier: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  street: string;
  houseNumber: string;
  zipCode: string;
  city: string;
  reCaptchaToken: string;
  notes: string;
  startInstant: boolean;
  state: InquiryState;
  scheduledFor: string;
  scheduledForConfirmed: string;
  scheduledForDeclined: string;
  assignedAgent: string;
  invitation?: Invitation;
  autoDeletionScheduledAt?: string | null;
  autoDeletedAt?: string | null;
  manuallyDeletedAt?: string | null;
  manuallyDeletedBy?: string | null;
  tenantId?: string | null;

  static fromDto(dto: InquiryDto): Inquiry {
    return {
      inquiryIdentifier: dto.inquiryIdentifier,
      firstName: dto.firstName,
      lastName: dto.lastName,
      email: dto.email,
      phoneNumber: dto.phoneNumber,
      street: dto.street,
      houseNumber: dto.houseNumber,
      zipCode: dto.zipCode,
      city: dto.city,
      reCaptchaToken: dto.reCaptchaToken,
      notes: dto.notes,
      startInstant: dto.startInstant,
      state: dto.state,
      scheduledFor: dto.scheduledFor,
      scheduledForConfirmed: dto.scheduledForConfirmed,
      scheduledForDeclined: dto.scheduledForDeclined,
      assignedAgent: dto.assignedAgent,
      invitation: dto.invitation ? Invitation.fromDto(dto.invitation) : null,
      autoDeletionScheduledAt: dto.autoDeletionScheduledAt,
      autoDeletedAt: dto.autoDeletedAt,
      manuallyDeletedAt: dto.manuallyDeletedAt,
      manuallyDeletedBy: dto.manuallyDeletedBy,
      tenantId: dto.tenantId,
    } as Inquiry;
  }
}
