import { ReportDto } from 'projects/helper-client/src/api/gen';
import { ReportType } from './report-type';

export class Report {
  id?: string;
  inquiryIdentifier?: string;
  containerName?: string | null;
  fileName?: string | null;
  filePath?: string | null;
  blobUrl?: string | null;
  reportType?: ReportType;
  createdAt?: string;
  static fromDto(dto: ReportDto): Report {
    return {
      id: dto.id,
      inquiryIdentifier: dto.inquiryIdentifier,
      containerName: dto.containerName,
      fileName: dto.fileName,
      filePath: dto.filePath,
      blobUrl: dto.blobUrl,
      reportType: dto.reportType,
      createdAt: dto.createdAt,
    } as Report;
  }
}
