import { Component, Input } from '@angular/core';
import { AidarPermissionStatus } from '../../services/permission.service';

@Component({
  selector: 'app-user-stats-card',
  templateUrl: './user-stats-card.component.html',
  styleUrls: ['./user-stats-card.component.scss'],
})
export class UserStatsCardComponent {
  @Input()
  userName: string;
  @Input()
  value: number;
  @Input()
  cameraDisabled: boolean;
  @Input()
  micDisabled: boolean;
  @Input()
  cameraPermission: AidarPermissionStatus;
  @Input()
  microphonePermission: AidarPermissionStatus;
  @Input()
  camerasAvailable: boolean;
  @Input()
  microphonesAvailable: boolean;

  protected allPermissionsGiven() {
    return (
      this.isPermissionGiven(this.cameraPermission) &&
      this.isPermissionGiven(this.microphonePermission)
    );
  }

  protected devicesAvailable(): boolean {
    return this.camerasAvailable && this.microphonesAvailable;
  }

  protected isPermissionGiven(permission: AidarPermissionStatus) {
    return (
      permission === AidarPermissionStatus.Prompt ||
      permission === AidarPermissionStatus.Granted
    );
  }

  protected getMissingDeviceText(): string {
    if (!this.camerasAvailable && !this.microphonesAvailable)
      return $localize`Keine Kamera und kein Mikrofon angeschlossen`;
    if (!this.camerasAvailable) return $localize`Keine Kamera angeschlossen`;
    return $localize`Kein Mikrofon angeschlossen`;
  }
}
