import { Injectable } from '@angular/core';
import { distinct, Observable, ReplaySubject } from 'rxjs';
import { getPlatform, Platform } from '../../../detectPlatform';
import { DeviceAvailabilityService } from './device-availability.service';
import { Platform as CdkPlatform } from '@angular/cdk/platform';
import { DevicePermission } from '../../../api/gen';

@Injectable()
export class PermissionService {
  private currentMicStatus: AidarPermissionStatus =
    AidarPermissionStatus.Unknown;
  private currentCamStatus: AidarPermissionStatus =
    AidarPermissionStatus.Unknown;

  private readonly permissionChanges$ = new ReplaySubject<PermissionUpdate>(1);

  // Maybe we should use this: https://github.com/helenamerk/mic-check/blob/main/lib/requestMediaPermissions.js
  constructor(
    private readonly platform: CdkPlatform,
    private readonly deviceAvailabilityService: DeviceAvailabilityService,
  ) {
    this.deviceAvailabilityService.devicesAvailable$
      .pipe(distinct())
      .subscribe((x) => {
        this.fetchPermission(x.microphone, x.camera);
      });
  }

  private fetchPermission(audio: boolean, video: boolean) {
    if (!audio && !video) {
      console.log('Neither audio nor video device available');
      return;
    }
    navigator.mediaDevices
      .getUserMedia({ audio: audio, video: video })
      .then((stream) => {
        // Cleanup track again, just to be sure
        stream.getTracks().forEach((track: MediaStreamTrack) => {
          track.stop();
        });
        this.permissionSuccess();
      })
      .catch((_) => {
        // This is needed in order to check if a permission was just denied on the "prompt" dialog or if
        // it they are denied on "app" / system level (and thus the user needs to give e.g. safari camera and mic
        // permissions in the system settings)
        // Initially, I wanted to check via the permissions api first (if available), and in case
        // a) Granted => nothing to do
        // b) Prompt => use getUserMedia to enforce decision
        // c) Denied => System level denied, as we do not even prompt
        // However, some folks at Apple decided that it is best to implement system level denied as follows:
        // query Permission gives prompt, enforcing it gives denied (same as when really prompting)
        // Yet, I found out that if we enforce it first and THEN use navigator query permission, we get 'prompt' in case
        // the user denied the permission in the dialog, and 'denied' if it is denied on system level
        // TLDR; Dont query via navigator first and use getUserMedia if needed, as in that case we cannot detect
        // system level blocked permissions on ios
        // By the way: On android the navigator query only gives us the users choice. However, even if we get granted,
        // this could still mean that it is denied on system level as it only reflects the users choice
        // at least, chrome on android gets us to the system settings, so we can leave our default instructions.
        if (getPlatform() === Platform.iOS && this.permissionApiSupported()) {
          const checkCamera = navigator['permissions'].query({
            name: 'camera' as PermissionName,
          });
          const checkMic = navigator['permissions'].query({
            name: 'microphone' as PermissionName,
          });
          Promise.all([checkCamera, checkMic]).then((x) => {
            if (x[0].state === 'granted' || x[1].state === 'granted') {
              this.permissionSuccess();
            } else if (x[0].state === 'denied' || x[1].state === 'denied') {
              this.permissionDeniedSystem();
            } else {
              this.permissionDenied();
            }
          });
        } else {
          this.permissionDenied();
        }
        this.permissionDenied();
      });
  }

  private permissionSuccess() {
    this.currentMicStatus = AidarPermissionStatus.Granted;
    this.currentCamStatus = AidarPermissionStatus.Granted;
    this.listenToCameraPermissionChange();
    this.listenToMicrophonePermissionChange();
    this.triggerUpdate();
  }

  private permissionDenied() {
    this.currentMicStatus = AidarPermissionStatus.Denied;
    this.currentCamStatus = AidarPermissionStatus.Denied;
    this.triggerUpdate();
  }

  private permissionDeniedSystem() {
    this.currentMicStatus = AidarPermissionStatus.DeniedOnSystemLevel;
    this.currentCamStatus = AidarPermissionStatus.DeniedOnSystemLevel;
    this.triggerUpdate();
  }

  public observeChanges(): Observable<PermissionUpdate> {
    return this.permissionChanges$.asObservable();
  }

  private listenToCameraPermissionChange() {
    if (this.permissionApiSupported()) {
      const permissionName = 'camera' as PermissionName;
      navigator['permissions']
        .query({
          name: permissionName,
        })
        .then((result) => {
          result.onchange = () => {
            this.currentCamStatus = this.castState(result?.state);
            this.triggerUpdate();
          };
        });
    }
  }

  private listenToMicrophonePermissionChange() {
    if (this.permissionApiSupported()) {
      const permissionName = 'microphone' as PermissionName;
      navigator['permissions']
        .query({
          name: permissionName,
        })
        .then((result) => {
          result.onchange = () => {
            this.currentMicStatus = this.castState(result?.state);
            this.triggerUpdate();
          };
        });
    }
  }

  private castState(state: PermissionState): AidarPermissionStatus {
    if (state == 'granted') {
      return AidarPermissionStatus.Granted;
    } else if (state == 'denied') {
      return AidarPermissionStatus.Denied;
    } else if (state == 'prompt') {
      return AidarPermissionStatus.Prompt;
    }
    return AidarPermissionStatus.PermissionApiUnavailable;
  }

  private triggerUpdate() {
    this.permissionChanges$.next(
      new PermissionUpdate(this.currentMicStatus, this.currentCamStatus),
    );
  }

  private permissionApiSupported() {
    return (
      navigator &&
      navigator['permissions'] &&
      navigator['permissions'].query &&
      // https://stackoverflow.com/questions/53147944/firefox-permission-name-member-of-permissiondescriptor-camera-is-not-a-vali
      !this.platform.FIREFOX
    );
  }
}

export class PermissionUpdate {
  constructor(
    public readonly currentMicStatus: AidarPermissionStatus,
    public readonly currentCamStatus: AidarPermissionStatus,
  ) {}

  isMicGiven(): boolean {
    return AidarPermissionStatus.isGiven(this.currentMicStatus);
  }

  isCamGiven(): boolean {
    return AidarPermissionStatus.isGiven(this.currentCamStatus);
  }
}

export enum AidarPermissionStatus {
  Denied = 0,
  Granted = 1,
  Prompt = 2,
  PermissionApiUnavailable = 3,
  Unknown = 4,
  DeniedOnSystemLevel,
}

export namespace AidarPermissionStatus {
  export function isGiven(status: AidarPermissionStatus): boolean {
    return (
      status === AidarPermissionStatus.Prompt ||
      status === AidarPermissionStatus.Granted
    );
  }
  export function castToDto(status: AidarPermissionStatus): DevicePermission {
    switch (status) {
      case AidarPermissionStatus.DeniedOnSystemLevel:
        return DevicePermission.DeniedOnSystemLevel;
      case AidarPermissionStatus.Denied:
        return DevicePermission.Denied;
      case AidarPermissionStatus.Granted:
        return DevicePermission.Granted;
      case AidarPermissionStatus.PermissionApiUnavailable:
        return DevicePermission.PermissionApiUnavailable;
      case AidarPermissionStatus.Prompt:
        return DevicePermission.Prompt;
      case AidarPermissionStatus.Unknown:
      default:
        return DevicePermission.Unknown;
    }
  }
}
