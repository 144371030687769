import { Component, OnDestroy, OnInit } from '@angular/core';
import { VideoChatService } from '../../services/videochat.service';
import { filter, map, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppointmentAttachmentsService } from '../../services/appointment-attachments.service';
import { UnreadMessagesService } from '@mad-tech/aidar-live-chat';
import { MatTabChangeEvent } from '@angular/material/tabs';

@Component({
  selector: 'app-attachment-panel',
  templateUrl: './attachment-panel.component.html',
  styleUrls: ['./attachment-panel.component.scss'],
})
export class AttachmentPanelComponent implements OnInit, OnDestroy {
  private readonly unsubscribe$ = new Subject<void>();
  protected inquiryId$: Observable<string>;
  protected activeIndex = 0;
  protected attachmentCount$ = new Observable<number>();
  protected unreadMessagesCount$ = new Observable<number>();

  public liveChatTabSelected = false;
  public liveChatEnabled = false;

  constructor(
    protected readonly videoChatService: VideoChatService,
    private readonly attachmentService: AppointmentAttachmentsService,
    private readonly unreadMessagesService: UnreadMessagesService,
  ) {}

  ngOnInit() {
    this.videoChatService.activeRoom$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((x) => {
        this.liveChatEnabled = x?.data?.liveChatTranslationsEnabled ?? false;
      });
    this.inquiryId$ = this.videoChatService.activeRoom$.pipe(
      takeUntil(this.unsubscribe$),
      map((x) => x?.data?.inquiryIdentifier),
      filter((x) => !!x),
    );
    this.attachmentCount$ = this.attachmentService.attachments$.pipe(
      takeUntil(this.unsubscribe$),
      map((x) => x?.length ?? 0),
    );
    this.unreadMessagesCount$ = this.unreadMessagesService
      .onUnreadMessagesChanged()
      .pipe(takeUntil(this.unsubscribe$));
    this.unreadMessagesService.startCounting();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  tabChanged(changeEvent: MatTabChangeEvent) {
    const index = changeEvent.index;
    localStorage.setItem('index', index.toString());
    this.activeIndex = index;

    if (this.liveChatEnabled) {
      if (changeEvent.tab.ariaLabelledby === 'chat-tab') {
        this.unreadMessagesService.stopCounting();
        this.liveChatTabSelected = true;
      } else {
        this.unreadMessagesService.startCounting();
        this.liveChatTabSelected = false;
      }
    }
  }
}
