import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { BehaviorSubject, debounceTime } from 'rxjs';
import { formatIncompletePhoneNumber, getCountries } from 'libphonenumber-js';
import { CountryDataService, CountryPhoneData } from './country-data.service';
import { isValidPhoneNumber } from 'libphonenumber-js/max';
import { MatSelectionListChange } from '@angular/material/list';
import { constants } from '../../../environments/constants';
import { CountryFilterService } from './country-filter.service';

@Component({
  selector: 'app-phone-number-input',
  templateUrl: './phone-number-input.component.html',
  styleUrls: ['./phone-number-input.component.scss'],
  providers: [CountryFilterService],
})
export class PhoneNumberInputComponent implements OnInit {
  // TODO: Make this component usable in default form fields: see https://material.angular.io/guide/creating-a-custom-form-field-control

  @Input() favoriteCountryCodeSet = new Set<string>(['AT', 'DE', 'CH']);
  @Input() phoneNumberDisplaySize: PhoneNumberInputDisplaySize =
    PhoneNumberInputDisplaySize.Normal;
  @Output() phoneNumberChanged = new EventEmitter<string>();
  @Output() phoneNumberVerifiedChanged = new EventEmitter<boolean>();

  @Output() enterKeyPressed = new EventEmitter<void>();

  selectedCountry: BehaviorSubject<CountryPhoneData>;
  countrySelectActive: BehaviorSubject<boolean>;
  allCountries: CountryPhoneData[];
  favoriteCountries: CountryPhoneData[];
  remainingCountries: CountryPhoneData[];

  countryFilterString: BehaviorSubject<string>;
  countriesFiltering: BehaviorSubject<boolean>;
  phoneNumberVerified: BehaviorSubject<boolean>;
  phoneNumber: BehaviorSubject<string>;

  constructor(
    private readonly countryFilterService: CountryFilterService,
    private readonly countryDataService: CountryDataService,
    private readonly cdr: ChangeDetectorRef,
  ) {
    this.countryFilterString = new BehaviorSubject<string>('');
    this.countriesFiltering = new BehaviorSubject<boolean>(false);
    this.countrySelectActive = new BehaviorSubject<boolean>(false);
    this.phoneNumberVerified = new BehaviorSubject<boolean>(false);

    this.populateCountries(this.countryFilterString.value);
    const initialSelectedCountry = this.allCountries.find(
      (entry) => entry.countryCode === 'AT',
    );
    this.selectedCountry = new BehaviorSubject<CountryPhoneData>(
      initialSelectedCountry,
    );
    this.phoneNumber = new BehaviorSubject<string>(
      this.getCurrentCallingCode(),
    );
  }

  countryCompareFunction = (o1: CountryPhoneData, o2: CountryPhoneData) =>
    o1.countryCode === o2.countryCode;

  ngOnInit() {
    this.countryFilterString
      .pipe(debounceTime(400))
      .subscribe((filterString) => {
        this.populateCountries(filterString);
        this.cdr.detectChanges();
      });

    if (window.innerWidth < constants.dimension.$sm) {
      this.phoneNumberDisplaySize = PhoneNumberInputDisplaySize.Normal;
    }
  }

  protected onCountrySelectClicked() {
    this.countryFilterString.next('');
    this.countrySelectActive.next(!this.countrySelectActive.value);
  }

  protected phoneNumberModelChanged(event: any) {
    this.phoneNumber.next(event.target.value);

    this.filterMultipleCallingCodes();
    this.filterForValidCallingCode();

    this.phoneNumber.next(formatIncompletePhoneNumber(this.phoneNumber.value));
    this.validateAndEmitPhoneNumber();
  }

  private filterMultipleCallingCodes() {
    // If phoneNumber is containing multiple calling codes,
    // only use the last one (e.g. +43+496501234567 => +496501234567)
    const phoneNumberSplits = this.phoneNumber.value.split('+');
    if (phoneNumberSplits.length > 2) {
      const lastValidSplit = `+${
        phoneNumberSplits[phoneNumberSplits.length - 1]
      }`;
      this.phoneNumber.next(lastValidSplit);
    }
  }

  private filterForValidCallingCode() {
    // If phoneNumber is not starting with the current calling code,
    // check if we start with any valid calling code
    if (!this.phoneNumber.value.startsWith(this.getCurrentCallingCode())) {
      const currentCountry = this.getPhoneDataFromNumber(
        this.phoneNumber.value,
      );
      if (currentCountry) {
        this.selectedCountry.next(currentCountry);
      } else {
        this.phoneNumber.next(this.getCurrentCallingCode());
      }
    }
  }

  protected onCountrySelectionChanged(change: MatSelectionListChange) {
    const value = change.options
      .filter((o) => o.selected)
      .map((o) => o.value)[0] as CountryPhoneData;
    this.selectedCountry.next(value);
    this.phoneNumber.next(this.getCurrentCallingCode());
    this.validateAndEmitPhoneNumber();
    this.countrySelectActive.next(false);
  }

  protected hide(_: any) {
    this.countrySelectActive.next(false);
    this.countryFilterString.next('');
  }

  private validateAndEmitPhoneNumber() {
    this.phoneNumberVerified.next(isValidPhoneNumber(this.phoneNumber.value));
    this.phoneNumberVerifiedChanged.emit(this.phoneNumberVerified.value);
    this.phoneNumberChanged.emit(this.phoneNumber.value);

    const inputElement = document.getElementById(
      'number-input',
    ) as HTMLInputElement;
    inputElement.value = this.phoneNumber.value;
  }

  private getCurrentCallingCode(): string {
    return `+${this.selectedCountry.value.countryCallingCode}`;
  }

  private populateCountries(filter: string) {
    this.countriesFiltering.next(true);
    const phoneCountryCodes = new Set<string>(getCountries());

    this.allCountries =
      this.countryDataService.getCountryPhoneData(phoneCountryCodes);

    this.favoriteCountries = this.countryFilterService
      .getFilteredCountries(this.allCountries, filter)
      .filter((e) => this.favoriteCountryCodeSet.has(e.countryCode));

    this.remainingCountries = this.countryFilterService
      .getFilteredCountries(this.allCountries, filter)
      .filter((e) => !this.favoriteCountryCodeSet.has(e.countryCode));
    this.countriesFiltering.next(false);
  }

  onSearchCountryChange(searchEvent: Event) {
    const target = searchEvent.target as HTMLInputElement;
    this.countryFilterString.next(target.value);
  }

  getNumberInputFontSize(): string {
    switch (this.phoneNumberDisplaySize) {
      case PhoneNumberInputDisplaySize.Normal:
        return '1rem';
      case PhoneNumberInputDisplaySize.Large:
        return '2rem';
    }
  }

  getTooltipText(): string {
    if (this.phoneNumber.value.startsWith('+')) {
      return $localize`Die eingegebene Nummer ist nicht korrekt.`;
    }

    return $localize`Die eingegebene Nummer muss mit einer Ländervorwahl starten (z.B. +43).`;
  }

  private getPhoneDataFromNumber(phoneNumber: string): CountryPhoneData {
    for (let country of this.allCountries) {
      const callingCode = `+${country.countryCallingCode}`;
      if (phoneNumber.startsWith(callingCode)) {
        return country;
      }
    }

    return null;
  }
}

export enum PhoneNumberInputDisplaySize {
  Normal,
  Large,
}
