import { CreateUserManually, Department } from '../../../../api/gen';

export class CreateUserManuallyRequest {
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  phoneNumber?: string | null;
  password?: string | null;
  department?: Department;

  static toDto(request: CreateUserManuallyRequest): CreateUserManually {
    return {
      firstName: request.firstName,
      lastName: request.lastName,
      email: request.email,
      phoneNumber: request.phoneNumber,
      password: request.password,
      department: request.department,
    } as CreateUserManually;
  }
}
