import { QuotaDto } from '../../api/gen';

export class Quota {
  subscriptionType?: SubscriptionType;
  tenantIdentifier?: string | null;
  externalEmployeeIdentifier?: string | null;
  callsLeftForTenant?: number | null;
  callsLeftForEmployee?: number | null;

  static fromDto(dto: QuotaDto): Quota {
    return {
      subscriptionType: dto.subscriptionType,
      tenantIdentifier: dto.tenantIdentifier,
      externalEmployeeIdentifier: dto.externalEmployeeIdentifier,
      callsLeftForTenant: dto.callsLeftForTenant,
      callsLeftForEmployee: dto.callsLeftForEmployee,
    } as Quota;
  }
}

export type SubscriptionType =
  | 'Trial'
  | 'Starter'
  | 'Pro'
  | 'Ultimate'
  | 'Individual';

export const SubscriptionType = {
  Trial: 'Trial' as SubscriptionType,
  Starter: 'Starter' as SubscriptionType,
  Pro: 'Pro' as SubscriptionType,
  Ultimate: 'Ultimate' as SubscriptionType,
  Individual: 'Individual' as SubscriptionType,
};
