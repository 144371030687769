<mat-card class="price-table-card">
  <mat-card-header>
    <mat-card-title i18n>Verfügbare Pläne</mat-card-title>
    <mat-card-subtitle i18n
      >Wählen Sie einen unserer bezahlten Pläne, um blankmile weiterhin nutzen
      zu können. Alle Pläne werden monatlich abgerechnet und können jederzeit
      innerhalb eines Monats gekündigt werden.</mat-card-subtitle
    >
  </mat-card-header>
  <mat-card-content>
    <stripe-pricing-table
      [attr.pricing-table-id]="pricingTableId"
      [attr.publishable-key]="publishableKey"
      [attr.client-reference-id]="clientReference | async"
      [attr.customer-email]="customerEmail"
    >
    </stripe-pricing-table>
  </mat-card-content>
</mat-card>
