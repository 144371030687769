import { Component } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-move-hand-overlay',
  templateUrl: './move-hand-overlay.component.html',
  styleUrls: ['./move-hand-overlay.component.scss'],
  animations: [
    trigger('fade', [
      transition(':enter', [
        style({ opacity: 1 }),
        animate(5500, style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class MoveHandOverlayComponent {}
