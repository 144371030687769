import { Injectable } from '@angular/core';
import { InquiryDashboardService as InquiryDashboardApi } from '../../../api/gen';
import { map, Observable } from 'rxjs';
import { InquiryDashboardSummary } from '../../model/inquiry/inquiry-dashboard-summary';
import { InquiryOpen } from '../../model/inquiry/InquiryOpen';

@Injectable({ providedIn: 'root' })
export class InquiryDashboardService {
  constructor(private readonly dashboardService: InquiryDashboardApi) {}

  getInquiryDetails(inquiryId: string): Observable<InquiryDashboardSummary> {
    return this.dashboardService
      .contactInquiryDashboardDetailsInquiryIdGet(inquiryId)
      .pipe(map((dto) => InquiryDashboardSummary.fromDto(dto)));
  }

  getOpenInquiries(): Observable<InquiryOpen[]> {
    return this.dashboardService
      .contactInquiryDashboardOpenMeGet()
      .pipe(map((x) => x.map((dto) => InquiryOpen.fromDto(dto))));
  }
}
