<app-heading-with-icon-and-action-menu
  i18n-title
  title="PDF Report"
  titleShort="Report"
  icon="picture_as_pdf"
  i18n-menuTitle
  menuTitle="PDF Report"
  i18n-menuTitleShort
  menuTitleShort="Report"
>
  <ng-container heading-menu-actions>
    <button
      mat-menu-item
      (click)="onClickCreateReport()"
      disabled="{{ !createReportEnabled }}"
    >
      <mat-icon>note_add</mat-icon>
      <span i18n>Standard</span>
    </button>
    <button
      mat-menu-item
      (click)="onClickCreateCustomReport()"
      disabled="{{ !createReportEnabled }}"
    >
      <mat-icon>note_alt</mat-icon>
      <span i18n>Benutzerdefiniert</span>
    </button>
  </ng-container>
</app-heading-with-icon-and-action-menu>

<div class="section-container">
  <app-info-text-with-icon
    i18n-text
    text="Hier können Sie Reports mit allen Daten des Termins erstellen."
  ></app-info-text-with-icon>

  <div>
    <table
      [dataSource]="dataSource"
      class="report-table-container mat-elevation-z2"
      mat-table
      matSort
    >
      <ng-container matColumnDef="fileName">
        <th *matHeaderCellDef mat-header-cell i18n>Dateiname</th>
        <td *matCellDef="let element" mat-cell>
          {{ element.fileName }}
        </td>
      </ng-container>

      <ng-container matColumnDef="createdAt">
        <th *matHeaderCellDef mat-header-cell mat-sort-header i18n>
          Erstellt am
        </th>
        <td *matCellDef="let element" mat-cell>
          {{ element.createdAt | date: "EEEEEE, dd.MM.yyyy HH:mm" }}
        </td>
      </ng-container>

      <ng-container matColumnDef="downloadUrl">
        <th *matHeaderCellDef class="report-download-cell" mat-header-cell i18n>
          Download
        </th>
        <td *matCellDef="let element" class="report-download-cell" mat-cell>
          <button
            (click)="onDownloadReport(element.blobUrl)"
            i18n-aria-label
            aria-label="Report herunterladen"
            color="primary"
            mat-icon-button
          >
            <mat-icon>download</mat-icon>
          </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="moreActions">
        <th *matHeaderCellDef class="report-download-cell" mat-header-cell></th>
        <td *matCellDef="let element" class="report-download-cell" mat-cell>
          <button mat-icon-button [matMenuTriggerFor]="moreActionsMenu">
            <mat-icon>more_vert</mat-icon>
          </button>

          <mat-menu #moreActionsMenu>
            <button
              [disabled]="sendToMyEmailDisabled$ | async"
              mat-menu-item
              (click)="onSendToMyEmail(element)"
              i18n
            >
              An meine E-Mail senden
            </button>
            <button mat-menu-item (click)="onRenameReport(element)" i18n>
              Umbenennen
            </button>
            <button mat-menu-item (click)="onDeleteReport(element)" i18n>
              Löschen
            </button>
          </mat-menu>
        </td>
      </ng-container>

      <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>

      <tr *matRowDef="let row; columns: displayedColumns" mat-row></tr>

      <tr *matNoDataRow class="mat-row">
        <td
          [attr.colspan]="displayedColumns.length"
          class="mat-cell no-data-row ns"
          i18n
        >
          Keine Reports.
        </td>
      </tr>
    </table>
  </div>
</div>
