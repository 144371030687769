export class AppointmentCommands {
  public static get SYNC(): string {
    return 'appointment_sync';
  }

  public static get SYNC_ATTACHMENTS(): string {
    return 'appointment_syncAttachments';
  }

  public static get HANG_UP(): string {
    return 'appointment_hangUp';
  }

  public static get IS_RECORDING(): string {
    return 'appointment_isRecording';
  }

  public static get INVITATION_STATUS_CHANGED(): string {
    return 'appointment_invitationStatusChanged';
  }

  public static get PUBLISH_NEW_CHAT_MESSAGE(): string {
    return 'appointment_newChatMessage';
  }
}
