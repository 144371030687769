import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, tap } from 'rxjs';
import { CompanyService } from '../../../api/gen';
import { CompanySettings } from '../../model/company/company-settings';
import { UpdateCompanyLogoRequest } from '../../model/company/request/update-company-logo';
import { map } from 'rxjs/operators';
import { UpdateCompanySettingsRequest } from '../../model/company/request/update-company-settings-request';

@Injectable({ providedIn: 'root' })
export class CompanySettingsService {
  private readonly companySettings$ = new ReplaySubject<CompanySettings>(1);

  constructor(private readonly companyService: CompanyService) {}

  getCompanySettings(): Observable<CompanySettings> {
    return this.companySettings$.asObservable();
  }

  loadCompanyData(): Observable<CompanySettings> {
    return this.companyService.companyGet().pipe(
      map((dto) => CompanySettings.fromDto(dto)),
      tap((companySettings) => this.companySettings$.next(companySettings)),
    );
  }

  updateCompanyData(
    request: UpdateCompanySettingsRequest,
  ): Observable<CompanySettings> {
    return this.companyService
      .companyPut(UpdateCompanySettingsRequest.toDto(request))
      .pipe(
        map((dto) => CompanySettings.fromDto(dto)),
        tap((companySettings) => this.companySettings$.next(companySettings)),
      );
  }

  updateCompanyLogo(
    request: UpdateCompanyLogoRequest,
  ): Observable<CompanySettings> {
    return this.companyService
      .companyLogoPost(UpdateCompanyLogoRequest.toDto(request))
      .pipe(
        map((dto) => CompanySettings.fromDto(dto)),
        tap((companySettings) => this.companySettings$.next(companySettings)),
      );
  }
}
