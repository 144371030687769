import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, filter, map, mergeMap, take } from 'rxjs';
import {
  EditBasicInquiryInfoDialogComponent,
  EditInquiryBaseDataFields,
} from '../../shared/dialogs/edit-basic-inquiry-info-dialog/edit-basic-inquiry-info-dialog.component';
import { VideoChatService } from '../services/videochat.service';
import { Inquiry } from '../../model/inquiry/inquiry';
import { InquiryService } from '../../services/api/inquiry.service';

@Component({
  selector: 'app-inquiry-infos',
  templateUrl: './inquiry-infos.component.html',
  styleUrls: ['./inquiry-infos.component.scss'],
  providers: [InquiryService],
})
export class InquiryInfosComponent implements OnInit {
  protected inquiry?: InquiryCallInfo;
  protected inquiry$ = new BehaviorSubject<Inquiry>(null);
  protected isLoading = true;

  constructor(
    private readonly dialog: MatDialog,
    private readonly videoChatService: VideoChatService,
    private readonly inquiryService: InquiryService,
  ) {}

  ngOnInit(): void {
    this.refresh();
  }

  private refresh(): void {
    this.isLoading = true;
    this.videoChatService.activeRoom$
      .pipe(
        filter((x) => !!x?.data?.inquiryIdentifier),
        map((x) => x.data.inquiryIdentifier),
        take(1),
        mergeMap((x) => this.inquiryService.getInquiry(x)),
      )
      .subscribe((x) => {
        this.isLoading = false;
        this.inquiry$.next(x);
        this.inquiry = {
          inquiryId: x.inquiryIdentifier,
          email: x.email,
          firstName: x.firstName,
          lastName: x.lastName,
          phoneNumber: x.phoneNumber,
          createdAt: new Date(x.scheduledFor),
        };
      });
  }

  protected editContactDetails() {
    this.dialog
      .open(EditBasicInquiryInfoDialogComponent, {
        data: {
          inquiryId: this.inquiry.inquiryId,
          phoneNumber: this.inquiry.phoneNumber,
          email: this.inquiry.email,
          firstName: this.inquiry.firstName,
          lastName: this.inquiry.lastName,
        } as EditInquiryBaseDataFields,
        maxWidth: '100vw',
        maxHeight: '100vh',
      })
      .afterClosed()
      .subscribe((shouldReload) => {
        if (shouldReload) {
          this.refresh();
        }
      });
  }
}

export interface InquiryCallInfo {
  inquiryId: string;
  phoneNumber: string;
  createdAt: Date;
  firstName?: string;
  lastName?: string;
  email?: string;
}
