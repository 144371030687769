import { NotificationCenterConnectionInfoDto } from '../../../api/gen';

export class NotificationCenterConnectionInfo {
  tenantId?: string | null;
  userId?: string | null;

  static fromDto(
    dto: NotificationCenterConnectionInfoDto,
  ): NotificationCenterConnectionInfo {
    return {
      tenantId: dto.tenantId,
      userId: dto.userId,
    } as NotificationCenterConnectionInfo;
  }
}
