<div
  class="diagnostics-info"
  *ngIf="diagnosticsObserver | async as diagnostics; else loading"
>
  <div
    class="connection-lost-container"
    *ngIf="
      diagnostics.remoteConnectionLost &&
      diagnostics.remoteParticipantConnected &&
      diagnostics.connectionState === ConnectionState.Connected
    "
  >
    <div class="workingChip chip warning">
      <mat-icon> warning_amber</mat-icon>
      <span i18n>Verbindungsproblem</span>
    </div>
    <span i18n>Probleme bei der Verbindung zum Teilnehmer</span>
  </div>
  <div
    class="connection-lost-container"
    *ngIf="diagnostics.connectionState === ConnectionState.RoomClosed"
  >
    <div class="workingChip chip waiting" i18n>Anruf beendet</div>
  </div>
  <div
    class="connection-lost-container"
    *ngIf="
      diagnostics.connectionState === ConnectionState.Connected &&
      !diagnostics.remoteParticipantConnected
    "
  >
    <div class="workingChip chip waiting">
      <mat-spinner [diameter]="25"></mat-spinner
      ><span i18n> Warte auf Teilnehmer</span>
    </div>
  </div>
  <div
    class="connection-lost-container"
    *ngIf="diagnostics.connectionState === ConnectionState.Connecting"
  >
    <div class="workingChip chip waiting">
      <mat-spinner [diameter]="25"></mat-spinner>
      <span i18n>"Verbindungsaufbau..."</span>
    </div>
  </div>

  <div class="participant-stats" *ngIf="connectionSuccessful(diagnostics)">
    <app-user-stats-card
      [value]="diagnostics.localAudioLevel"
      [cameraPermission]="diagnostics.localCameraPermissionStatus"
      [microphonePermission]="diagnostics.localMicrophonePermissionStatus"
      [cameraDisabled]="!diagnostics.localCameraTurnedOn"
      [micDisabled]="!diagnostics.localMicrophoneTurnedOn"
      [camerasAvailable]="diagnostics.localCameraDevicesAvailable"
      [microphonesAvailable]="diagnostics.localMicrophoneDevicesAvailable"
      i18n-userName="the word you capitalized"
      userName="Du"
    ></app-user-stats-card>
    <app-user-stats-card
      [value]="diagnostics.remoteAudioLevel"
      [cameraPermission]="diagnostics.remoteCameraPermissionStatus"
      [microphonePermission]="diagnostics.remoteMicrophonePermissionStatus"
      [cameraDisabled]="!diagnostics.remoteCameraTurnedOn"
      [micDisabled]="!diagnostics.remoteMicrophoneTurnedOn"
      [camerasAvailable]="true"
      [microphonesAvailable]="true"
      i18n-userName
      userName="Teilnehmer"
    ></app-user-stats-card>
  </div>
</div>

<ng-template #loading>
  <div class="loading-spinner">
    <mat-spinner [diameter]="25"></mat-spinner>
  </div>
</ng-template>
