<mat-card class="department-container">
  <app-card-header-with-action
    i18n-title
    title="Department"
    (actionClicked)="onChangeDepartment()"
  ></app-card-header-with-action>

  <div *ngIf="employee">
    <mat-list>
      <mat-list-item>
        <mat-icon matListItemIcon>business</mat-icon>
        <div matListItemTitle class="ns">
          {{ DepartmentOption.getOption(employee.department).displayName }}
        </div>
      </mat-list-item>
    </mat-list>
  </div>
</mat-card>
