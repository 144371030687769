import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DrawingCanvasComponent } from './drawing-canvas/drawing-canvas.component';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { AttachmentDialogComponent } from './dialogs/attachment-dialog/attachment-dialog.component';
import { MatButtonModule } from '@angular/material/button';
import { AnnotateImageComponent } from './annotate-image/annotate-image.component';

@NgModule({
  declarations: [
    DrawingCanvasComponent,
    AttachmentDialogComponent,
    AnnotateImageComponent,
  ],
  imports: [CommonModule, MatIconModule, MatSidenavModule, MatButtonModule],
  exports: [
    DrawingCanvasComponent,
    AttachmentDialogComponent,
    AnnotateImageComponent,
  ],
})
export class AnnotationsModule {}
