import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { InquiryDashboardSummary } from '../../../model/inquiry/inquiry-dashboard-summary';
import { BehaviorSubject } from 'rxjs';
import { EmployeeService } from '../../../services/api/employee.service';

@Component({
  selector: 'app-inquiry-details-deletion-data',
  templateUrl: './inquiry-details-deletion-data.component.html',
  styleUrls: ['./inquiry-details-deletion-data.component.scss'],
  providers: [EmployeeService],
})
export class InquiryDetailsDeletionDataComponent implements OnChanges {
  @Input() inquiry: InquiryDashboardSummary;

  private currentInquiryId: string;

  protected isLoading$ = new BehaviorSubject(false);
  protected deletedDateText = new BehaviorSubject<string>(null);
  protected deletedTimeText = new BehaviorSubject<string>(null);
  protected deletedByText = new BehaviorSubject<string>(null);

  constructor(private readonly employeeService: EmployeeService) {}

  ngOnChanges(changes: SimpleChanges): void {
    const date = this.inquiry.autoDeletedAt
      ? new Date(this.inquiry.autoDeletedAt)
      : new Date(this.inquiry.manuallyDeletedAt);

    this.deletedDateText.next(date.toLocaleDateString());
    this.deletedTimeText.next(date.toLocaleTimeString());

    if (this.currentInquiryId !== this.inquiry.inquiryIdentifier) {
      this.currentInquiryId = this.inquiry.inquiryIdentifier;

      if (this.inquiry.manuallyDeletedBy) {
        this.isLoading$.next(true);
        this.employeeService
          .getEmployeeInformationById(this.inquiry.manuallyDeletedBy)
          .subscribe({
            next: (e) => {
              this.deletedByText.next(`${e.firstName} ${e.lastName}`);
              this.isLoading$.next(false);
            },
            error: () => {
              this.isLoading$.next(false);
            },
          });
      }
    }
  }
}
