<div *ngIf="endedOnStartedDay() || !endDate">
  <span>{{ startDate | date: "HH:mm" }}</span>
  <ng-container *ngIf="endDate">
    <span> → </span><span>{{ endDate | date: "HH:mm" }}</span>
  </ng-container>
  <div class="date-cell">
    {{ startDate | date: "EEEEEE, dd.MM.yyyy" }}
  </div>
</div>
<div *ngIf="!endedOnStartedDay() && endDate" class="multi-day-duration">
  <div>
    <div>{{ startDate | date: "HH:mm" }}</div>
    <div class="date-cell">{{ startDate | date: "EEEEEE, dd.MM.yyyy" }}</div>
  </div>
  <span style="padding: 0 0.5rem"> → </span>
  <div>
    <div>{{ endDate | date: "HH:mm" }}</div>
    <div class="date-cell">{{ endDate | date: "EEEEEE, dd.MM.yyyy" }}</div>
  </div>
</div>
