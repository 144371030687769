import { RegistrationValidationDto } from '../../api/gen';

export class RegistrationValidation {
  id?: string | null;
  eMail?: string | null;
  token?: string | null;
  validTenantIdentifier?: string | null;
  emailSent?: string | null;
  activated?: boolean;
  deleted?: boolean;

  static fromDto(dto: RegistrationValidationDto): RegistrationValidation {
    return {
      id: dto.id,
      eMail: dto.eMail,
      token: dto.token,
      validTenantIdentifier: dto.validTenantIdentifier,
      emailSent: dto.emailSent,
      activated: dto.activated,
      deleted: dto.deleted,
    } as RegistrationValidation;
  }
}
