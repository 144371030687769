import { CompanySettingsDto } from '../../../api/gen';
import { CompanyAddress } from './company-address';
import { ApplicationSettings } from './application-settings';

export class CompanySettings {
  displayName?: string | null;
  logoUrl?: string | null;
  privacyUrl?: string | null;
  applicationSettings?: ApplicationSettings;
  companyAddress?: CompanyAddress;

  static fromDto(dto: CompanySettingsDto): CompanySettings {
    return {
      displayName: dto.displayName,
      logoUrl: dto.logoUrl,
      privacyUrl: dto.privacyUrl,
      applicationSettings: dto.applicationSettings
        ? ApplicationSettings.fromDto(dto.applicationSettings)
        : null,
      companyAddress: dto.companyAddress
        ? CompanyAddress.fromDto(dto.companyAddress)
        : null,
    } as CompanySettings;
  }
}
