<mat-card appearance="outlined" (click)="onNotificationClick()">
  <div class="notification-item-container">
    <mat-card-header class="notification-item-header">
      <mat-card-title class="notification-item-title">{{
        notificationTitle
      }}</mat-card-title>
      <mat-card-subtitle class="notification-item-text">{{
        notificationText
      }}</mat-card-subtitle>
    </mat-card-header>
    <mat-card-actions align="end" [matTooltip]="markAsReadTooltip">
      <button mat-icon-button (click)="onNotificationReadClick($event)">
        <mat-icon color="primary">{{ notificationIcon }}</mat-icon>
      </button>
    </mat-card-actions>
  </div>
</mat-card>
