import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TabChipData } from './inquiry-detail-component-chip';

@Component({
  selector: 'app-tab-chip',
  templateUrl: './tab-chip.component.html',
  styleUrls: ['./tab-chip.component.scss'],
})
export class TabChipComponent {
  @Input()
  tabChipData: TabChipData;

  @Output()
  chipSelectedEmitter = new EventEmitter<number>();

  selectChip() {
    this.chipSelectedEmitter.next(this.tabChipData.index);
  }
}
