import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-device-trouble',
  templateUrl: './device-trouble.component.html',
  styleUrls: ['./device-trouble.component.scss'],
})
export class DeviceTroubleComponent {
  @Input()
  title: string;
}
