import { Injectable } from '@angular/core';
import { QuotaService as QuotaApi } from '../../../api/gen';
import { map, Observable } from 'rxjs';
import { Quota } from '../../model/Quota';

@Injectable()
export class QuotaService {
  constructor(private readonly quotaService: QuotaApi) {}

  getQuota(): Observable<Quota> {
    return this.quotaService.quotaGet().pipe(map((dto) => Quota.fromDto(dto)));
  }
}
