import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SignalingService {
  private readonly hubConnection: signalR.HubConnection;

  constructor() {
    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(environment.apiPath + '/aidar-hub')
      .withAutomaticReconnect()
      .build();
  }

  public getHubConnection(): signalR.HubConnection {
    return this.hubConnection;
  }

  public async joinSignalingGroup(employeeIdentifier: string): Promise<void> {
    await this.hubConnection.start();
    await this.hubConnection
      .invoke(RemoteMethods.connect, employeeIdentifier)
      .catch(console.error);
  }

  public closeConnection() {
    this.hubConnection?.stop();
  }
}

class RemoteMethods {
  public static get connect(): string {
    return 'Connect';
  }
  public static get disconnect(): string {
    return 'Disconnect';
  }
}
