import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Employee } from '../../../model/employee/employee';
import { BehaviorSubject } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { LegalEntity } from '../../../model/legal-entity/legal-entity';
import { LegalEntityService } from '../../../services/api/legal-entity.service';
import { UpdateEmployeeRequest } from '../../../model/employee/request/update-employee-request';
import { ProfileService } from '../../../services/api/profile-service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-edit-legal-entity-dialog',
  templateUrl: './edit-legal-entity-dialog.component.html',
  styleUrls: ['./edit-legal-entity-dialog.component.scss'],
  providers: [LegalEntityService, ProfileService],
})
export class EditLegalEntityDialogComponent {
  protected readonly loading$ = new BehaviorSubject(false);
  protected employee: Employee;
  protected legalEntityForm = new FormGroup({
    legalEntity: new FormControl('', Validators.required),
  });

  protected legalEntities$ = new BehaviorSubject<LegalEntity[]>([]);

  constructor(
    @Inject(MAT_DIALOG_DATA) data: Employee,
    private readonly dialogRef: MatDialogRef<EditLegalEntityDialogComponent>,
    private readonly snackBar: MatSnackBar,
    private readonly legalEntityService: LegalEntityService,
    private readonly profileService: ProfileService,
  ) {
    this.employee = data;
    this.legalEntityForm.controls.legalEntity.setValue(
      this.employee.legalEntityId,
    );
    this.legalEntityService.getLegalEntities().subscribe((le) => {
      this.legalEntities$.next(le);
    });
  }

  onClickSaveLegalEntity() {
    this.loading$.next(true);
    const request = {
      externalIdentifier: this.employee.externalIdentifier,
      firstName: this.employee.firstName,
      lastName: this.employee.lastName,
      eMail: this.employee.email,
      department: this.employee.department,
      legalEntityId: this.legalEntityForm.controls.legalEntity.value,
    } as UpdateEmployeeRequest;

    this.profileService.updateProfile(request).subscribe((employee) => {
      this.snackBar.open(
        $localize`Standort wurde erfolgreich aktualisiert`,
        $localize`Ok`,
        {
          duration: 3_000,
        },
      );
      this.dialogRef.close(employee);
      this.loading$.next(false);
    });
  }
}
