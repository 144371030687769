<div class="signup-container">
  <div
    *ngIf="isLoading$ | async; else content"
    class="signup-additional-info-retry-container"
  >
    <mat-spinner *ngIf="(queryTokenInvalid$ | async) === false"></mat-spinner>
    <div *ngIf="queryTokenFailed$ | async" class="signup-additional-info-retry">
      <span class="signup-additional-info-retry-text" i18n
        >Informationen konnten nicht geladen werden.</span
      >
      <button
        mat-raised-button
        color="primary"
        (click)="retryLoadingToken()"
        i18n
      >
        Erneut versuchen
      </button>
    </div>

    <div
      *ngIf="queryTokenInvalid$ | async"
      class="signup-additional-info-invalid"
    >
      <img
        src="assets/images/blankmile-logo-1x.webp"
        class="signup-additional-info-invalid-logo"
        alt="blankmile Logo"
      />
      <span class="signup-additional-info-invalid-text" i18n
        >Der verwendete Token ist nicht mehr gültig. <br />Entweder er wurde
        bereits aktiviert oder Ihr Administrator hat die Einladung
        zurückgezogen.</span
      >

      <span class="signup-additional-info-invalid-support" i18n
        >Falls es sich um einen Fehler handelt, melden Sie sich unter
        <a href="mailto:support@blankmile.com">support&#64;blankmile.com</a>
      </span>
    </div>
  </div>

  <ng-template #content>
    <div class="signup-additional-info-container">
      <div class="signup-additional-info-description-container">
        <img
          class="signup-additional-info-company-logo"
          src="{{
            (companyInfo$ | async)?.logoUrl ??
              'assets/images/blankmile-logo-1x.webp'
          }}"
          i18n-alt
          alt="Das Logo Ihres Unternehmens"
        />
        <span class="signup-additional-info-description" i18n
          ><b>{{ (companyInfo$ | async)?.displayName }}</b> hat Sie eingeladen
          <b>blankmile</b> zu verwenden.<br />Ergänzen Sie hier Ihre
          Anmeldeinformationen,<br />Sie werden anschließend zur Anwendung
          weitergeleitet.</span
        >
      </div>
      <form
        [formGroup]="signUpInformationFormGroup"
        class="signup-additional-info-form"
      >
        <h1 i18n>Anmeldeinformationen vervollständigen</h1>
        <mat-form-field appearance="outline" disabled="true">
          <mat-label>Email</mat-label>
          <input formControlName="email" matInput type="text" />
        </mat-form-field>

        <div class="signup-additional-info-form-phone-container">
          <app-phone-number-input
            class="signup-additional-info-form-phone-number-input"
            (phoneNumberChanged)="onPhoneNumberChanged($event)"
            (phoneNumberVerifiedChanged)="onPhoneNumberVerifiedChanged($event)"
          >
          </app-phone-number-input>

          <mat-icon
            class="signup-additional-info-form-phone-verified"
            *ngIf="
              phoneNumberVerified$ | async;
              else phoneNumberNotVerifiedIcon
            "
          >
            check
          </mat-icon>
          <ng-template #phoneNumberNotVerifiedIcon>
            <mat-icon class="signup-additional-info-form-phone-not-verified"
              >clear</mat-icon
            >
          </ng-template>
        </div>

        <div class="signup-additional-info-form-name-container">
          <mat-form-field
            appearance="outline"
            class="signup-additional-info-form-first-name"
          >
            <mat-label i18n>Vorname</mat-label>
            <input formControlName="firstName" matInput type="text" />
            <mat-error i18n> Vorname darf nicht leer sein </mat-error>
          </mat-form-field>

          <mat-form-field
            appearance="outline"
            class="signup-additional-info-form-last-name"
          >
            <mat-label i18n>Nachname</mat-label>
            <input formControlName="lastName" matInput type="text" />
            <mat-error i18n> Nachname darf nicht leer sein </mat-error>
          </mat-form-field>
        </div>

        <mat-form-field appearance="fill">
          <mat-label i18n>Passwort eingeben</mat-label>
          <input
            [type]="hidePassword ? 'password' : 'text'"
            formControlName="password"
            matInput
          />
          <button
            (click)="hidePassword = !hidePassword"
            [attr.aria-label]="'Hide password'"
            [attr.aria-pressed]="hidePassword"
            mat-icon-button
            matSuffix
            tabindex="-1"
          >
            <mat-icon>{{
              hidePassword ? "visibility_off" : "visibility"
            }}</mat-icon>
          </button>

          <mat-error
            *ngIf="
              signUpInformationFormGroup
                .get('password')
                .hasError(
                  signUpValidationMessages.passwordRequired.errorCode
                ) &&
              (signUpInformationFormGroup.get('password').dirty ||
                signUpInformationFormGroup.get('password').touched)
            "
            >{{ signUpValidationMessages.passwordRequired.message }}</mat-error
          >

          <mat-error
            *ngIf="
              signUpInformationFormGroup
                .get('password')
                .hasError(
                  signUpValidationMessages.passwordMinLength.errorCode
                ) &&
              (signUpInformationFormGroup.get('password').dirty ||
                signUpInformationFormGroup.get('password').touched)
            "
            >{{ signUpValidationMessages.passwordMinLength.message }}</mat-error
          >
        </mat-form-field>

        <button
          type="submit"
          mat-raised-button
          color="primary"
          [disabled]="!signUpInformationFormGroup.valid"
          (click)="sendAdditionalInformation()"
          i18n
        >
          Anmeldung vervollständigen
        </button>
      </form>
    </div>
  </ng-template>
</div>
