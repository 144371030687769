export enum AttachmentContentType {
  None = 0,
  Pdf = 100,
  Image = 200,
  LivePhoto = 300,
}

export class AttachmentContentTypeUtils {
  static fromContentType(contentType: string): AttachmentContentType {
    if (/image\/*/.test(contentType)) {
      return AttachmentContentType.Image;
    } else if (/application\/pdf/.test(contentType)) {
      return AttachmentContentType.Pdf;
    } else if (/video\/*/.test(contentType)) {
      return AttachmentContentType.LivePhoto;
    }

    return AttachmentContentType.None;
  }
}
