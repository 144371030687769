<div [ngSwitch]="status">
  <div class="chip-container">
    <div *ngSwitchCase="InquiryState.Open" class="chip open">
      <span class="chip-text" i18n>Offen</span>
    </div>
  </div>

  <div class="chip-container">
    <div *ngSwitchCase="InquiryState.Cancelled" class="chip cancelled">
      <span class="chip-text" i18n>Abgebrochen</span>
    </div>
  </div>

  <div class="chip-container">
    <div *ngSwitchCase="InquiryState.Expired" class="chip expired">
      <span class="chip-text" i18n>Abgelaufen</span>
    </div>
  </div>

  <div
    class="chip-container"
    i18n-matTooltip
    matTooltip="Anfrage wird in {{ daysLeft }} Tage gelöscht."
    matTooltipShowDelay="1000"
  >
    <div
      *ngSwitchCase="InquiryState.SuccessfullyFinished"
      class="chip finished"
    >
      <span class="chip-text">{{ daysLeftText ?? daysLeftTextDefault }}</span>
    </div>
  </div>

  <div class="chip-container">
    <div *ngSwitchCase="InquiryState.Deleted" class="chip cancelled">
      <span class="chip-text" i18n>Gelöscht</span>
    </div>
  </div>
</div>
