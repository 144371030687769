import { Component, Input, OnInit } from "@angular/core";
import { constants } from "../../../environments/constants";

/**
 * Heading with menu, can be used by projecting menu items with "heading-menu-actions" selector
 * For example:
 * <pre>
 * <app-heading-with-icon-and-action-menu>
 *   <ng-container heading-menu-actions>
 *     <button mat-menu-item>
 *       <mat-icon>dialpad</mat-icon>
 *       <span>Redial</span>
 *     </button>
 *   </ng-container>
 * </app-heading-with-icon-and-action-menu>
 * </pre>
 */
@Component({
  selector: 'app-heading-with-icon-and-action-menu',
  templateUrl: './heading-with-icon-and-action-menu.component.html',
  styleUrls: ['./heading-with-icon-and-action-menu.component.scss'],
})
export class HeadingWithIconAndActionMenuComponent implements OnInit {
  @Input() icon: string = 'delete';
  @Input() title: string = $localize`Kontaktdaten`;
  @Input() titleShort: string = null;
  @Input() menuTitle: string = null;
  @Input() menuTitleShort: string = null;
  @Input() menuIcon: string = 'more_vert';

  ngOnInit(): void {
    if (window.innerWidth < constants.dimension.$sm && this.titleShort) {
      this.title = this.titleShort;
    }

    if (window.innerWidth < constants.dimension.$sm && this.menuTitleShort) {
      this.menuTitle = this.menuTitleShort;
    }
  }
}
