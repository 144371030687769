import { ChangeDetectorRef, Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { BehaviorSubject, first, firstValueFrom } from 'rxjs';
import { NavigationService } from '../../services/util/navigation.service';
import { PhoneNumberInputDisplaySize } from '../../shared/phone-number-input/phone-number-input.component';
import { QuotaDataService } from '../../services/data/quota-data.service';
import { Quota } from '../../model/Quota';
import { MatDialog } from '@angular/material/dialog';
import { NotAllowedToMakeCallComponent } from '../dialogs/not-allowed-to-make-call/not-allowed-to-make-call.component';
import { QuotaService } from '../../services/api/quota.service';
import { CreateNewScheduledInquiryRequest } from '../../model/inquiry/request/create-new-scheduled-inquiry';
import { InvitationDataService } from '../services/invitation-data.service';
import { InquiryService } from '../../services/api/inquiry.service';
import { NotificationValidationService } from '../../services/api/notification-validation.service';
import { LineTypeIntelligenceType } from '../../model/notification/validation/line-type-intelligence';
import { PhoneNumberValidationResult } from '../../model/notification/validation/phone-number-validation-result';
import { PhoneNumberNotValidDialogComponent } from '../dialogs/phone-number-not-valid-dialog/phone-number-not-valid-dialog.component';
import { formatIncompletePhoneNumber } from 'libphonenumber-js';

@Component({
  selector: 'app-start-appointment',
  templateUrl: './start-appointment.component.html',
  styleUrls: ['./start-appointment.component.scss'],
  providers: [InquiryService, QuotaService, NotificationValidationService],
})
export class StartAppointmentComponent {
  phoneNumber = new BehaviorSubject<string>('');
  phoneNumberVerified = new BehaviorSubject<boolean>(false);
  skipPhoneNumberFormatting = new BehaviorSubject<boolean>(false);

  phoneNumberAlternativeControl = new FormControl(
    this.phoneNumber.value,
    Validators.required,
  );

  isLoading = false;
  showError = false;
  phoneNumberDisplaySize = PhoneNumberInputDisplaySize.Large;

  private readonly allowedToMakeCall = new BehaviorSubject(false);
  private readonly allowedLineTypeIntelligenceTypes = new Set([
    LineTypeIntelligenceType.Mobile.valueOf(),
    LineTypeIntelligenceType.Personal.valueOf(),
    LineTypeIntelligenceType.Unknown.valueOf(),
    LineTypeIntelligenceType.FixedVoip.valueOf(),
    LineTypeIntelligenceType.NonFixedVoip.valueOf(),
  ]);

  protected skipPhoneNumberFormattingText: string = $localize`Formatierung der Nummer aktivieren`;
  protected dontSkipPhoneNumberFormattingText: string = $localize`Formatierung der Nummer deaktivieren`;

  protected skipPhoneNumberFormattingInfoText: string = $localize`Eine Formatierung auf Grund der Landesvorwahl wird durchgeführt.`;
  protected dontSkipPhoneNumberFormattingInfoText: string = $localize`Alle Formatierungen und Validierungen für die Telefonnummer werden entfernt.`;

  constructor(
    private readonly inquiryService: InquiryService,
    private readonly invitationDataService: InvitationDataService,
    private readonly notificationValidationService: NotificationValidationService,
    private readonly navigationService: NavigationService,
    private readonly quotaService: QuotaService,
    private readonly employeeQuotaDataService: QuotaDataService,
    private readonly dialog: MatDialog,
    private readonly cdr: ChangeDetectorRef,
  ) {
    this.employeeQuotaDataService.employeeQuota.subscribe((quota) => {
      this.allowedToMakeCall.next(this.employeeAllowedToMakeCall(quota));
    });

    this.quotaService
      .getQuota()
      .subscribe((quota) => this.employeeQuotaDataService.updateQuota(quota));
  }

  phoneNumberVerifiedChanged(verified: boolean) {
    this.phoneNumberVerified.next(verified);
  }

  phoneNumberChanged(phoneNumber: string) {
    this.phoneNumber.next(phoneNumber);
  }

  onRemoveNumberFormattingClicked() {
    this.phoneNumberVerified.next(false);
    this.skipPhoneNumberFormatting.next(!this.skipPhoneNumberFormatting.value);

    if (this.skipPhoneNumberFormatting.value) {
      const trimmedNumber = this.phoneNumber.value.replace(/\s/g, '');
      this.phoneNumberAlternativeControl.setValue(trimmedNumber);
    }
  }

  async onInviteClicked() {
    if (this.allowedToMakeCall.value) {
      await this.sendInvite();
    } else {
      this.dialog.open(NotAllowedToMakeCallComponent);
    }
  }

  private async sendInvite() {
    this.skipPhoneNumberFormatting?.pipe(first()).subscribe(async (value) => {
      if (value && this.phoneNumberAlternativeControl.valid) {
        await this.validatePhoneNumberAndSendInvite();
      } else {
        this.phoneNumberVerified.pipe(first()).subscribe(async (isValid) => {
          if (isValid) {
            await this.validatePhoneNumberAndSendInvite();
          }
        });
      }
    });
  }

  private async validatePhoneNumberAndSendInvite() {
    this.isLoading = true;
    const phoneNumberToProcess = this.skipPhoneNumberFormatting.value
      ? this.phoneNumberAlternativeControl.value
      : this.phoneNumber.value;

    this.notificationValidationService
      .validatePhoneNumber(phoneNumberToProcess)
      .subscribe({
        next: async (result) => {
          if (this.isPhoneNumberValidationResultValid(result)) {
            await this.sendInvitationRequest(phoneNumberToProcess);
          } else {
            this.isLoading = false;
            this.dialog.open(PhoneNumberNotValidDialogComponent, {
              data: result,
            });
          }
        },
        error: () => {
          this.isLoading = false;
          this.showError = true;
          this.cdr.detectChanges();
        },
      });
  }

  private isPhoneNumberValidationResultValid(
    result: PhoneNumberValidationResult,
  ): boolean {
    return (
      result.couldValidate &&
      result.validation != null &&
      result.validation.valid === true &&
      this.lineTypeIsValid(result.validation.lineTypeIntelligence.type)
    );
  }

  private async sendInvitationRequest(phoneNumber: string) {
    const createNewScheduledInquiry: CreateNewScheduledInquiryRequest = {
      phoneNumber: phoneNumber,
      startInstant: true,
      invitation: await firstValueFrom(
        this.invitationDataService.getCurrentInvitationData(),
      ),
    };

    this.inquiryService.createNewInquiry(createNewScheduledInquiry).subscribe({
      next: (response) => {
        this.isLoading = false;
        this.cdr.detectChanges();

        this.navigationService.navigateToVideoAppointment(
          response.inquiryIdentifier,
        );
      },
      error: () => {
        this.isLoading = false;
        this.showError = true;
        this.cdr.detectChanges();
      },
    });
  }

  private employeeAllowedToMakeCall(quota: Quota) {
    if (
      quota.callsLeftForEmployee === null &&
      quota.callsLeftForTenant === null
    ) {
      return true;
    }

    return quota.callsLeftForEmployee > 0 && quota.callsLeftForTenant > 0;
  }

  phoneNumberModelChanged() {
    this.phoneNumberAlternativeControl.setValue(
      formatIncompletePhoneNumber(this.phoneNumberAlternativeControl.value),
    );
  }

  private lineTypeIsValid(type: string): boolean {
    if (type == null) {
      return true;
    }

    return this.allowedLineTypeIntelligenceTypes.has(type);
  }
}
