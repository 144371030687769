import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, from, Observable, switchMap, throwError } from 'rxjs';
import { AuthenticationService } from './authentication.service';
import { reportErrorExternally } from '../../reportErrorExternally';
import { environment } from '../../environments/environment';

@Injectable()
export class AuthenticationTokenInterceptorService implements HttpInterceptor {
  private readonly _secondsBeforeRenew = 5;
  constructor(private readonly authService: AuthenticationService) {}

  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    // Only intercept calls to our backend
    if (!req.url.match(environment.apiPath)) {
      return next.handle(req);
    }

    return from(this.authService.getUser()).pipe(
      switchMap((user) => {
        if (
          user &&
          user.access_token &&
          user.expires_in > this._secondsBeforeRenew
        ) {
          return this.callApi(req, next, user.access_token);
        } else if (user) {
          return from(this.authService.renewToken()).pipe(
            switchMap((user) => {
              return this.callApi(req, next, user.access_token);
            }),
            catchError(() => {
              throw new Error(`Can't acquire new token`);
              // return next.handle(req);
            }),
          );
        }

        return next.handle(req);
      }),
      catchError((err) => {
        reportErrorExternally(err);
        return next.handle(req);
      }),
    );
  }

  private callApi(
    req: HttpRequest<unknown>,
    next: HttpHandler,
    accessToken: string,
  ): Observable<HttpEvent<unknown>> {
    return next.handle(this.cloneRequestWithToken(req, accessToken)).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401 || error.status === 403) {
          return from(this.authService.renewToken()).pipe(
            switchMap((user) => {
              return next.handle(
                this.cloneRequestWithToken(req, user.access_token),
              );
            }),
            catchError(() => {
              throw new Error('Unable to renew token');
            }),
          );
        }
        return throwError(() => error);
      }),
    );
  }

  private cloneRequestWithToken(
    req: HttpRequest<unknown>,
    accessToken: string,
  ): HttpRequest<unknown> {
    return req.clone({
      setHeaders: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }
}
