import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { Employee } from '../../model/employee/employee';
import { CompanySettingsService } from '../../services/api/company.service';
import { ProfileDataService } from '../../services/data/profile-data.service';
import { JoinOpenAppointmentComponent } from '../join-open-appointment/join-open-appointment.component';
import { CompanySettings } from '../../model/company/company-settings';
import { ProfileService } from '../../services/api/profile-service';
import { InquiryDashboardService } from '../../services/api/inquiry-dashboard.service';
import { InquiryOpen } from '../../model/inquiry/InquiryOpen';
import { QuotaService } from '../../services/api/quota.service';
import { AuthenticationService } from '../../authentication/authentication.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RoleService } from '../../authentication/role.service';
import { BackendRole } from '../../authentication/backend-role';
import {
  EmployeeMessageType,
  EmployeeMessagingService,
} from '../../services/signaling/messaging/employee-messaging.service';

@Component({
  selector: 'app-menu-bar',
  templateUrl: './menu-bar.component.html',
  styleUrls: ['./menu-bar.component.scss'],
  providers: [ProfileService, QuotaService],
})
export class MenuBarComponent implements OnInit, OnDestroy {
  companySettings: CompanySettings;
  protected openInquiries: InquiryOpen[] = [];
  private readonly unsubscribe$ = new Subject<void>();

  protected BackendRole = BackendRole;

  constructor(
    private readonly companySettingsService: CompanySettingsService,
    private readonly employeeSignalingService: EmployeeMessagingService,
    private readonly profileService: ProfileService,
    protected readonly profileDataService: ProfileDataService,
    private readonly inquiryService: InquiryDashboardService,
    private readonly authService: AuthenticationService,
    private readonly snackBar: MatSnackBar,
    private readonly router: Router,
    private readonly dialog: MatDialog,
    protected readonly roleService: RoleService,
  ) {
    this.employeeSignalingService
      .onMessage(EmployeeMessageType.employeeChanged)
      .subscribe((employee: Employee) => {
        this.profileDataService.updateEmployeeInformation(employee);
      });
  }

  ngOnInit(): void {
    this.updateMenuBarCompanyInfo();
    this.updateMenuBarUsername();
    this.checkForOpenInquiries();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onLogout() {
    this.authService.logout().then((r) => {
      this.snackBar.open($localize`Benutzer wurde abgemeldet.`);
    });
  }

  joinRunningAppointment() {
    // Todo: subject for propagating changes of original data source
    if (this.openInquiries.length == 1) {
      // join inquiry instantly
      this.router.navigate(['/video-appointment'], {
        queryParams: { inquiryId: this.openInquiries[0].inquiryIdentifier },
      });
    } else if (this.openInquiries.length > 1) {
      // show dialog to choose which inquiry to join
      this.dialog.open(JoinOpenAppointmentComponent, {
        data: { inquiries: this.openInquiries },
      });
    }
  }

  buildAgentDisplayName(agent: Employee): string {
    return `${agent.firstName} ${agent.lastName}`;
  }

  canBuildDisplayName(agent: Employee) {
    return !(agent.firstName === null && agent.lastName === null);
  }

  private updateMenuBarCompanyInfo(): void {
    this.companySettingsService
      .getCompanySettings()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((settings) => {
        this.companySettings = settings;
      });

    this.companySettingsService.loadCompanyData().subscribe();
  }

  private updateMenuBarUsername(): void {
    this.profileService.getProfile().subscribe({
      next: (employee) => {
        this.profileDataService.updateEmployeeInformation(employee);
      },
    });
  }

  private checkForOpenInquiries(): void {
    this.inquiryService.getOpenInquiries().subscribe({
      next: (inquiry) => {
        if (inquiry != null) {
          this.openInquiries = inquiry;
        }
      },
    });
  }
}
