import { BrandingInvitationService as Api } from '../../../api/gen';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { BrandingInvitation } from '../../model/company/branding/branding-invitation';
import { UpdateBrandingInvitationRequest } from '../../model/company/branding/request/update-branding-invitation-request';
import { CreateBrandingInvitationRequest } from '../../model/company/branding/request/create-branding-invitation-request';

@Injectable()
export class BrandingInvitationService {
  constructor(private readonly brandingInvitationService: Api) {}

  getBrandingInvitation(id: string): Observable<BrandingInvitation> {
    return this.brandingInvitationService
      .companyBrandingInvitationIdGet(id)
      .pipe(map((dto) => BrandingInvitation.fromDto(dto)));
  }

  getBrandingInvitations(): Observable<BrandingInvitation[]> {
    return this.brandingInvitationService
      .companyBrandingInvitationGet()
      .pipe(map((x) => x.map((dto) => BrandingInvitation.fromDto(dto))));
  }

  createBrandingInvitation(
    request: CreateBrandingInvitationRequest,
  ): Observable<BrandingInvitation> {
    return this.brandingInvitationService
      .companyBrandingInvitationPost(
        CreateBrandingInvitationRequest.toRequest(request),
      )
      .pipe(map((dto) => BrandingInvitation.fromDto(dto)));
  }

  updateBrandingInvitation(
    id: string,
    request: UpdateBrandingInvitationRequest,
  ): Observable<BrandingInvitation> {
    return this.brandingInvitationService
      .companyBrandingInvitationIdPatch(
        id,
        UpdateBrandingInvitationRequest.toRequest(request),
      )
      .pipe(map((dto) => BrandingInvitation.fromDto(dto)));
  }

  deleteBrandingInvitation(id: string): Observable<BrandingInvitation> {
    return this.brandingInvitationService
      .companyBrandingInvitationIdDelete(id)
      .pipe(map((dto) => BrandingInvitation.fromDto(dto)));
  }
}
