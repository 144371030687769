<h1 mat-dialog-title i18n>OCR Ergebnis</h1>
<div mat-dialog-content>
  <p i18n>Folgende Texte wurden aus dem Bild ausgelesen.</p>

  <div class="result-table-container">
    <table
      [dataSource]="dataSource"
      class="result-table mat-elevation-z8"
      mat-table
    >
      <ng-container matColumnDef="text">
        <th *matHeaderCellDef mat-header-cell>Text</th>
        <td *matCellDef="let element" mat-cell>{{ element.text }}</td>
      </ng-container>

      <ng-container matColumnDef="copy">
        <th *matHeaderCellDef mat-header-cell i18n>Kopieren</th>
        <td *matCellDef="let element" mat-cell>
          <button
            (click)="copyElementToClipboard(element)"
            i18n-aria-label
            aria-label="Kopieren"
            mat-icon-button
          >
            <mat-icon>content_copy</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
      <tr *matRowDef="let row; columns: displayedColumns" mat-row></tr>
      <tr *matNoDataRow class="mat-row">
        <td
          [attr.colspan]="displayedColumns.length"
          class="mat-cell no-data-row"
          i18n
        >
          Keine Texte ausgelesen.
        </td>
      </tr>
    </table>
  </div>
</div>
<div mat-dialog-actions>
  <button cdkFocusInitial color="primary" mat-button mat-dialog-close="" i18n>
    Ok
  </button>
  <button (click)="copyEverythingToClipboard()" color="primary" mat-button i18n>
    Alles kopieren
  </button>
</div>
