import { AssignInquiryService as AssignInquiryApi } from '../../../../api/gen';
import { map, Observable } from 'rxjs';
import { Inquiry } from '../../../model/inquiry/inquiry';
import {
  AssignEmployeeToOpenInquiryRequest,
  UnAssignEmployeeFromOpenInquiryRequest,
} from './requests';
import { Injectable } from '@angular/core';

@Injectable()
export class AssignInquiryService {
  constructor(private readonly assignInquiryService: AssignInquiryApi) {}

  assignAgent(
    request: AssignEmployeeToOpenInquiryRequest,
  ): Observable<Inquiry> {
    return this.assignInquiryService
      .contactInquiryAgentAssignPost(request)
      .pipe(map((dto) => Inquiry.fromDto(dto)));
  }

  unAssignAgent(
    request: UnAssignEmployeeFromOpenInquiryRequest,
  ): Observable<Inquiry> {
    return this.assignInquiryService
      .contactInquiryAgentUnassignPost(request)
      .pipe(map((dto) => Inquiry.fromDto(dto)));
  }
}
