import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class RealmService {
  private static readonly DEFAULT_SUBDOMAIN: string = 'madtech';
  private static readonly AIDAR_SUBDOMAIN: string = 'aidar';
  private static readonly WWW_SUBDOMAIN: string = 'www';

  private readonly realm: string;

  constructor() {
    this.realm = this.getSubdomain();
  }

  public getRealm(): string {
    return this.realm;
  }

  public getClientRootForRealm(clientRootUrl: string): string {
    return clientRootUrl.replace('*', this.getRealm());
  }

  private getSubdomain(): string {
    let subdomain: string;
    const domain = window.location.hostname;
    if (
      domain.indexOf('.') < 0 ||
      domain.split('.')[0] === RealmService.AIDAR_SUBDOMAIN ||
      domain.split('.')[0] === RealmService.WWW_SUBDOMAIN ||
      !Number.isNaN(+domain.split('.')[0])
    ) {
      subdomain = RealmService.DEFAULT_SUBDOMAIN;
    } else {
      subdomain = domain.split('.')[0];
    }

    return subdomain;
  }
}
