<h2 mat-dialog-title i18n>Nummer ungültig</h2>
<mat-dialog-content>
  <span>{{ errorReason$ | async }}</span>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close i18n>Andere Nummer eingeben</button>
  <button
    (click)="createInquiryWithLink()"
    cdkFocusInitial
    mat-flat-button
    color="primary"
    [disabled]="isLoading$ | async"
  >
    <mat-icon>link</mat-icon>
    <span i18n>Termin mit Link erstellen</span>
  </button>
</mat-dialog-actions>
<mat-progress-bar
  mode="indeterminate"
  *ngIf="isLoading$ | async"
></mat-progress-bar>
