<div class="container">
  <div class="animation">
    <ng-lottie
      [containerClass]="
        invitationStatus === InvitationStatus.Sent ? 'zoom' : ''
      "
      [options]="currentAnimationConfig"
    ></ng-lottie>
  </div>

  <mat-vertical-stepper [linear]="false" #stepper>
    <mat-step editable="false">
      <ng-template matStepLabel i18n>SMS Gesendet</ng-template>
      <ng-template matStepContent>
        <span *ngIf="invitationStatus !== InvitationStatus.Error" i18n
          >Die SMS wurde von blankmile versendet.</span
        >
        <div
          class="error"
          *ngIf="invitationStatus === InvitationStatus.Error"
          i18n
        >
          Die SMS konnte nicht versendet werden.<br />
          Bitte starte einen neuen Call und überprüfe die Handynummer.
        </div>
      </ng-template>
    </mat-step>
    <mat-step editable="false">
      <ng-template matStepLabel i18n>SMS Empfangen</ng-template>
      <ng-template matStepContent>
        <span i18n>Die SMS wurde vom Teilnehmer empfangen.</span>
      </ng-template>
    </mat-step>
    <mat-step editable="false">
      <ng-template matStepLabel i18n>Link geöffnet</ng-template>
      <ng-template matStepContent>
        <span i18n>Der versendete Link wurde geöffnet.</span><br />
        <span i18n>Dein/e Gesprächspartner/in sollte bald hier sein.</span>
      </ng-template>
    </mat-step>
  </mat-vertical-stepper>
</div>
