<div class="distance-container">
  <div
    class="selection"
    *ngFor="let distance of distances"
    (click)="changeSelection(distance.value)"
    [class.selected]="selection == distance.value"
    [class.disabled]="!enabled"
  >
    {{ distance.label }}
  </div>
</div>
