import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { InquiryDashboardSummary } from '../../../model/inquiry/inquiry-dashboard-summary';
import { RedialDialogComponent } from '../../dialogs/redial-dialog/redial-dialog.component';
import { first, firstValueFrom } from 'rxjs';
import { ConfirmDeleteInquiryDialogComponent } from '../../dialogs/confirm-delete-inquiry-dialog/confirm-delete-inquiry-dialog.component';
import { Inquiry } from '../../../model/inquiry/inquiry';
import { InquiryService } from '../../../services/api/inquiry.service';
import { NavigationService } from '../../../services/util/navigation.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AppointmentService } from '../../../services/api/appointment.service';
import { InquiryState } from '../../../model/inquiry/inquiry-state';
import { CreateNewScheduledInquiryRequest } from '../../../model/inquiry/request/create-new-scheduled-inquiry';
import { InvitationDataService } from '../../services/invitation-data.service';

@Component({
  selector: 'app-inquiry-details-header',
  templateUrl: './inquiry-details-header.component.html',
  styleUrls: ['./inquiry-details-header.component.scss'],
  providers: [InquiryService],
})
export class InquiryDetailsHeaderComponent implements OnInit {
  @Input() inquiry: InquiryDashboardSummary;
  @Output() callCancelled = new EventEmitter();

  protected inquiryId: string;
  protected readonly InquiryState = InquiryState;

  constructor(
    private readonly inquiryService: InquiryService,
    private readonly invitationDataService: InvitationDataService,
    private readonly appointmentService: AppointmentService,
    private readonly ref: ChangeDetectorRef,
    private readonly navigationService: NavigationService,
    private readonly dialog: MatDialog,
    private readonly snackBar: MatSnackBar,
  ) {}

  ngOnInit(): void {
    if (this.inquiry) {
      this.inquiryId = this.inquiry.inquiryIdentifier;
    }
  }

  protected redial() {
    const shouldRedial = this.dialog.open(RedialDialogComponent, {
      width: '640px',
    });
    shouldRedial.afterClosed().subscribe(async (result) => {
      if (result) {
        const createNewScheduledInquiry: CreateNewScheduledInquiryRequest = {
          firstName: this.inquiry.firstName,
          lastName: this.inquiry.lastName,
          email: this.inquiry.email,
          phoneNumber: this.inquiry.phoneNumber,
          startInstant: true,
          invitation: await firstValueFrom(
            this.invitationDataService.getCurrentInvitationData(),
          ),
        };

        this.inquiryService
          .createNewInquiry(createNewScheduledInquiry)
          .subscribe({
            next: (response) => {
              this.navigationService.navigateToVideoAppointment(
                response.inquiryIdentifier,
              );
            },
            error: (error) => {
              this.snackBar.open('Einladung fehlgeschlagen!', null, {
                duration: 5000,
              });
            },
          });
      }
    });
  }

  protected joinCall() {
    if (this.inquiryId) {
      this.navigationService.navigateToVideoAppointment(this.inquiryId);
    }
  }

  protected cancelCall() {
    if (!this.inquiryId) {
      return;
    }
    // TODO: Store Cancelation reason
    this.appointmentService
      .finishAppointment(this.inquiryId)
      .pipe(first())
      .subscribe(() => {
        this.callCancelled.emit();
      });
  }

  deleteInquiry(inquiry: InquiryDashboardSummary) {
    this.dialog
      .open<ConfirmDeleteInquiryDialogComponent, string, Inquiry>(
        ConfirmDeleteInquiryDialogComponent,
        {
          maxWidth: '640px',
          data: inquiry.inquiryIdentifier,
        },
      )
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.inquiry.manuallyDeletedAt = result.manuallyDeletedAt;
          this.inquiry.manuallyDeletedBy = result.manuallyDeletedBy;
          this.inquiry.state = result.state;
          this.ref.markForCheck();
        }
      });
  }
}
