<div class="container">
  <div class="country-container">
    <div (isOutside)="hide($event)" class="country-select-container">
      <button
        #trigger="cdkOverlayOrigin"
        (click)="onCountrySelectClicked()"
        cdkOverlayOrigin
        class="country-select-button"
        matRipple
      >
        <img
          alt="{{ (selectedCountry | async).flagIcon }}"
          class="country-picker-flag-icon"
          src="assets/images/flags/100px/{{
            (selectedCountry | async).countryCode.toLowerCase()
          }}.png"
        />

        <img
          i18n-alt
          alt="Zeige verfügbare Länder"
          class="country-select-expand"
          src="assets/images/expand_more.svg"
        />
      </button>

      <ng-template
        [cdkConnectedOverlayOpen]="countrySelectActive | async"
        [cdkConnectedOverlayOrigin]="trigger"
        cdkConnectedOverlay
      >
        <ng-container>
          <div class="country-picker-container close-when-outside">
            <div
              class="country-picker-search-container-outer close-when-outside"
            >
              <div class="country-picker-search-container close-when-outside">
                <input
                  class="country-picker-input close-when-outside"
                  i18n-placeholder
                  placeholder="Suchen"
                  type="search"
                  (input)="onSearchCountryChange($event)"
                />
                <div
                  class="country-picker-search-icon-container close-when-outside"
                >
                  <button
                    mat-icon-button
                    i18n-aria-label
                    aria-label="Suchen"
                    color="primary"
                    disabled
                  >
                    <mat-icon>search</mat-icon>
                  </button>
                </div>
              </div>
            </div>
            <div class="country-picker-list-container">
              <mat-selection-list
                (selectionChange)="onCountrySelectionChanged($event)"
                [compareWith]="countryCompareFunction"
                [multiple]="false"
                class="country-picker-list close-when-outside"
              >
                <div
                  *ngIf="countriesFiltering | async"
                  class="searching-countries-loading-container"
                >
                  <mat-spinner diameter="24"></mat-spinner>
                </div>
                <div
                  class="close-when-outside"
                  mat-subheader
                  style="user-select: none"
                  *ngIf="
                    favoriteCountries.length > 0 &&
                    (countriesFiltering | async) === false
                  "
                  i18n
                >
                  Favoriten
                </div>
                <mat-list-option
                  *ngFor="let country of favoriteCountries"
                  [selected]="
                    (selectedCountry | async).countryCode ===
                    country.countryCode
                  "
                  [appScrollIntoView]="
                    country.countryCode ===
                    (selectedCountry | async).countryCode
                  "
                  [value]="country"
                  class="close-when-outside"
                >
                  <div class="close-when-outside" matListItemIcon>
                    <img
                      alt="{{ (selectedCountry | async).flagIcon }}"
                      class="country-picker-flag-icon close-when-outside"
                      src="assets/images/flags/100px/{{
                        country.countryCode.toLowerCase()
                      }}.png"
                    />
                  </div>

                  <span class="close-when-outside" matListItemTitle
                    >{{ country.countryNameEnglish }} +{{
                      country.countryCallingCode
                    }}</span
                  >
                </mat-list-option>

                <mat-divider
                  class="close-when-outside"
                  *ngIf="
                    favoriteCountries.length > 0 &&
                    remainingCountries.length > 0
                  "
                ></mat-divider>

                <mat-list-option
                  *ngFor="let country of remainingCountries"
                  [selected]="
                    (selectedCountry | async).countryCode ===
                    country.countryCode
                  "
                  [appScrollIntoView]="
                    country.countryCode ===
                    (selectedCountry | async).countryCode
                  "
                  [value]="country"
                  class="close-when-outside"
                >
                  <div class="close-when-outside" matListItemIcon>
                    <img
                      alt="{{ (selectedCountry | async).flagIcon }}"
                      class="country-picker-flag-icon close-when-outside"
                      src="assets/images/flags/100px/{{
                        country.countryCode.toLowerCase()
                      }}.png"
                    />
                  </div>

                  <span class="close-when-outside" matListItemTitle
                    >{{ country.countryNameEnglish }} +{{
                      country.countryCallingCode
                    }}
                  </span>
                </mat-list-option>

                <div
                  class="no-countries-found-container"
                  *ngIf="
                    favoriteCountries.length === 0 &&
                    remainingCountries.length === 0 &&
                    (countriesFiltering | async) === false
                  "
                >
                  <span i18n>Keine Länder gefunden!</span>
                </div>
              </mat-selection-list>
            </div>
          </div>
        </ng-container>
      </ng-template>
    </div>
  </div>

  <div class="number-input-container">
    <input
      id="number-input"
      (input)="phoneNumberModelChanged($event)"
      (keydown.enter)="enterKeyPressed?.emit()"
      [OnlyNumber]="true"
      [value]="phoneNumber | async"
      type="tel"
      class="number-input"
      [style.font-size]="getNumberInputFontSize()"
      [style.border-color]="
        (phoneNumberVerified | async) ||
        (phoneNumber | async).length <=
          (selectedCountry | async).countryCallingCode.length + 1
          ? '#65b5f5'
          : 'red'
      "
    />
    <div
      [matTooltip]="getTooltipText()"
      class="number-input-error-icon"
      *ngIf="
        (phoneNumberVerified | async) === false &&
        (phoneNumber | async).length > 3
      "
    >
      <mat-icon color="warn">error_outline</mat-icon>
    </div>
  </div>
</div>
