<div>
  <app-heading-with-icon-and-action
    i18n-title
    title="Entfernung zum Kunden"
    titleShort="Entfernung"
    icon="social_distance"
    i18n-actionTitle
    actionTitle="Bearbeiten"
    [actionTitle]="allowSelection ? textFinished : textEdit"
    [actionIcon]="allowSelection ? 'done' : 'edit'"
    (actionTriggered)="toggleEdit()"
  >
  </app-heading-with-icon-and-action>
  <div class="content">
    <app-distance-input
      *ngIf="!loading"
      [selection]="selectedDistance"
      (selectionChange)="selectionChanged($event)"
      [enabled]="allowSelection"
    ></app-distance-input>
    <mat-progress-spinner
      *ngIf="loading"
      mode="indeterminate"
      diameter="25"
    ></mat-progress-spinner>
  </div>
</div>
