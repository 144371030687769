<div [ngClass]="{ clickable: clickable }" class="list-item">
  <mat-card class="single-attachment-card">
    <div class="clickbox" (click)="onItemClicked()">
      <div class="attachment-thumbnail" mat-card-image>
        @if (type === AttachmentContentType.Image) {
          <img src="{{ previewUrl ?? 'assets/images/description.svg' }}" />
        }
        @if (type === AttachmentContentType.LivePhoto) {
          <video
            [src]="previewUrl"
            loop
            autoplay
            muted
            disablepictureinpicture
          ></video>
        }
        @if (
          type === AttachmentContentType.Pdf ||
          type === AttachmentContentType.None
        ) {
          <img src="../../../../assets/images/description.svg" />
        }
      </div>

      <div class="attachment-information">
        <div class="attachment-information-title">
          {{ title }}
        </div>
        <div class="attachment-date">{{ createdAt | date: "HH:mm:ss" }}</div>
        <div class="spacer"></div>
        <div class="extension-tag">{{ extension }}</div>
      </div>
    </div>
    <div class="action">
      <button
        matBadge="✎"
        matBadgeHidden="{{ !noteAvailable }}"
        matBadgeSize="small"
        mat-icon-button
        (click)="onEditNoteClicked()"
      >
        <mat-icon>edit</mat-icon>
      </button>
      <button mat-icon-button *ngIf="menu" [matMenuTriggerFor]="menu">
        <mat-icon>more_vert</mat-icon>
      </button>
    </div>
  </mat-card>
</div>
