import { Component } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RegistrationValidationService } from '../../services/api/registration-validation.service';
import { ValidateRegistrationTokenRequest } from '../../model/signup/request/validate-registration-token-request';
import { CreateUserFromValidationTokenRequest } from '../../model/signup/request/create-user-from-validation-token-request';
import { CompanyLoginService } from '../../services/api/company-login.service';
import { CompanyLoginInfo } from '../../model/company-login/company-login-info';
import { EmployeeService } from '../../services/api/employee.service';

@Component({
  selector: 'app-signup-invitation-landing',
  templateUrl: './signup-invitation-landing.component.html',
  styleUrls: ['./signup-invitation-landing.component.scss'],
  providers: [
    RegistrationValidationService,
    EmployeeService,
    CompanyLoginService,
  ],
})
export class SignupInvitationLandingComponent {
  signUpInformationFormGroup = new FormGroup({
    email: new FormControl('', Validators.email),
    firstName: new FormControl('', Validators.required),
    lastName: new FormControl('', Validators.required),
    password: new FormControl('', [
      Validators.required,
      Validators.minLength(10),
    ]),
  });

  signUpValidationMessages = {
    passwordRequired: {
      errorCode: 'required',
      message: $localize`Passwort wird benötigt`,
    },
    passwordMinLength: {
      errorCode: 'minlength',
      message: $localize`Passwort muss mindestens 10 Zeichen lang sein`,
    },
  };

  isLoading$ = new BehaviorSubject<boolean>(true);
  queryTokenFailed$ = new BehaviorSubject<boolean>(false);
  queryTokenInvalid$ = new BehaviorSubject<boolean>(false);
  phoneNumber$ = new BehaviorSubject<string>('');
  phoneNumberVerified$ = new BehaviorSubject<boolean>(false);
  hidePassword = true;

  private readonly token$ = new BehaviorSubject<string>('');
  companyInfo$ = new BehaviorSubject<CompanyLoginInfo>(null);

  constructor(
    private readonly companyService: CompanyLoginService,
    private readonly registrationValidationService: RegistrationValidationService,
    private readonly employeeService: EmployeeService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
    private readonly snackBar: MatSnackBar,
  ) {
    this.signUpInformationFormGroup.controls.email.disable();

    this.companyService
      .getCompanyLoginInformation()
      .subscribe((companyInfo) => {
        this.companyInfo$.next(companyInfo);
      });

    this.activatedRoute.queryParams.subscribe((params) => {
      this.token$.next(params['token']);
      this.queryTokenInformation();
    });
  }

  onPhoneNumberChanged(phoneNumber: string) {
    this.phoneNumber$.next(phoneNumber);
  }

  onPhoneNumberVerifiedChanged(verified: boolean) {
    this.phoneNumberVerified$.next(verified);
  }

  sendAdditionalInformation() {
    this.isLoading$.next(true);
    const requestData = {
      firstName: this.signUpInformationFormGroup.controls.firstName.value,
      lastName: this.signUpInformationFormGroup.controls.lastName.value,
      password: this.signUpInformationFormGroup.controls.password.value,
      phoneNumber: this.phoneNumberVerified$ ? this.phoneNumber$.value : null,
      token: this.token$.value,
    } as CreateUserFromValidationTokenRequest;
    this.employeeService.createUserFromValidationToken(requestData).subscribe({
      next: (x) => {
        this.snackBar.open(
          $localize`Hallo ${x.firstName}! Dein Account wurde erfolgreich angelegt.`,
          null,
          { duration: 5000 },
        );
        this.router.navigate(['/']);
      },
      error: () => {
        this.isLoading$.next(false);
        this.snackBar.open(
          $localize`Benutzer konnte nicht angelegt werden. Wenden Sie sich an Ihren Administrator`,
          $localize`Ok`,
        );
      },
    });
  }

  retryLoadingToken() {
    this.queryTokenFailed$.next(false);
    this.queryTokenInformation();
  }

  private queryTokenInformation() {
    if (this.token$.value) {
      const request = {
        token: this.token$.value,
      } as ValidateRegistrationTokenRequest;
      this.registrationValidationService.validateToken(request).subscribe({
        next: (validation) => {
          if (validation.activated || validation.deleted) {
            this.queryTokenInvalid$.next(true);
            return;
          }
          this.signUpInformationFormGroup.controls.email.setValue(
            validation.eMail,
          );
          this.isLoading$.next(false);
        },
        error: () => {
          this.queryTokenFailed$.next(true);
        },
      });
    }
  }
}
